@import '../../../src/scss/utilities/mixins';

.MobileMenuFooter {


   .socialIcon {
      height: 50px;
      width: 50px;
      border-radius: 50%;
      float: left;
      margin-left: 5px;
      margin-right: 5px;
      background-image: -webkit-linear-gradient(0deg, rgba(25, 234, 23, 1.00) 0%, rgba(20, 208, 230, 1.00) 100%);
      background-image: -moz-linear-gradient(0deg, rgba(25, 234, 23, 1.00) 0%, rgba(20, 208, 230, 1.00) 100%);
      background-image: -o-linear-gradient(0deg, rgba(25, 234, 23, 1.00) 0%, rgba(20, 208, 230, 1.00) 100%);
      background-image: linear-gradient(90deg, rgba(25, 234, 23, 1.00) 0%, rgba(20, 208, 230, 1.00) 100%);
      color: hsla(0, 0%, 0%, 1.00);

      i {
         font-size: 20px;
         line-height: 50px;
      }
   }

   .footLogo {
      height: 25px;
   }

   a {
      color: #000000;
   }

   a:hover {
      color: #ffffff
   }

   .copyright {
      font-size: 12px;

      a {
         color: #697778;
         text-decoration: none;
      }

      a:hover {
         color: #54e8f8;
         text-decoration: none;
      }
   }

   .socialUi {
      padding-left: 0;
      list-style-type: none;
      width: 364px;
      float: right;

      li {
         float: left;
         margin-left: 20px;
      }

      li:first-child {
         margin-left: 0;
      }
   }

   .social {
      display: flex;
      float: right;
      width: 52px;
      height: 52px;
      background: #0E2029;
      border-radius: 90px;
      justify-content: center;

      img {
         width: 24px;
         padding-top: 15px;
      }
   }

   .footerLinks {
      text-decoration: none;
      font-size: 16px;
      color: #fff;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: -0.01em;
   }

   .grayText {
      color: #95A6B7;
      font-size: 16px;
      text-align: left;

      a {
         color: #95A6B7 !important;
         cursor: pointer;
      }
   }

   @media only screen and (min-width: 992px) {
      .mobileMenuFooterContainer {
         display: none !important;
      }
   }
   @media only screen and (max-width: 992px) {
      .navbarCollapse {
         width: 100% !important;
      }
      .uppeFooter > div > div {
         width: 100%;
         display: block;
         position: relative;

         .metalogoWrapper {

            margin: 0 auto;
         }

         > ul {
            float: none;
            padding-top: 20px;
            height: 56px;
         }
      }

      .uppeFooter {
         margin: 0 auto;

         // > .row {
         //   background-color: red;

         //   > div {
         //     background-color: green;
         //   }
         // }
      }
      .socialLinksWrapper {
         ul {
            li {
               .social {
                  width: 40px;
                  height: 40px;
                  border-radius: 50%;

                  img {
                     padding-top: 9px;
                  }
               }
            }
         }

      }
      .mobileMenuFooterContainer {
         ul.socialUi {
            width: fit-content;
            margin: 0 auto;

            li {
               .social {
                  width: 40px;
                  height: 40px;
                  border-radius: 50%;

                  img {
                     padding-top: 9px;
                  }
               }
            }
         }

         .footerLinks {
            margin: 0 auto;
            width: fit-content;

            > a {
               display: block;
               width: 100%;
               text-align: center;
            }
         }

         .siteFooter {
            max-width: fit-content;
            margin: 0 auto;

            .copyRightMsg {
               position: absolute;
               bottom: -26px;
               left: 0;
               right: 0;
               margin-left: auto;
               margin-right: auto;
               text-align: center;

               a {
                  color: rgba(203, 207, 210, 1);
                  width: fit-content;
                  margin: 0;
                  text-decoration: none;
               }
            }
         }

         .uppeFooter {
            div.row {
               display: grid;

               > div:nth-child(1) {
                  width: fit-content;
                  margin: 0 auto;
               }
            }

            .metalogoWrapper {
               display: block;
               width: fit-content;

               img {
                  max-width: 120px;
               }
            }
         }
      }
      .mobileMenuFooterContainer {
         .mobileLang {
            width: fit-content;
            margin: 0 auto;
            margin-top: 15px;
         }
      }
   }

   @media (max-width: 768px) {
      .socialIcon {
         height: 30px;
         width: 30px;
         border-radius: 50%;
         float: left;

         i {
            font-size: 15px;
            line-height: 30px;
         }
      }


      .social {
         width: 44px;
         height: 44px;

         img {
            width: 20px;
            padding-top: 13px;
         }
      }

      .socialUi {
         padding-left: 0;
         list-style-type: none;
         width: 300px;
         margin-left: auto;
         margin-right: auto;
         float: none;
      }
      .footerLinks {
         text-decoration: none;
         font-size: 14px;
         color: #fff;
         font-style: normal;
         font-weight: 100;
         line-height: 20px;

      }
      .grayText {
         color: #95A6B7;
         font-size: 10px;
         text-align: center;
      }
   }

}

.scoreBoardTeamNames {
   font-weight: 400;
   text-transform: uppercase;
   font-size: 16px;
   font-family: 'OSSlanted' !important;
   cursor: pointer;
}

td.currentPlayer {
   padding-left: 0px !important;
   padding-right: 0px !important;
}

.dropdownSelectedItem {
   background-color: #111E33 !important;
   background-image: url(https://storage.googleapis.com/meta11/serviceplatform/greenCheck.webp);
   background-repeat: no-repeat;
   padding-left: 25px !important;
   background-position: 0% 18px;
   color: #ffffff !important;
}

.statwrap {
   display: flex;
   align-items: center;
   margin: 10px 0;
   width: 100%;
   flex-wrap: wrap;
   text-align: center;
}

.statlbl {
   color: #95A6B7;
   font-size: 16px;
   line-height: 20px;
   letter-spacing: -0.01em;
   font-weight: 700;
   font-family: 'ObjectSans-Regular';
}

.statval {
   padding: 0px 10px;
   background: linear-gradient(89.7deg, #17EA85 2.27%, #14D0E6 23.6%, #17EA85 44.41%, #14D0E6 64.17%, #17EA85 82.99%, #14D0E6 99.74%);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   font-family: 'ObjectSans-Heavy';
   /*display: flex;*/
   justify-content: left;
   font-size: 16px;
   font-weight: bold;
}


@media (max-width: 768px) {
   .battersWrp {
      overflow: auto;
   }

   .battersWrp table {
      width: 500px;
   }
   .statlbl {
      font-size: 12px;
      width: 100%;
   }
   .statval {
      font-size: 12px;
   }
}

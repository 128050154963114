.OmniCreditsNavBar {
   margin: 22px 20px 0 22px;
}

.LoginButton {
   margin-left: 50px;
   margin-top: 8px;
}

// .LoginButton{
//     all: unset;
// }

@media (min-width: 1210px) {
   .topicText {
      padding: 10px 20px;
      color: #000000;
      border-radius: 200px;
      font-size: 25px;
      font-weight: 700;
      text-align: center;
      background-color: #97fce9;
   }

   .carouselCaption {
      position: absolute;
   }

   .navbarBrand img {
      margin-bottom: 0px;
      padding-bottom: 0px;
      padding-left: 0px;
      margin-left: 0px;
      border-top-left-radius: 0px;
      height: 35px;
      width: 197px;
   }

   .text2 {
      font-weight: 600;
      font-size: 35px;
      letter-spacing: 2px;
      line-height: 0;
   }

   .videoContainer {
      margin-top: 0px;
      padding-top: 0px;
   }

   .text1 {
      line-height: 0px;
   }

   .text4 {
      font-weight: 400;
      font-size: 35px;
      letter-spacing: 1px;
      line-height: 1.2;
      margin-top: 0px;
      height: auto;
   }
}


.navbarBrand img {
   height: auto;
   width: 226px;
   margin-top: -8px;
}

.navLink {
   color: #ffffff !important;
   margin-top: 0px;
   font-size: 16px;
   padding-top: 0px;
   padding-right: 0px;
   padding-left: 0px;
   text-decoration: none;
}

.customDrop {
   a {
      -webkit-text-fill-color: #95A6B7 !important
   }

   a:hover {
      -webkit-text-fill-color: #ffffff !important
   }
}

.centeredUserName {
   position: relative;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   text-align: center;
   color: black;
   font-weight: bold;
   font-family: 'ObjectSans-Regular';
   -webkit-text-fill-color: #000000;
   font-size: 18px;
   line-height: 22px;
}

.userNameLink a {
   padding-right: 0px;
   padding-left: 3px;
   text-decoration: none;
}

.navDropLink {
   color: #212529 !important;

   div {
      width: 100%;
   }
}

.smallBtn {
   background-color: #000000;
   font-family: 'Rubik-Medium';
   padding-top: 10px;
   padding-right: 20px;
   padding-bottom: 10px;
   padding-left: 20px;
   color: #000000;
   font-size: 15px;
   border: 0px;
   border-radius: 20px;
   background-image: -webkit-linear-gradient(0deg, rgba(25, 234, 23, 1.00) 0%, rgba(20, 208, 230, 1.00) 100%);
   background-image: -moz-linear-gradient(0deg, rgba(25, 234, 23, 1.00) 0%, rgba(20, 208, 230, 1.00) 100%);
   background-image: -o-linear-gradient(0deg, rgba(25, 234, 23, 1.00) 0%, rgba(20, 208, 230, 1.00) 100%);
   background-image: linear-gradient(90deg, rgba(25, 234, 23, 1.00) 0%, rgba(20, 208, 230, 1.00) 100%);
}

.smallBtn:hover {
   background-color: #000000;
   padding-top: 10px;
   padding-right: 20px;
   padding-bottom: 10px;
   padding-left: 20px;
   color: #FFFFFF;

}

.navLink {
   img {
      margin-bottom: 5px;
      border-width: medium;
      margin-top: 0px;
   }

   a:hover {
      background: linear-gradient(to right, #17EA85 0%, #17EA85 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
   }

}

.navbar {
   padding: 0;
   z-index: 1000;
   display: flex;
   justify-content: start;
}

.navbarBrand {
   padding-top: 0;
}


.navItem {
   a {
      display: block;
   }

   a:hover {
      background: linear-gradient(to right, #17EA85 0%, #17EA85 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
   }

   .active {
      display: block;
      background: linear-gradient(to right, #17EA85 0%, #17EA85 100%);
      -webkit-background-clip: text;
      font-weight: bold;
      -webkit-text-fill-color: transparent;
   }

   .dropdownToggle:hover {
      background-color: black !important;
      border-color: black !important;

   }
}

.navItem:last-child {
   a {
      padding: 0;
   }

   a:nth-child(1) {
      width: 67px;
      margin-top: 15px;
   }
}

.navTabs {
   border: 0;

   .navLink {
      color: #ffffff !important;
      padding: 10px 20px;
      border-bottom: 3px solid rgba(0, 0, 0, 0.00);
   }

   .navLink:hover {
      color: #fff !important;
      border-top: 1px solid rgba(0, 0, 0, 0.00);
      border-left: 1px solid rgba(0, 0, 0, 0.00);
      border-right: 1px solid rgba(0, 0, 0, 0.00);
      border-bottom: 3px solid rgba(0, 0, 0, 0.00);
   }

   .navLink.active,
   .navItem.show .navLink {
      background-color: rgba(0, 0, 0, 0.00);
      border-top: 0px;
      border-left: 0px;
      border-right: 0px;
      border-bottom: 3px solid #97FCE9;
   }
}


.navbarLight .navbarToggler {
   color: #FFFFFF;
}

.navbarWrapper {
   padding: 32px 0;
}

.leftMenu1 {
   margin: 0 auto;
   padding: 0px 0px 0px 0px;
   border-radius: 0px 0px 5px 5px;

   li .navLink {
      font-family: 'ObjectSans-Regular';
      font-size: 14px;
      line-height: 20px;
      border: 1px solid hsla(0, 0%, 0%, 0.00);
      padding: 24px 10px;
      display: flex;
      justify-content: center;
      text-decoration: none;
   }

   .topic {
      font-size: 17px;
      color: #FFFFFF;
      padding: 10px 10px 15px 15px
   }
}


.navbarCollapse {
   /*display: inline-block !important;*/
   flex-basis: auto;
   justify-content: right;
   transition: none;
}

.navBarCollapseShow {
   display: none;
}

.navbarNav {
   flex-direction: row;
   flex-wrap: wrap;
   margin: unset;

   a {
      /*color: #FFFFFF !important;*/
      padding: 0 31.5px;
   }
}

.navbarExpandLg .navbarNav .navLink {
   padding-left: 0.3rem;
   padding-right: 0.3rem
}

.profileIcoImg {
   background: linear-gradient(95.34deg, #FAFF00 6.28%, #00FFC2 111.13%);
   // width: 28px;
   // height: 28px;
   float: left;
   border-radius: 14px;
   margin-top: 3px;
   margin-left: 2px;
   background-position: center top;
   border: 0px;
   background-size: cover;
   object-fit: fill;
   margin-right: 8px;

   position: relative;
   display: block;
   width: 35px;
   height: 35px;

   > p.centeredUserName {
      width: 35px;
      height: 35px;
      margin: 0;
      padding: 0;
      font-size: 16px;
      text-align: center;
      position: absolute;
      top: 63%;
      left: 50%;
      transform: translate(-50%, -50%);
   }
}

.navbar-toggler {
   padding: 0.25rem 0.75rem !important;
   font-size: 1.25rem !important;
   line-height: 1 !important;
   background-color: transparent;
   border: 1px solid transparent;
   border-radius: 0.25rem;
}

.navbar-collapse {
   background-color: #010E14;
   padding-bottom: 20px;
   width: 100%;
   margin-top: 10px;
   padding-left: 20px;
}

/*Charitha*/
.lightGreenButton {
   background: linear-gradient(95.34deg, #FAFF00 6.28%, #00FFC2 111.13%);
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
   border-radius: 2px;
   transform: skew(-17deg, 0deg);
   border: 1px solid #00FFC2;
   font-family: "OSHeavySlanted";
   font-size: 14px;
   line-height: 18px;
   /* identical to box height, or 129% */

   text-align: center;
   letter-spacing: -0.01em;
   text-transform: uppercase;
   padding: 14px 27px;
   font-weight: 700;
   -webkit-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
   transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}

.lightGreenButton:hover {
   background: linear-gradient(95.34deg, #00FFC2 6.28%, #FAFF00 111.13%);
}

.mL91 {
   margin-left: 91px;
}

.dropdown-menu a:hover {
   background-color: red !important;
   color: #000000 !important;
}

.userIcon {
   color: #FFFFFF;
   font-size: 33px;
   cursor: pointer;
}

.userIcon:hover {
   color: #19ea17;
   font-size: 33px;
}

.navbarDropDownShow {
   display: block;
   position: absolute;
   color: #FFFFFF;
}

.navbarDropDownHide {
   display: none;
   position: absolute;
   color: #FFFFFF;
}

.LoginButton {
   background: linear-gradient(0deg, #0E2029, #0E2029);
   color: #03161F;
   text-align: center;
   letter-spacing: -0.01em;
   padding: 10px 27px;
   /*font-weight: 700;*/
   width: fit-content;
   height: 46px;
   font-family: 'ObjSans-HeavySlanted';
   font-size: 14px;
   line-height: 23px;
   text-transform: uppercase;
   margin-left: 12px !important;
}

.SprtsBtnWrapper {
   border-radius: 2px;
   transform: skew(-10deg, 0deg);
   margin-top: 8px;
   margin-left: 8px;
   transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
   border: 1px solid #F7D14E;

   .SprtsBtn {
      padding: 12px !important;
      //color: linear-gradient(139.99deg, rgba(133, 98, 32, 0.2) 8.59%, rgba(244, 230, 131, 0.2) 29.64%, rgba(191, 146, 61, 0.2) 46.87%, rgba(78, 52, 27, 0.2) 66.01%, rgba(241, 234, 130, 0.2) 85.15%);
      width: 148px;
      height: 45px;
      display: block;
      font-size: 14px;
      text-transform: uppercase;
      text-align: center;
      background: linear-gradient(139.99deg, rgba(133, 98, 32, 0.2) 8.59%, rgba(244, 230, 131, 0.2) 29.64%, rgba(191, 146, 61, 0.2) 46.87%, rgba(78, 52, 27, 0.2) 66.01%, rgba(241, 234, 130, 0.2) 85.15%);

      text {
         background: linear-gradient(136.75deg, #8C421D 0%, #FBE67B 28.08%, #FCFBE7 46.23%, #F7D14E 66.46%, #D4A041 86.33%);
         line-height: 20px;
         -webkit-background-clip: text;
         background-clip: text;
         color: transparent;
         font-family: "PPObjectSans-BoldSlanted";
      }
   }

   .SprtsBtn:hover {
      text-decoration: none;
      background: linear-gradient(136.75deg, #8C421D 0%, #85622033 28.08%, #8C421D 46.23%, #85622033 66.46%, #85622033 86.33%);

   }
}

.LoginButton:hover {
   color: #03161F;
}

.LoginButton:visited {
   background: linear-gradient(95.34deg, #B0FFCA 6.28%, #F2FF7E 111.13%);
   color: #03161F;
}

.LoginButton:disabled,
.LoginButton[disabled] {
   cursor: not-allowed;
   color: #000000;
   background: linear-gradient(95.34deg, #FAFF00 6.28%, #00FFC2 111.13%);
}

.Darrow {
   transform: rotate(-180deg);
   transition: transform 150ms ease;

}

.Uarrow {
   transform: rotate(-360deg);
   transition: transform 150ms ease;

}

.navBarLoginButtonShow {
   display: none;
}

.liveIcon {
   position: absolute;
   margin-top: 18px;
   transform: rotate(-10deg);
   background-image: url("https://storage.googleapis.com/meta11/serviceplatform/liveIcon.gif");
   width: 35px;
   height: 17px;
   background-repeat: no-repeat;
   margin-left: 23px;
}

.liveIcon1 {
   position: absolute;
   display: block;
   margin-top: 18px;
   background: linear-gradient(96.32deg, #B10B8F 1.99%, #E01655 99.35%), linear-gradient(92.2deg, #E11653 2.13%, #AD0A93 106.58%);
   border: 1px solid;
   // border-image-source: linear-gradient(107.05deg, #2FFF9E -79.56%, #F9FF01 210.2%);
   border-image-source: linear-gradient(107.05deg, #E11653 -79.56%, #AD0A93 210.2%);
   // box-shadow: 0px 1px 8px 0px #DFFF15;
   box-shadow: 0px 1px 8px 0px #E11653;
   transform: skew(-10deg, 0deg);
   bottom: 2px;
   font-family: 'ObjSans-HeavySlanted';
   font-size: 12px;
   padding: 0 10px;
   line-height: 17px;
   color: #fff;
   -webkit-text-fill-color: #fff !important;

   label {
      letter-spacing: 1px;
      margin-left: -0.6px;
      margin-top: -0.3px;
      text-align: center;
      font-size: 10px;
      left: 1px;
      position: absolute;
      padding-top: 2px;
      display: block !important;
      width: 48px;
      height: 21.5px;
      /* border-radius: 50%; */
      background-color: #000;
      display: inline-block;

   }

   @keyframes blink {
      0% {
         opacity: 0
      }
      49% {
         opacity: 1
      }
      50% {
         opacity: 0
      }
   }
}


.liveIcon1 {
   position: absolute;
   top: 29px;
   width: 50px;
   height: 23px;
   background: linear-gradient(0deg, #000, #272727);
}

.liveIcon1:before, .liveIcon1:after {
   content: '';
   position: absolute;
   left: -2px;
   top: -2px;

   background: linear-gradient(90deg, #fb0094, #14D0E6, #00ff00, #ffff00,
      #14D0E6, #00ff00, #ff9500, #fb0094);


   background-size: 400%;
   width: calc(100% + 4px);
   height: calc(100% + 4px);
   z-index: -1;
   animation: steam 20s linear infinite;
}

@keyframes steam {
   0% {
      background-position: 0 0;
   }
   50% {
      background-position: 400% 0;
   }
   100% {
      background-position: 0 0;
   }
}

.liveIcon1:after {
   filter: blur(80px);
}


.CominIcon {
   position: absolute;
   margin-top: 18px;
   /*transform: rotate(-10deg);*/
   background-image: url("https://storage.googleapis.com/meta11/serviceplatform/COMING-SOON-icon.png");
   width: 129px;
   height: 35px;
   background-repeat: no-repeat;
   /*margin-left:23px;*/
}

.oneOnOneTag {
   position: absolute;
   display: block;
   background: linear-gradient(95.83deg, #445BCC -25.53%, #D52AB1 98.46%), linear-gradient(90.37deg, #D52AB1 29.3%, #445BCC 161.3%);
   border: 1px solid;
   border-image-source: linear-gradient(95.83deg, #445BCC -25.53%, #D52AB1 98.46%);
   box-shadow: 0px 1px 8px 0px rgba(213, 42, 177, 1);
   transform: skew(-10deg, 0deg);
   bottom: 2px;
   font-family: 'ObjSans-HeavySlanted';
   font-size: 12px;
   padding: 0 10px;
   line-height: 17px;
   color: rgb(255, 255, 255);
   -webkit-text-fill-color: #FFFFFF !important;
}

@media only screen and (max-width: 1200px) {
   ul.leftMenu1 {
      li:last-child {
         > button {
            margin-left: 30px !important;
            margin-right: 20px !important;
            padding: 10px 18px !important;
            width: 120px;
            display: block;
         }
      }
   }
   .leftMenu1 {
      li .navLink {
         padding: 20px 8px;
      }
   }
   .navbarBrand {
      img {
         max-width: 120px;
      }
   }

}

@media (min-width: 992px) {
   .mobileOmniCreditsNavBar {
      display: none;
   }

}

@media (max-width: 992px) {

   .leftMenu1 li {
      .navLink {
         font-family: "ObjectSans-Regular";
         font-size: 17px !important;
         line-height: 20px;
         border: 1px solid hsla(0, 0%, 0%, 0);
         padding: 12px 15px !important;
         display: flex;
         justify-content: center;
         text-decoration: none;
      }

   }
   li.navItemWithLiveTag {
      padding-bottom: 20px !important;
   }
   li {
      .OmniCreditsNavBar {
         > ul {
            li {
               padding-bottom: 0 !important;
            }
         }
      }
   }
   .blinkingRedDot {
      display: none;
   }
   .navbarWrapper {
      .OmniCreditsNavBar {
         display: none;
      }
   }
   .oneOnOneTag,
   .liveIcon1 {
      // display: none;
   }
   .leftMenu1 {
      li .navLink {
         padding: 15px 15px;
      }
   }
   #responsive-navbar-nav {

      padding-bottom: 20px;
      width: 100%;
      margin-top: 10px;
      padding-left: 20px;
   }

   .loginButtonShow {
      display: none;
   }

   .navBarLoginButtonShow {
      display: inline;
      position: absolute;
      right: 20px;
   }

   .navbar li {
      border-bottom: 0;
      z-index: 1000;
   }

   .navbarBrand {
      width: calc(100vw - 40px);
      position: absolute;
      top: 4px;
      text-align: center;
      margin-right: 0;
      padding-left: 30px;
      z-index: 20;
      left: 10px;
   }

   .navbarBrandClosed {
      padding-left: 45px;
      text-align: left;
   }

   .navbarNav {
      width: auto;
   }

   .navbarShowWrapper {
      height: 100vh;
      position: fixed;
      z-index: 200;
      left: 0;
      width: 100vw;
      display: block;
      background-color: #010E14;
   }

   .navbarShowWrapper .navbarBrand {
      text-align: center;
   }

   .navbar {
      height: 60px;
   }

   .navbarShowWrapper .navbarNav {
      width: 100vw;
      height: 100vh;
      overflow: auto;
   }

   .navbarShowWrapper .navbarCollapse {
      display: flex;
   }


   .leftMenu1 {
      margin: 60px auto 20px auto;

      li .navLink {
         font-size: 24px;
         line-height: 28px;
         letter-spacing: 2px;
         position: relative;
      }

   }

   .closeTog {
      position: absolute;
      left: 5px;
      top: -15px;
      z-index: 21;
      cursor: pointer;
      padding: 10px;

      svg {
         font-size: 45px;
      }
   }
   .navbar-dark .navbar-brand {
      padding-left: 45px;
   }

   .blinkingRedDot {
      position: absolute;
      width: 10px;
      height: 10px;
      background-color: red;
      border-radius: 5px;
      animation: blink 1s infinite;
      margin-right: 200px;
      margin-top: 8px;
   }
   .navbarCollapse {
      width: 100%;
   }
   a.profileBtnWrapper {
      margin-top: -4px !important;
      margin-right: -20px;
   }

   @keyframes blink {
      from {
         opacity: 0;
      }

      to {
         opacity: 1;
      }
   }
   .mobileOmniCreditsNavBar {
      display: block;
      width: fit-content;
      margin: 0 auto;

      ul {
         padding: 10px 45px !important;

         > li {
            margin-right: 20px;
         }
      }
   }
   .mobileLang {
      width: fit-content;
      margin: 0 auto;
   }
   .navbarWrapper {

   }
   .navbar-expand-lg .navbar-toggler {
      display: block !important;
   }
}

@media only screen and (min-width: 992px) and (max-width: 992px) {
   .navbarNav {
      margin-right: 160px;
      justify-content: right;
   }
   .leftMenu1 {
      margin: 0px !important;
   }
   .navbar-expand-lg .navbar-nav {
      justify-content: right;
      flex-direction: row;

      > a {
         padding: 20px 8px !important;
         font-size: 15px;
         line-height: 20px;
      }
   }
   .navbarBrandClosed {
      padding-left: 0 !important;
   }
   .navbarBrand {
      a {
         img {
            max-width: 120px;
         }
      }
   }
   .leftMenu1 li .navLink {
      padding: 20px 8px !important;
      letter-spacing: unset;
   }
   .liveIcon1 {
      display: block;
   }
   .mobileOmniCreditsNavBar {
      display: none;
   }
   .navbarNav:has(.navItemOmniCredits) {
      margin-right: 90px;
   }
   .navbarNav {
      margin-right: 160px;
   }
}

@media (max-width: 768px) {
   .LoginButton {
      // background: linear-gradient(95.34deg, #FAFF00 6.28%, #00FFC2 111.13%);
      // box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
      border-radius: 2px;
      transform: skew(-10deg, 0deg);
      // border: 1px solid #2FFF9E;
      /*border-image-source: linear-gradient(103.05deg, #2FFF9E 7.22%, #F9FF01 93.09%);*/
      text-align: center;
      letter-spacing: -0.01em;
      padding: 12px 16px;
      width: fit-content;
      height: 42px;
      font-family: 'ObjectSans-Regular';
      font-size: 14px;
      line-height: 10px;
      text-transform: uppercase;
      font-weight: 700;
   }

}

@media (max-width: 576px) {
   .navbarBrand img {
      height: auto;
      margin-top: 0;
      width: 100px;
   }

   .LoginButton {
      display: flex;
      padding: 14px 20px;
      font-size: 14px;
      height: 42px;
      line-height: 15px;
      right: 0px;
      position: relative;
      color: #03161F;
      top: -5px;
      right: -20px;
      align-items: center;
   }
}


/*********  iPad layouts *********/
@media only screen and (device-width: 768px) {
   /* For general iPad layouts */
   .navbarWrapper {
      nav.navbar {
         > ul {
            > li.navItem {
               display: block;
               position: relative;

               a {
                  display: block;
                  width: fit-content;

                  > span.oneOnOneTag {
                     display: block;
                     width: fit-content;
                     width: 60px;
                     text-align: center;
                     left: 28px;
                  }

                  > div {
                     position: absolute;
                     margin-top: 0 !important;
                  }
               }

               a:active {
                  color: #17EA85 !important;
               }

               button.LoginButton {
                  display: block;
                  width: fit-content;
               }
            }
         }
      }
   }
   .navItem .active {
      color: #17ea85 !important;
      background: unset;
      -webkit-text-fill-color: #17ea85 !important;
   }
   .navbar-expand-lg .navbar-collapse {
      justify-content: flex-end;
   }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
   /* For portrait layouts only */
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
   /* For landscape layouts only */
}

@media all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
   /*  ipad portrait */
   .navItem {
      a {
         display: block;
      }
   }
   .navItem .active {
      color: #17ea85 !important;
      background: unset;
      -webkit-text-fill-color: #17ea85 !important;
   }
   .navbar-expand-lg .navbar-collapse {
      justify-content: flex-end;
   }
}

@media all and (device-width: 1024px) and (device-height: 768px) and (orientation: landscape) {
   /* ipad landscape */
   .navItem {
      a {
         display: block;
      }
   }
   .navItem .active {
      color: #17ea85 !important;
      background: unset;
      -webkit-text-fill-color: #17ea85 !important;
   }
   .navbar-expand-lg .navbar-collapse {
      justify-content: flex-end;
   }
}

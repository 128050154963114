//Font face Mixin
@mixin metaFontFace($eot, $woff2, $woff, $ttf) {
   src: url($eot);
   src: url($woff2) format('woff2'),
   url($woff) format('woff'),
   url($ttf) format('truetype');
}

//Font face Mixin
// @mixin metaFontFace2($fontName, $eot, $woff2, $woff) {
//     font-family: $fontName;
//     src: url($eot);
//     src: url($woff2) format('woff2'),
//     url($woff) format('woff');
//     font-style: normal;
// }

@mixin metaFontFace2($eot, $woff2, $woff) {
   src: url($eot) format('eot'),
   url($woff2) format('$woff2'),
   url($woff) format('$woff');
}

//Heading Mixin
@mixin Typography($fontSize, $lineHeight, $letterSpacing, $case) {
   font-size: $fontSize;
   line-height: $lineHeight;
   letter-spacing: $letterSpacing;
   text-transform: $case;
   //color: $color;
}

//Match page section title
@mixin matchPageSectionTitle {
   font-family: "Montserrat", sans-serif;
   text-transform: uppercase;
}

//Page Title
@mixin pageTitle {
   font-family: $subTitleFont;
   font-size: $subTitleFontSize;
   text-transform: uppercase;
   background: linear-gradient(89.7deg, $headingGreen 2.27%, $headingBlue 23.6%, $headingGreen 44.41%, $headingBlue 64.17%, $headingGreen 82.99%, $headingBlue 99.74%);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   background-clip: text;
   text-fill-color: transparent;
   text-shadow: 0px 0px 8px rgba(20, 208, 229, 0.56);
   margin-top: 40px;
   font-weight: 800;
   line-height: 58px;
   letter-spacing: 0.02em;
}

@mixin sectionTitle {
   font-family: $sectionTitleFont;
   font-size: $sectionTitleFontSize;
   line-height: 32px;
   color: $textWhite;
   text-transform: uppercase;
   margin-top: 20px;
}

@mixin sectionTitle2 {
   // font-family: $sectionTitleFont;
   font-family: $sectionTitleFont2;
   src: url('../../assets/fonts/Solina-Light.eot');
   line-height: 32px;
   color: $textWhite;
   text-transform: uppercase;
   margin-top: 20px;
}

@mixin sectionSubTitle {
   font-family: $sectionTitleFont;
   font-size: $sectionTitleFontSize;
   line-height: 32px;
   color: $textWhite;
   text-transform: uppercase;
   margin-top: 20px;
}

@mixin sectionContent {
   font-family: $sectionContentFont;
   font-size: $sectionContentFontSize;
   line-height: 32px;
   margin-top: 15px;
   color: $textGray2;
}

@mixin GradientHeadingColor() {
   text-transform: uppercase;
   background: linear-gradient(89.7deg, $headingGreen 2.27%, $headingBlue 23.6%, $headingGreen 44.41%, $headingBlue 64.17%, $headingGreen 82.99%, $headingBlue 99.74%);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   background-clip: text;
   text-fill-color: transparent;
   text-shadow: $textShadowDepth $textShadowColorBlue;
}

@mixin WhiteHeadingColor() {
   background: linear-gradient(89.7deg, $textWhite 2.27%, $textWhite 23.6%, $textWhite 44.41%, $textWhite 64.17%, $textWhite 82.99%, $textWhite 99.74%);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   background-clip: text;
   text-fill-color: transparent;
   text-shadow: $textShadowDepth $textWhite;
}

@mixin solidHeadingColor($textColor) {
   color: $textColor;
}

@mixin sectionTitle {
   font-family: $sectionTitleFont;
   font-size: $sectionTitleFontSize;
   line-height: 32px;
   color: $textWhite;
   text-transform: uppercase;
   margin-top: 20px;
}

//Animation
@mixin css3Anim() {
   -webkit-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
   transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}

/*Cards*/
@mixin Card($Mixcard01, $Mixcard02) {
   width: 100%;
   background-image: -webkit-linear-gradient(0deg, $Mixcard01, $Mixcard02);
   background-image: -moz-linear-gradient(0deg, $Mixcard01, $Mixcard02);
   background-image: -o-linear-gradient(0deg, $Mixcard01, $Mixcard02);
   background-image: linear-gradient(0deg, $Mixcard01, $Mixcard02);
   border: 1px solid #FFFFFF0F;
}

/*Gradients*/
@mixin gradientGeneralLeft($MixWidth, $MixHeight, $MixLeft, $MixTop, $MixMainColor, $MixRadial01, $MixRadial02, $MixPos) {
   width: $MixWidth;
   height: $MixHeight;
   left: $MixLeft;
   top: $MixTop;
   background: radial-gradient($MixRadial01 $MixRadial02 at $MixPos, $MixMainColor 0%, rgba(20, 208, 230, 0) 100%);
   position: absolute;
}

@mixin gradientGeneralRight($MixWidth, $MixHeight, $MixRight, $MixTop, $MixMainColor, $MixRadial01, $MixRadial02, $MixPos) {
   width: $MixWidth;
   height: $MixHeight;
   right: $MixRight;
   top: $MixTop;
   background: radial-gradient($MixRadial01 $MixRadial02 at $MixPos, $MixMainColor 0%, rgba(20, 208, 230, 0) 100%);
   position: absolute;
}

@mixin gradientGeneralCenter($MixWidth, $MixHeight, $MixLeft, $MixTop, $MixMainColor, $MixRadial01, $MixRadial02, $MixPos) {
   width: $MixWidth;
   height: $MixHeight;
   left: $MixLeft;
   top: $MixTop;
   background: radial-gradient($MixRadial01 $MixRadial02 at $MixPos, $MixMainColor 0%, rgba(20, 208, 230, 0) 100%);
   position: absolute;
}

// Page background Gradients
@mixin bgGradientRed {
   width: 884px;
   height: 884px;
   position: absolute;
   z-index: -1;
   opacity: 0.4;
   background: radial-gradient(32.65% 32.65% at 50% 50%, #E61479 0%, rgba(230, 20, 20, 0) 100%);
}

@mixin bgGradientGreen {
   width: 884px;
   height: 884px;
   position: absolute;
   z-index: -1;
   opacity: 0.4;
   background: radial-gradient(35.01% 35.01% at 50% 47.91%, #04F0FF 0%, rgba(20, 208, 230, 0) 100%);
}

// Buttons
@mixin LightButtonAtributes($MixBtnColor1, $MixBtnColor2, $MixboxShadow, $MixBorderRadius, $Mixtransform, $Mixborder, $MixfontSize, $MixlineHeight, $MixtextTransform, $MixPadding, $MixColor) {
   background: linear-gradient(95.34deg, $MixBtnColor1 6.28%, $MixBtnColor2 111.13%);
   box-shadow: $MixboxShadow;
   border-radius: $MixBorderRadius;
   transform: $Mixtransform;
   border: $Mixborder;
   font-size: $MixfontSize;
   line-height: $MixlineHeight;
   letter-spacing: -0.01em;
   text-transform: $MixtextTransform;
   padding: $MixPadding;
   color: $MixColor !important;
}

@mixin GrayButtonAtributes($MixGrayBtnColor1, $MixGrayBtnColor2, $MixBtnColor1, $MixBtnColor2, $MixBorderRadius, $Mixtransform,  $MixtextTransform, $MixPadding) {
   background: linear-gradient(0deg, $MixGrayBtnColor1, $MixGrayBtnColor1) padding-box;
   border-radius: $MixBorderRadius;
   transform: $Mixtransform;
   text-transform: $MixtextTransform;
   padding: $MixPadding;
}

@mixin GrayButtonText($MixBtnColor1, $MixBtnColor2, $MixfontSize, $MixlineHeight) {
   background-image: linear-gradient(0deg, $MixBtnColor1, $MixBtnColor2);
   font-size: $MixfontSize;
   line-height: $MixlineHeight;
}

//Privacy
@mixin linear-gradient($angle) {
   background-image: linear-gradient($angle, $bg-color-start 0%, $bg-color-end 100%);
   background-image: -moz-linear-gradient($angle, $bg-color-start 0%, $bg-color-end 100%);
   background-image: -o-linear-gradient($angle, $bg-color-start 0%, $bg-color-end 100%);
}

@mixin gradient($angle) {
   background-image: -webkit-linear-gradient($angle, $gradient-start-color 0%, $gradient-end-color 100%);
   background-image: -moz-linear-gradient($angle, $gradient-start-color 0%, $gradient-end-color 100%);
   background-image: -o-linear-gradient($angle, $gradient-start-color 0%, $gradient-end-color 100%);
   background-image: linear-gradient($angle, $gradient-start-color 0%, $gradient-end-color 100%);
}

//Terms and Conditions
@mixin overview-box {
   background-image: -webkit-linear-gradient(0deg, #1A263980, #0B1C3580);
   background-image: -moz-linear-gradient(0deg, #1A263980, #0B1C3580);
   background-image: -o-linear-gradient(0deg, #1A263980, #0B1C3580);
   background-image: linear-gradient(0deg, #1A263980, #0B1C3580);
}

//Horizontal Divider Gradient
@mixin horizontal-divider-gradient {
   background-image: url(https://storage.googleapis.com/meta11/serviceplatform/GradientTopBorder.webp);
   background-repeat: no-repeat;
   height: 2px;
   background-position: center 0%;
   background-size: 100%;
}

@mixin overview-boxT {
   background-image: -webkit-linear-gradient(0deg, #1A263980, #0B1C3580);
   background-image: -moz-linear-gradient(0deg, #1A263980, #0B1C3580);
   background-image: -o-linear-gradient(0deg, #1A263980, #0B1C3580);
   background-image: linear-gradient(0deg, #1A263980, #0B1C3580);
}

//Back Button
@mixin blackButton {
   background: linear-gradient(0deg, #111E33, #111E33),
   linear-gradient(0deg, rgba(255, 255, 255, 0.06), rgba(255, 255, 255, 0.06));
   border: 1px solid rgba(255, 255, 255, 0.06);
   border-radius: 2px;
   color: #fff;
   padding: 10px 20px;
   font-size: 16px;
   line-height: 20px;
   letter-spacing: -0.01em;
   color: #FFFFFF;
   margin-bottom: 15px;
   text-transform: uppercase;
}

//Overview Box
@mixin overview-box {
   width: 100%;
   background-image: -webkit-linear-gradient(0deg, #1A263980, #0B1C3580);
   background-image: -moz-linear-gradient(0deg, #1A263980, #0B1C3580);
   background-image: -o-linear-gradient(0deg, #1A263980, #0B1C3580);
   background-image: linear-gradient(0deg, #1A263980, #0B1C3580);
   border: 1px solid #FFFFFF0F;
   padding: 12px 12px 12px 12px;
   position: relative;
}

//Card top borders
@mixin yellow-gradient { // #F3DE1B
   border-image: linear-gradient(to right, #f3dd1b00, #F3DE1B, #f3dd1b00) 1 0 0 0;
   border-top: 1px solid;
}

@mixin green-gradient { // #2DFEE5
   border-image: linear-gradient(to right, #f3dd1b00, #2DFEE5, #f3dd1b00) 1 0 0 0;
   border-top: 1px solid;
}

@mixin purple-gradient { // #C668FF
   border-image: linear-gradient(to right, #f3dd1b00, #C668FF, #f3dd1b00) 1 0 0 0;
   border-top: 1px solid;
}

@mixin eMulticolorFull {
   //background: radial-gradient(47.21% 47.21% at 50% 50%, #E61479 0%, rgba(230, 20, 20, 0) 100%);
   background-image: url("../../assets/images/backgrounds/MulticolorFullEllipse.webp");
   background-repeat: no-repeat;
   background-size: 100% 100%;
}

@mixin eMulticolorHalf {
   //background: radial-gradient(47.21% 47.21% at 50% 50%, #E61479 0%, rgba(230, 20, 20, 0) 100%);
   background-image: url("../../assets/images/Ellipse-half.webp ");
   background-repeat: no-repeat;
   background-size: 100% 100%;
}

@mixin ePinkFull {
   background: radial-gradient(47.21% 47.21% at 50% 50%, #e61479 0, #14d0e600 100%);
   mix-blend-mode: screen;
}
@import './scss/main.scss';

.App-logo {
   height: 40vmin;
   pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
   .App-logo {
      animation: App-logo-spin infinite 20s linear;
   }
}

.App-header {
   background-color: #282c34;
   min-height: 100vh;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   font-size: calc(10px + 2vmin);
   color: white;
}

.App-link {
   color: #61dafb;
}

@keyframes App-logo-spin {
   from {
      transform: rotate(0deg);
   }
   to {
      transform: rotate(360deg);
   }
}

.dropdown-item {
   font-family: 'ObjectSans-Regular';
   font-size: 18px;
   padding: 15px 10px 15px 25px !important;
   background-color: #111E33 !important;
   color: #95A6B7 !important;

}

.dropdown-menu {
   background-color: #111E33 !important;
   padding: 18px !important;
}

.dropdown-menu button {
   padding: 13px !important;
   text-align: center;
}


.dropdown-item:hover {
   background-color: #111E33 !important;
   background-image: url(https://storage.googleapis.com/meta11/serviceplatform/greenCheck.webp);
   background-repeat: no-repeat;
   padding-left: 25px !important;
   background-position: 0% 18px;
   color: #ffffff !important;
}


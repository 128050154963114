.li a {
   cursor: pointer;
   border-radius: 3px;
   background-color: rgba(2, 20, 29, 0);
   margin-right: 5px;
   font-size: 13px;
   border: 0;
   font-weight: 400 !important;
}

.cardCustom {
   background-color: hsla(180, 50%, 2%, 0.50);
   border-radius: 30px;
   border-width: 0px;
   padding-right: 0px;
   margin-bottom: 20px;
}

.page-link.active {
   color: #000000;
}

.pagination {
   --bs-pagination-bg: #202020;
   --bs-pagination-color: #ffffff;
   --bs-pagination-hover-color: #000000;
   --bs-pagination-hover-bg: #123954;
   --bs-pagination-padding-x: 0.8rem;
   --bs-pagination-padding-y: 0.5rem;
   --bs-pagination-font-size: 1rem;
   --bs-pagination-border-width: 0px;
   --bs-pagination-border-color: #dee2e6;
   --bs-pagination-border-radius: 0.375rem;
   --bs-pagination-hover-border-color: #dee2e6;
   --bs-pagination-focus-color: #ffffff;
   --bs-pagination-focus-bg: #e9ecef;
   --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
   --bs-pagination-active-color: #afc0cc;
   --bs-pagination-active-bg: #123954;
   --bs-pagination-active-border-color: #123954;

   --bs-pagination-disabled-color: #000000;
   --bs-pagination-disabled-bg: #000000;
   --bs-pagination-disabled-border-color: #000000;

   li .disabled {
      background-color: #ad1d9a !important;
      font-size: 30px;
   }

}

.dropdownItem:focus, .dropdownItem:hover {
   background-color: #14D0E6FF;
}

.dropdownItem {
   color: #000000;
}

.dropdownMenu {
   background-color: transparent !important;
}

.dropdownToggle {
   button {
      font-size: 13px;
      --bs-btn-color: #ffffff;
      --bs-btn-bg: transparent;
      --bs-btn-border-color: #123954;
      --bs-btn-hover-color: #fff;
      --bs-btn-hover-bg: #123954;
      --bs-btn-hover-border-color: #14D0E6FF;
      --bs-btn-focus-shadow-rgb: 49, 132, 253;
      --bs-btn-active-color: #fff;
      --bs-btn-active-bg: #14D0E6FF;
      --bs-btn-active-border-color: #14D0E6FF;
      --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
      --bs-btn-disabled-color: #fff;
      --bs-btn-disabled-bg: #14D0E6FF;
      --bs-btn-disabled-border-color: #14D0E6FF;
   }

   button:focus button:hover {
      --bs-btn-bg: transparent;
   }
}

.page-item.disabled .page-link {
   background-color: #02141d !important;
}

.dropdownItem {
   padding: 10px 10px 5px 10px !important;
}


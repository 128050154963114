@import '../../scss/utilities/mixins';
@import '../../scss/utilities/variables';

.pageWrapper {
   max-width: 1240px;
   margin: {
      left: auto;
      right: auto;
   }
}

.pageTitle {
   @include pageTitle;
}

// .sectionTitle{
//   @include sectionTitle;
//   span{
//     text-transform: none;
//   }
// }
.sectionContent {
   @include sectionContent;

   ul {
      list-style-type: none;
      margin-left: 10px;
      margin-bottom: 0;

      li {
         list-style-image: url(https://storage.googleapis.com/meta11/serviceplatform/SmallBullet.webp);
      }
   }
}

.blackButton {
   @include blackButton;

   svg {
      margin-top: -3px;
      position: relative;
      left: -9px;
   }
}

.greenBtn {
   @include blackButton;

   svg {
      margin-top: -3px;
      position: relative;
      left: -9px;
   }

   background: linear-gradient(180deg, #FAFF01 0.03%, #39FF96 86.64%);
   color: rgba(3, 22, 31, 1);

   text {
      font-size: 18px;
   }
}

.p-relative {
   position: relative;
}

.q-btn {
   width: calc(33.33% - 20px);
   margin: 0 10px;
   height: fit-content;
}

.blackButton:hover {
   background: #203455;
}

.gradient-greenBtn-notification {
   background: linear-gradient(103.05deg, #F9FF01 7.22%, #2FFF9E 93.09%),
   linear-gradient(95.34deg, #FAFF00 6.28%, #00FFC2 111.13%);
   font-size: $b4FontSize;
   text-transform: uppercase;
   padding: 7px 24px;
   font-family: $buttonText;
   color: rgba(3, 22, 31, 1);
   border: 0;
}

.gradient-greenBtn-notification:hover {
   background: linear-gradient(103.05deg, #2FFF9E 7.22%, #F9FF01 93.09%),
   linear-gradient(95.34deg, #00FFC2 6.28%, #FAFF00 111.13%);
}

.arrow-up {
   width: 0;
   height: 0;
   border-left: 10px solid transparent;
   border-right: 10px solid transparent;
   border-bottom: 10px solid #122249;
   position: absolute;
   top: -10px;
   z-index: 1;
}

.arrow-uptOutter {
   width: 0;
   height: 0;
   border-left: 12px solid transparent;
   border-right: 12px solid transparent;
   border-bottom: 12px solid rgba(255, 255, 255, 0.22);
   position: absolute;
   top: -12px;
   z-index: 0;
   left: 18px;
}

.arrow-right {
   width: 0;
   height: 0;
   border-top: 10px solid transparent;
   border-bottom: 10px solid transparent;
   position: absolute;
   right: -10px;
   border-left: 10px solid #122249;
   z-index: 1;
}

.arrow-rightOutter {
   width: 0;
   height: 0;
   border-top: 11px solid transparent;
   border-bottom: 10px solid transparent;
   position: absolute;
   right: -12px;
   border-left: 11px solid rgba(255, 255, 255, 0.22);
   z-index: 0;
   top: 19px;
}

.paginationWrapper {
   .pagination {
      justify-content: center;
      margin-bottom: 0;
   }
}

.txt-c {
   text-align: center;
}

/*answer tool tip*/
.me-1 {
   position: relative;
   cursor: pointer;

   .blackTooltip {
      position: absolute;
      background: #000;
      border-radius: 8px;
      color: #fff;
      padding: 10px;
      text-align: center;
      width: 230px;
      z-index: 10;
      bottom: 33px;
      left: -105px;
      display: none;
   }

   .blackTooltip::before {
      content: "";
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #000;
      position: absolute;
      bottom: -10px;
      left: calc(50% - 5px);
   }
}

.me-1:hover {
   .blackTooltip {
      display: block;
   }
}

/*hollow btn*/
.green-border-btn {
   border: 2px solid #F9FF01;
   padding: 15px 20px;
   background: none;
   display: inline-block;
}

.red-solid-btn {
   padding: 15px 20px;
   background: rgba(222, 74, 74, 1);
   border: 2px solid rgba(222, 74, 74, 1);
   display: inline-block;
}

.OverviewBox {
   @include overview-box;
}

//Stream Chat
.outgoing {
   display: grid;
   grid-template-columns: 1fr 36px !important;
   gap: 16px;

   > div:nth-child(1) {
      order: 2;

      > div {
         position: absolute;
         right: -12px;
      }
   }

   > div:nth-child(2) {
      > div {
         position: relative;
         // text-align: right !important;
         > span:nth-child(1) {
            float: right;
            margin-right: 0px !important;
         }

         > span.body-text5-b-italic {
            display: block;
            width: calc(100% - 90px) !important;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-left: -54px;
         }

         > span:nth-child(2) {
            position: absolute;
            left: 0;
            top: 1px;
         }
      }

      > div:nth-child(2) {
         margin-top: 20px;
         text-align: right !important;
      }

      > div:nth-child(3) {
         height: 25px;
         width: fit-content;
         float: right;

         > span {
            display: block;
            position: relative;
            width: 30px;
            float: left;
         }

         > span:nth-child(1) {
            margin-top: 2px;
         }

         > span:nth-child(2) {
            margin-top: 1px;
         }
      }
   }
}

.incoming {
   .avatarNameWrapper {
      > div:nth-child(2) {
         max-width: 210px;
      }
   }
}

.liveTag {
   display: block;
   width: -moz-fit-content;
   width: fit-content;
   background: linear-gradient(96.32deg, #B10B8F 1.99%, #E01655 99.35%), linear-gradient(92.2deg, #E11653 2.13%, #AD0A93 106.58%);
   transform: skew(-10deg, 0deg);
   bottom: 2px;
   font-family: "ObjectSans-Regular";
   letter-spacing: 2px;
   font-size: 12px;
   padding: 2px 10px;
   height: 24px;
   color: rgb(255, 255, 255);
   -webkit-text-fill-color: #FFFFFF !important;
   text-transform: uppercase;

   span {
      width: 3px;
      height: 3px;
      background-color: #fff;
      display: inline-block;
      margin-right: 4px;
      margin-bottom: 2px;
      animation: blink 2s infinite;
   }

   @keyframes blink {
      0% {
         opacity: 0
      }
      49% {
         opacity: 1
      }
      50% {
         opacity: 0
      }
   }

}

@keyframes BgGradient {
   0% {
      scale: 1;
      opacity: 1;
   }
   50% {
      scale: 1.1;
      opacity: .8;
   }
   90% {
      scale: 1.2;
      opacity: .3;
   }
   100% {
      scale: 1;
      opacity: .0;
   }
}

.TournamentMatchesLiveTag {
   .PlayNowLiveTagContainer {
      position: relative;

      .PlayNowLiveTag {
         width: -moz-fit-content;
         width: fit-content;
         // background: linear-gradient(96.32deg, #B10B8F 1.99%, #E01655 99.35%), linear-gradient(92.2deg, #E11653 2.13%, #AD0A93 106.58%);
         background: linear-gradient(-45deg, #ee7752, #e73c7e, #931cb1, #e01655);
         transform: skew(-10deg, 0deg);
         bottom: 2px;
         font-family: "ObjectSans-Regular";
         letter-spacing: 2px;
         font-size: 12px;
         padding: 2px 10px;
         height: 24px;
         color: rgb(255, 255, 255);
         -webkit-text-fill-color: #FFFFFF !important;
         text-transform: uppercase;
         display: flex;
         font-size: 10px;
         background-size: 300% 200%;
         // animation: BgGradient 1s ease infinite;
         .LiveTag {
            padding-top: 2px;
         }

         .BlinkingDot {
            display: block;
            margin-top: 8px;
            margin-right: 4px;
         }
      }

      > div, .PlayNowLiveTagWrapper {
         background: linear-gradient(-45deg, #ee7752, #e73c7e, #931cb1, #e01655);
         transform: skew(-10deg, 0deg);
         width: 102px;
         height: 27px;
         border-radius: 2px;
         position: absolute;
         top: -2px;
         animation: BgGradient 1s ease infinite;
      }
   }

   .PlayNowLiveTag {
      > div {
         padding-top: 3px;
      }
   }
}

.moreThanthree > button,
.twoAnswers > button {
   float: left;
   display: block;
   height: 52px !important;
}

.oneAnswer {
   width: fit-content;
   grid-template-columns: none !important;

   > button {
      min-width: fit-content;
      width: unset !important;
      height: 52px !important;
      margin: 0 auto;
   }
}

.black-social-btn {
   background: linear-gradient(0deg, rgba(11, 28, 53, 0.34), rgba(4, 21, 29, 0.5)),
   linear-gradient(90.95deg, rgba(4, 21, 29, 0.5) 2.62%, rgba(11, 28, 53, 0.34) 78.44%);
   border: 1px solid rgba(53, 90, 105, 1);
   font-family: "Montserrat-ExtraBoldItalic";
   border-radius: 2px;
   color: #fff;
   padding: 10px 20px;
   font-size: 16px;
   line-height: 20px;
   letter-spacing: -0.01em;
   color: #FFFFFF;
   margin-bottom: 15px;
   text-transform: uppercase;
   height: 54px;
}

.socialModalWidth {
   .modal-dialog {
      width: 800px;
   }
}

.modal-open {
   .socialModalWidth {
      overflow-x: hidden;
      overflow-y: inherit;
      position: absolute;
      height: auto;
   }
}

.mobile-lang {
   .dropdown {
      .langDrop {
         width: auto;
         border-radius: 90px;
         height: 40px;
         padding: 10px 15px;
         font-size: 14px;
         color: #fff !important;
         display: flex;
         align-items: center;
         box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.51);

         img {
            margin-right: 10px;
            width: 24px;
         }

         &::after {
            margin-left: 10px;
         }
      }

      .dropdown-menu {
         padding: 10px !important;

         .dropdown-item {
            padding: 5px 5px 5px 20px !important;
         }

         .dropdown-item:hover, .dropdown-item-active {
            background-position: 0px 12px !important;
            background-size: 10%;
            background-color: #111E33 !important;
            background-image: url(https://storage.googleapis.com/meta11/serviceplatform/greenCheck.webp);
            background-repeat: no-repeat;
            padding-left: 20px !important;
            color: #ffffff !important;
         }
      }
   }
}

.greenBall {
   background: url("../../assets/images/bigBall.webp");
   background-size: 40px 40px;
}

.redBall {
   background: url("../../assets/images/bigBall2.webp");
   background-size: 40px 40px;
}

.qbotQuestions {
   .arrow-right {
      top: 92px !important;
   }

   .arrow-rightOutter {
      top: 92px !important;
   }
}

.matches-radio-wrapper {
   margin-right: 10px !important;
   margin-left: -10px !important;

   > div {
      margin-left: -4px !important;
   }
}

//
.WinPrizes {
   position: relative;
   margin-bottom: 20px;
   border: solid 2px #112c4dcc;
   border-radius: 6px;
   background-color: #112338;
   // box-shadow: 0 8px 6px -6px #091627;

   -webkit-box-shadow: inset 0px 0px 25px 15px rgb(14 30 51);
   -moz-box-shadow: inset 0px 0px 25px 15px rgb(14 30 51);
   box-shadow: inset 0px 0px 25px 15px rgb(14 30 51);

   .WinPrizesWrapper {
      background-color: #031020;
      margin: 12px;
      border-radius: 6px;
      border: solid 2px #112c4dcc;
      padding: 32px 0;
      -webkit-box-shadow: inset 0px 0px 25px 15px rgb(11 29 45);
      -moz-box-shadow: inset 0px 0px 25px 15px rgb(11 29 45);
      box-shadow: inset 0px 0px 25px 15px rgb(11 29 45);
      text-align: center;

      .CreditWrapper {
         display: grid;
         grid-template-columns: 1fr 1fr;
         gap: 10px;
         max-width: 160px;
         margin: 0 auto;
         margin-top: 10px;

         > * {
            display: flex;
         }
      }
   }

   @mixin ConnerImg {
      width: 15px;
      height: 15px;
      background-image: url("../../assets/images/reflection.webp");
      background-repeat: no-repeat;
      position: absolute;
   }

   .Conner1 {
      @include ConnerImg;
      left: 0;
      top: 0;
   }

   .Conner2 {
      @include ConnerImg;
      right: 0;
      top: 0;
      transform: rotate(-90deg);
   }

   .Conner3 {
      @include ConnerImg;
      bottom: 0;
      right: 0;
   }

   .Conner4 {
      @include ConnerImg;
      left: 0;
      bottom: 0;
      transform: rotate(-90deg);
   }

   @mixin Shades {
      background-repeat: no-repeat;
      position: absolute;
   }

   .Shade1 {
      @include Shades;
      background-image: url("../../assets/images/shade_right_bottom.webp");
      background-repeat: no-repeat;
      width: 64px;
      height: 156px;
      bottom: 0;
      right: 0;
   }

   .Shade2 {
      @include Shades;
      background-image: url("../../assets/images/shade_left_bottom.webp");
      background-repeat: no-repeat;
      width: 64px;
      height: 156px;
      bottom: 0;
      left: 0;
   }

   .Shade3 {
      @include Shades;
      background-image: url("../../assets/images/shade_right_middle.webp");
      background-repeat: no-repeat;
      width: 64px;
      height: 210px;
      bottom: 130px;
      right: 0;
   }

   .Shade4 {
      @include Shades;
      background-image: url("../../assets/images/shade_left_middle.webp");
      background-repeat: no-repeat;
      width: 64px;
      height: 210px;
      bottom: 130px;
      left: 0;
   }

   img {
      margin: 0 auto;
   }

   .LineWrapper {
      height: 38px;
      width: fit-content;
      margin: 0 auto;

      img {
         display: block;
         margin: 0 auto;
         height: 46px;
      }
   }
}

.FantasyLeaderboardGrayButton {
   margin: 0 auto;
   background: linear-gradient(90deg, #F9FF01, #2FFF9E);
   border-radius: 2px;
   transform: skew(-10deg, 0deg);
   width: -moz-fit-content;
   width: fit-content;
   height: 47px;
   display: flex;
   justify-content: center;
   align-items: center;
   width: fit-content;
   padding: 2px;

   span {
      background-color: #0E2029;
      border-radius: 4px;
      color: #fff;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;
      padding: 10px 18px;
      border: 0;
   }
}


//Tree View
.TournamentView {
   // min-height: 300px;
   .tournamentContainer {
      // max-width: 620px;
      max-width: fit-content;
      margin: 0 auto;
      overflow-y: auto;
   }

   ul {
      li.TeamItem {
         > div {
            position: relative;
            height: 40px;
            transform: skew(-12deg, 0deg);
            width: 142px;
            border: 1px solid;
            border-image-source: linear-gradient(99.98deg, rgba(213, 42, 177, 0.2) -2.72%, rgba(68, 91, 204, 0.2) 128.13%);
            background: radial-gradient(87.12% 337.52% at 31.82% 50%, rgba(213, 42, 177, 0.2) 0%, rgba(68, 91, 204, 0.45) 100%);
            border-radius: 4px;

            span {
               position: absolute;
               top: -5px;
               left: -8px;
               display: block;
               width: 30px;
               height: 36px;
               background-image: url("../../assets/images/match_tree_img_node_back.webp");
               background-repeat: no-repeat;
               -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.83);
               -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.83);
               box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.83);
               border-radius: 4px;

               img {
                  width: 32px;
                  height: 36px;
               }
            }

            label {
               font-size: 12px;
               font-family: $sectionContentFont;
               text-align: left;
               padding-left: 29px;
               padding-top: 6px;
               width: 132px;
               white-space: nowrap;
               overflow: hidden;
               text-overflow: ellipsis;
            }
         }
      }

      .PopupButton {
         width: 32px;
         height: 32px;
         position: absolute;
         z-index: 1;
         transform: skew(12deg, 0deg);
         left: -56px;
      }

      .PopupButton:hover {
         cursor: pointer;
         opacity: .8;
         scale: 1.2;
      }
   }

   .tournamentBrackets {
      display: flex;
      flex-direction: row;
      list-style-type: none;
      //background: #fdfdfd;
      margin-bottom: 50px;
      width: fit-content;
      padding-left: 20px;
      padding-right: 20px;
   }

   .tournamentContainer::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
   }

   .tournamentContainer::-webkit-scrollbar {
      height: 11px;
   }


   .bracket {
      padding-left: 0;
      display: flex;
      margin: 0;
      padding: 30px 0;
      flex-grow: 1;
      flex-direction: column;
      justify-content: space-around;
      list-style-type: none;
      // border-right: 1px dashed #ccc;
      flex: 1;
   }

   .bracket:nth-child(2) {
      padding-left: 30px;
   }

   .bracket:nth-child(3) {
      padding-left: 30px;

      .TeamItem:nth-of-type(odd) {
         // height: 140px !important;
      }
   }

   .TeamItem {
      // background-color: #555;
      padding: .5rem;
      display: block;
      margin: 0.05rem 10px;
      position: relative;
      vertical-align: middle;
      line-height: 2;
      text-align: center;
   }

   .TeamItem:after {
      content: '';
      border-color: #445bcca3;
      border-width: 2px;
      position: absolute;
      display: block;
      width: 30px;
      right: -20px;
   }

   .TeamItem:nth-of-type(odd):after {
      border-right-style: solid;
      border-top-style: solid;
      height: 100%;
      top: 50%;
   }

   .TeamItem:nth-of-type(even):after {
      border-right-style: solid;
      border-bottom-style: solid;
      height: 100%;
      top: -50%;
   }

   .TeamItem:before {
      content: '';
      border-top: 2px solid rgba(68, 91, 204, 0.6392156863);
      position: absolute;
      height: 2px;
      width: 39px;
      left: -31px;
      top: 50%;
   }

   .bracket-2 {
      .TeamItem:nth-of-type(odd):after {
         height: 200%;
         top: 50%;
      }

      .TeamItem:nth-of-type(even):after {
         height: 200%;
         top: -150%;
      }
   }

   .bracket-3 {
      .TeamItem:nth-of-type(odd):after {
         height: 350%;
         top: 50%;
      }

      .TeamItem:nth-of-type(even):after {
         height: 350%;
         top: -300%;
      }
   }

   .bracket-4 {
      .TeamItem:nth-of-type(odd):after {
         height: 700%;
         top: 50%;
      }

      .TeamItem:nth-of-type(even):after {
         height: 700%;
         top: -650%;
      }
   }

   .bracket:first-of-type {
      .TeamItem:before {
         display: none;
      }
   }

   .bracket-4 {
      .TeamItem:after {
         display: none;
      }
   }

   .bracket:last-of-type {

      .TeamItem:after {
         display: none;
      }
   }

   .TeamItem time {
      display: inline-block;
      background-color: #dbdbdb;
      font-size: .8rem;
      padding: 0 .6rem;
   }


}

.CompletedMatchInfoBox {
   background-image: url('../../assets/images/Now_playing_block.webp');
   background-repeat: no-repeat;
   width: 526px;
   height: 300px;
   position: absolute !important;
   right: -3px;
   z-index: 1;
   top: -40px;

   .completedTag {
      width: fit-content;
      font-size: 12px;
      background: linear-gradient(92.07deg, #3421AA 2.01%, #0F7A89 100.44%) padding-box, linear-gradient(to right, #0F7A89, #3421AA) border-box;
      align-items: center;
      border-radius: 2px;
      transform: skew(-5deg, 0deg);
      height: 24px;
      padding: 10px;
      padding-left: 10px;
      padding-right: 11px;
      display: flex;
      text-transform: uppercase;
      font-family: 'ObjectSans-Regular';
      letter-spacing: 0.05em;
   }

   .ResultInfoWrapper {
      display: flex;
      gap: 5px;
      padding-top: 10px;
      margin-top: 15px;
   }

   .LogoWrapper {
      position: relative;
      width: 46px;
      height: 46px;
      padding: 4px;
      background-color: #020e14;
      border-radius: 50%;
      margin-top: 3px;

      img {
         width: 100%
      }

      .CrownWrapper {
         position: absolute;
         width: 22px;
         height: 17px;
         background-image: url('../../assets/images/crown.webp');
         background-repeat: no-repeat;
         top: -17px;
         left: 11px;
      }
   }

   .TeamName {
      width: 100px;

      h4 {
         font-size: 14px;
         font-style: italic;
         font-family: "ObjectSans-Regular";
         text-transform: uppercase;
         font-weight: 700;
         margin-top: 7px;
      }
   }

   .BatOrBall {
      width: 16px;
      margin-top: 5px;
   }

   .TotalWrapper {
      width: 80px;
      text-align: right;

      > span {
         font-size: 24px;
         color: #95A6B7;
         font-weight: 800;
         font-style: italic;
         display: block;
         width: 100%;
      }

      > label {
         display: block;
         width: 100%;
         margin-top: -2px;
         text-align: right;
      }
   }

   .ContentWrapper {
      color: white;
      display: block;
      float: right;
      margin: 50px 25px;
      width: 275px;

      > .Row {
         margin-top: 10px;
      }
   }

   .Winner {
      color: #17EA85 !important;
   }
}

//
.HowDoesBotWorkMmodal {
   .TitleWrapper {
      width: fit-content;
      margin: 0 auto;

      h4 {
         display: flex;
         width: fit-content;
         background: none !important;
         -webkit-text-fill-color: unset !important;
         background-clip: unset !important;
         font-style: normal !important;
         font-weight: 400 !important;

         img {
            max-width: 90px;
            margin: 0 8px;
         }
      }
   }

   .ModalBody {
      max-width: 450px;
      margin: 0 auto;
      padding-top: 20px;
      padding-bottom: 20px;
      padding-bottom: 0;

      P {
         font-size: 15PX;
         line-height: normal;

         span {
            display: block;
            width: 100%;
            color: #17EA85;
            line-height: 15px;
            margin-bottom: 5px;
         }
      }

   }

   .CheckBoxWrapper {
      position: relative;
   }

   .CheckBoxRow {
      max-width: 450px;
      margin: 0 auto;
      padding-left: 14px !important;
      padding-bottom: 20px;

      .CheckBoxWrapper {
         width: fit-content;

         input {
            scale: 1.5;
         }

         label {
            font-size: 14px;
            margin-left: 15px;
         }
      }

   }

   .ModalButtonRow {
      padding-bottom: 40px !important;
      padding-top: 30px !important;

      button {
         width: fit-content;
         margin: 0 auto;
         height: 45px;
         padding: 0 25px;

         text {
            background-image: linear-gradient(95.34deg, #FAFF00 6.28%, #00FFC2 111.13%);
            font-size: 14px;
            line-height: 20px;
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
            font-family: "PPObjectSans-BoldSlanted";
         }
      }
   }

   .UserProfileModal {
      margin-top: 20px
   }

   .ellipse.eMulticolorHalf {

      @include eMulticolorHalf;
      position: absolute;
      z-index: 1;
      left: 0;
      top: 57px;
      width: 96px;
      height: 157px;
      transform: rotate(180deg);
      opacity: .7;
   }

   .ellipse.eMulticolorFull {

      @include eMulticolorFull;
      position: absolute;
      z-index: 1;
      right: 55px;
      bottom: 60px;
      width: 78px;
      height: 78px;
      transform: rotate(180deg);
      opacity: .7;
   }

   .ellipse.e39 {
      position: absolute;
      z-index: 1;
      width: 157px;
      height: 157px;
      right: 0;
      opacity: 1;
      top: 0;
   }

   .ellipse.e392 {
      width: 200px;
      height: 200px;
      right: 0px;
      top: 0px;
      background: radial-gradient(32.65% 32.65% at 20% 20%, #103a5f 0%, rgba(20, 208, 230, 0) 100%);
      position: absolute;
      mix-blend-mode: screen;
      opacity: 0.4;
      z-index: 2;
      transform: rotate(90deg);
   }
}

.FantasyTeamSelector {
   .row > .CounterWrapper {
      display: flex;
      width: fit-content !important;
      gap: 10px;
      padding-left: 0 !important;
      margin-left: -10px;

      .TeamPlayerCounter {
         width: fit-content;
         display: flex;
         margin: 0 auto;
         margin-top: 2px;
         gap: 7px;

         > div {
            width: 26px;
            height: 16px;
            border-radius: 2px;
            border: solid 1px #326668;
            transform: skew(12deg, 0deg);
            background: linear-gradient(45deg, #262E35, #1e2429);
         }

         > div.Filled {
            background: linear-gradient(45deg, #00FFC2, #FAFF00);
         }
      }
   }
}

.LeagueTournamentPag {
   .TournamentFantasySection {

   }

   .PreviousFantasyTeams {
      margin-top: 27px;
   }
}

.MoreInfoBtn {
   position: absolute;
   width: 30px;
   height: 30px;
   top: 59px;
   right: 40px;
   z-index: 2;
}

.MoreInfoBtn:hover {
   cursor: pointer;
   opacity: .8;
}

.PlayerPointsRow {
   .PlayerPoints {
      display: flex;
      justify-content: center;
      border: solid 1px #9baf9b;
      border-radius: 4px;
      font-size: 12px !important;
      padding: 0 !important;

      * {
         font-family: "ObjectSans-Regular";
         font-size: 12px !important;
         font-weight: bolder;
      }

      .PointSplus {

         display: block;
         width: fit-content;
         text-align: center;

         font-weight: bold;
         font-style: normal;
         // padding-left: 5px;
         // padding-right: 2px;
      }

      .PointsWrapper {
         display: flex;
         width: -moz-fit-content;
         width: fit-content;
         margin: 0 !important;
         margin-top: 2px;
         font-size: 18px;

         .Points {
            font-size: 10px;
            font-weight: bolder;
         }

         .DecimalPoints {
            font-family: "ObjectSans-Regular";
            // font-size: 11px;
         }
      }
   }
}

@keyframes BoostIconAni {
   0% {
      scale: 1;
      opacity: 1;
   }
   50% {
      scale: 1.2;
      opacity: .6;
   }
   90% {
      scale: 1.5;
      opacity: .1;
   }
   100% {
      scale: 1;
      opacity: 1;
   }
}

.BoostIcon {
   width: 53px;
   height: 30px;
   position: absolute;
   right: 15px;
   top: 60px;
}

.BoostIcon:hover {
   animation: BoostIconAni .3s;
   cursor: pointer;
}

.BoostIconWrapper {
   width: 30px;
   height: 30px;
   position: absolute;
   right: 4px;
   top: 10px;

   .Circle {
      position: absolute;
      width: 31px;
      height: 31px;
      border-radius: 50%;
      // border: solid 2.5px #ccc;
      background: linear-gradient(145deg, #c39614, #ffffff, #7d0dc3);
      padding: 2px;
      right: 10px;
      top: 40px;
      transition: all 200ms;

      .InnerCircle {
         background-color: #000;
         border-radius: 50%;
         width: 27px;
         height: 27px;
         position: absolute;
         top: 2px;
      }

      svg {
         width: 33px;
         height: 33px;
         position: absolute;
         z-index: 3;
         left: -1px;
         top: 0px;

         .s0 {
            fill: rgb(255 183 0);
            // background: linear-gradient(-45deg, #ee7752, #e73c7e, #931cb1, #e01655);
         }
      }
   }

   .Circle:hover {
      transform: scale(1.3);
      background: linear-gradient(120deg, #7d0dc3, #ffffff, #7d0dc3);
      -webkit-box-shadow: 0px 0px 20px 3px rgba(74, 241, 247, 0.93);
      -moz-box-shadow: 0px 0px 20px 3px rgba(74, 241, 247, 0.93);
      box-shadow: 0px 0px 20px 3px rgba(74, 241, 247, 0.93);
      //cursor: pointer;
      .InnerCircle {
         background-color: #0000007d;
      }

      svg {
         .s0 {
            fill: rgb(255, 255, 255);
         }
      }
   }

   .Circle.IconDisable {
      svg {
         .s0 {
            fill: rgb(182, 182, 182);
         }
      }
   }

   .Circle.IconDisable:hover {
      transform: scale(1.1);
      background: linear-gradient(120deg, #7d0dc3, #ffffff, #7d0dc3);
      -webkit-box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.93);
      -moz-box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.93);
      box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.93);
      //cursor: pointer;
      .InnerCircle {
         background-color: #0000007d;
      }

      svg {
         .s0 {
            fill: rgb(182, 182, 182);
         }
      }
   }
}

.noPoints {
   margin-top: 0 !important;
}

.PositivePill {
   color: #000;
   background: #0ff976;
   border: solid 1px #4f9568;
}

.NagativePill {
   color: #ffffff;
   background-color: #e01655;
   border: solid 1px #7f2e43;
}

.ZeroPill {
   color: #ffffff;
   background: #112338;
   border: solid 1px #9baf9b;
}

.HistoryInfoIconBtn {
   position: absolute;
   left: -7px;
   top: 2px;
   width: 20px;
   height: 20px;
}

.HistoryInfoIconBtn:hover {
   opacity: .8;
}

.MoreInfoIconBtn {
   position: absolute;
   right: 11px;
   top: 2px;
   width: 20px;
   height: 20px;
}

.MoreInfoIconBtn:hover {
   opacity: .8;
}

.LeaderBoardBoostIconWrapper {
   width: 31px;
   height: 31px;
   border-radius: 50%;
   // border: solid 2.5px #ccc;
   background: linear-gradient(145deg, #c39614, #ffffff, #7d0dc3);
   padding: 2px;
   right: 10px;
   top: 40px;
   transition: all 200ms;

   .InnerCircle {
      background-color: #000;
      border-radius: 50%;
      width: 27px;
      height: 27px;
      position: absolute;
      top: 3px;
   }

   svg {
      width: 33px;
      height: 33px;
      position: absolute;
      z-index: 3;
      left: -1px;
      top: 0px;

      .s0 {
         fill: #fdb81d !important;
      }
   }
}

.TournamentStatus {
   background: #1f2d43;
   font-size: 14px;
   letter-spacing: -0.01em;
   text-transform: uppercase;
   border-radius: 90px !important;
   color: #95A6B7;
   display: flex;
   padding: 3px 6px;
   width: fit-content;
   padding-right: 14px;

   &.ActiveStatus {
      // border: 3px solid #17ea85;
   }

   &.InactiveStatus {
      // border: 3px solid #ff0000;
   }
}

.ActiveStatusTagWrapper {
   display: flex;

   span {
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin: 6px 4px;
      margin-top: 7px;
   }
}

.ActiveStatus {
   span {
      animation: blink 2s infinite;
      background-color: rgb(8, 241, 0);
   }
}

.InactiveStatus {
   span {
      animation: blink 2s infinite;
      background-color: red;
   }
}

.BaseModalPopUp {
   display: block;
   background: rgba(0, 0, 0, 0.2588235294);
   backdrop-filter: blur(5px);
   -webkit-backdrop-filter: blur(5px);

   .ModalBodyWrapper {
      width: 100%;
      max-width: 650px;

      .modalContentContainer {
         position: relative;
         min-height: 700px;
         box-shadow: 0px 0px 65px 0px rgba(128, 255, 95, 0.431372549);
         background-repeat: no-repeat;
         background-position: right 0 bottom 0;
         background-size: 70%;

         .closeButton {
            width: fit-content;
            position: absolute;
            z-index: 1;
            top: 10px;
            right: 15px;
         }

         .TabSetWrapper {
            .TabTitles {
               display: flex;
               gap: 10px;
               padding: 20px;

               a {
                  text-decoration: none;
                  color: #ffffffc5;
                  text-transform: uppercase;
                  width: fit-content;
                  border-bottom: solid 1px #16695a;
                  padding: 1.5px 10px;
               }

               a:hover {
                  color: #ffffffa6;
                  border-bottom: solid 1px #44fbda;
               }
            }

            .TabTitles {
               > a.active {
                  border-bottom: solid 1px #2fc9ad !important;
                  color: #fff !important;
               }
            }

            .TabContentBody {
               .UserProfileModal {
                  padding: 20px;

                  .InfoBody {
                     .PlayerSummary1 {
                        max-width: 230px;

                        .InforRow {
                           margin-bottom: 12px;

                           .Title {
                              font-size: 14px
                           }

                           span {
                              text-transform: uppercase;
                              color: #17EA85;
                              font-size: 16px;
                              font-weight: 700;
                              font-style: italic;
                           }
                        }

                     }

                     .PlayerSummary2 {
                        padding: 12px;
                        margin-top: 24px;
                        margin-bottom: 24px;
                        background: linear-gradient(180deg, rgba(26, 38, 57, 0.6) 0%, rgba(11, 28, 53, 0.6) 100%);
                        border-width: 0px, 1px, 0px, 1px;
                        border: solid 1px #1F2A36;
                        max-width: 350px;
                        min-height: 165px;
                        position: absolute;
                        z-index: 4;

                        .InforRow {
                           > div > div {
                              text-transform: uppercase;
                              color: #17EA85;
                              font-size: 16px;
                              font-weight: 700;
                              font-style: italic;

                              span {
                                 font-size: 14px;
                                 color: #fff;
                              }
                           }

                           > div > div:last-child {
                              margin-bottom: 10px;
                           }

                           .LastRow {
                              display: flex;
                              gap: 15px;

                              span {
                                 text-transform: none;
                                 font-weight: normal;
                                 font-style: normal;
                              }
                           }
                        }

                        .PlayerSummary2ColWrapper {
                           display: grid;
                           grid-template-columns: 1fr 1fr;

                           span:first-child {
                              text-transform: uppercase;
                              color: #17EA85;
                              font-size: 16px;
                              font-weight: 700;
                              font-style: italic;
                           }

                           span:last-child {
                              text-align: right;
                              font-weight: normal;
                           }
                        }
                     }

                  }
               }

               .PlayerActionImage {
                  position: absolute;
                  width: 340px;
                  height: 480px;
                  bottom: 15px;
                  right: 0;
                  z-index: 2;
               }

               h3 {
                  display: block;
                  width: 100%;
                  text-transform: uppercase;
                  font-family: Montserrat;
                  font-size: 28px;
                  font-style: italic;
                  font-weight: 800;
                  line-height: 32px;
                  letter-spacing: 0.02em;
                  text-align: center;
                  background: linear-gradient(89.7deg, #17EA85 2.27%, #14D0E6 23.6%, #17EA85 44.41%, #14D0E6 64.17%, #17EA85 82.99%, #14D0E6 99.74%);
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                  background-clip: text;
                  text-fill-color: transparent;
                  text-shadow: 0px 0px 3px rgba(20, 208, 229, 0.5607843137);
                  margin-bottom: 10px;

                  span {
                     padding-left: 5px;
                     font-size: 16px;
                  }
               }
            }
         }

         .ModalFooterBtnRow {
            position: absolute;
            width: 100%;
            bottom: 0;
         }
      }

   }

   //Backgrounds

   @mixin eMulticolorFull {
      position: absolute;
      background-image: url("../../assets/images/backgrounds/MulticolorFullEllipse.webp");
      background-repeat: no-repeat;
      background-size: 100% 100%;
   }
   @mixin eMulticolorHalf {
      position: absolute;
      background-image: url("../../assets/images/Ellipse-half.webp");
      background-repeat: no-repeat;
      background-size: 100% 100%;
   }

   .Radial1 {
      @include eMulticolorFull;
      width: 125px;
      height: 125px;
      top: 138px;
      right: 78px;
      mix-blend-mode: normal;

   }

   .Radial2 {
      @include eMulticolorHalf;
      width: 128px;
      height: 254px;
      top: 110px;
      mix-blend-mode: normal;
      transform: rotate(180deg);
   }

   .Radial3 {
      @include eMulticolorFull;
      width: 163px;
      height: 163px;
      right: 50px;
      bottom: 74px;
      z-index: 0;
      mix-blend-mode: normal;

   }

   .EdgeRadial1 {
      position: absolute;
      background-image: url("../../assets/images/backgrounds/EllipsePinkBackground.webp");
      background-repeat: no-repeat;
      background-size: 100% 100%;

      width: 315px;
      height: 212px;
      top: 0;
      right: 0;
      z-index: 0;
   }

   .EdgeRadial2 {
      position: absolute;
      background-image: url("../../assets/images/backgrounds/EdgeBlueEllipse2.webp");
      background-repeat: no-repeat;
      background-size: 100% 100%;

      width: 250px;
      height: 250px;
      bottom: 0;
      left: 0;
      z-index: 0;

   }

}

.MatchDetails {

}

@media only screen and (max-width: 1199px) {
   .MatchDetails {
      padding-right: 24px !important;
   }
}

@media only screen and (max-width: 1200px) {
   .MatchDetails {
      padding-right: 0 !important;
   }
}

.PlayingNow {
   -webkit-box-shadow: 0px 0px 14px 3px rgba(45, 255, 196, 0.52);
   -moz-box-shadow: 0px 0px 14px 3px rgba(45, 255, 196, 0.52);
   box-shadow: 0px 0px 14px 3px rgba(45, 255, 196, 0.52);
}

.Boost1 {
   border-image: linear-gradient(90deg, rgba(243, 221, 27, 0), #00B1ED, rgba(243, 221, 27, 0)) 1 0 1 0;

   .TopLeft {
      background: radial-gradient(100% 100% at 0% 0%, #14d0e66e 0%, rgba(20, 208, 230, 0) 80%);
   }

   .BottomRight {
      background: radial-gradient(100% 100% at 100% 100%, #14d0e66e 0%, rgba(20, 208, 230, 0) 80%);
   }
}

.Boost2 {
   border-image: linear-gradient(90deg, rgba(243, 221, 27, 0), #f8a3006b, rgba(243, 221, 27, 0)) 1 0 1 0;

   .TopLeft {
      background: radial-gradient(100% 100% at 0% 0%, #f8a3006b 0%, rgba(20, 208, 230, 0) 80%);
   }

   .BottomRight {
      background: radial-gradient(100% 100% at 100% 100%, #f8a3006b 0%, rgba(20, 208, 230, 0) 80%);
   }
}

.Boost3 {
   border-image: linear-gradient(90deg, rgba(243, 221, 27, 0), #0CB464, rgba(243, 221, 27, 0)) 1 0 1 0;

   .TopLeft {
      background: radial-gradient(100% 100% at 0% 0%, #0cb46482 0%, rgba(20, 208, 230, 0) 80%);
   }

   .BottomRight {
      background: radial-gradient(100% 100% at 100% 100%, #0cb46482 0%, rgba(20, 208, 230, 0) 80%);
   }
}

.Boost4 {
   border-image: linear-gradient(90deg, rgba(243, 221, 27, 0), #F9FF01, rgba(243, 221, 27, 0)) 1 0 1 0;

   .TopLeft {
      background: radial-gradient(100% 100% at 0% 0%, #f9ff0170 0%, rgba(20, 208, 230, 0) 80%);
   }

   .BottomRight {
      background: radial-gradient(100% 100% at 100% 100%, #f9ff0170 0%, rgba(20, 208, 230, 0) 80%);
   }
}

.Boost5 {
   border-image: linear-gradient(90deg, rgba(243, 221, 27, 0), #E61414, rgba(243, 221, 27, 0)) 1 0 1 0;

   .TopLeft {
      background: radial-gradient(100% 100% at 0% 0%, #e614146e 0%, rgba(20, 208, 230, 0) 80%);
   }

   .BottomRight {
      background: radial-gradient(100% 100% at 100% 100%, #e614146e 0%, rgba(20, 208, 230, 0) 80%);
   }
}

.Boost6 {
   border-image: linear-gradient(90deg, rgba(243, 221, 27, 0), #3F57F1, rgba(243, 221, 27, 0)) 1 0 1 0;

   .TopLeft {
      background: radial-gradient(100% 100% at 0% 0%, #3f57f154 0%, rgba(20, 208, 230, 0) 80%);
   }

   .BottomRight {
      background: radial-gradient(100% 100% at 100% 100%, #3f57f154 0%, rgba(20, 208, 230, 0) 80%);
   }
}

.Boost7 {
   border-image: linear-gradient(90deg, rgba(243, 221, 27, 0), #B425F7, rgba(243, 221, 27, 0)) 1 0 1 0;

   .TopLeft {
      background: radial-gradient(100% 100% at 0% 0%, #b425f773 0%, rgba(20, 208, 230, 0) 80%);
   }

   .BottomRight {
      background: radial-gradient(100% 100% at 100% 100%, #b425f773 0%, rgba(20, 208, 230, 0) 80%);
   }
}

.Boost8 {
   border-image: linear-gradient(90deg, rgba(243, 221, 27, 0), #F725BF, rgba(243, 221, 27, 0)) 1 0 1 0;

   .TopLeft {
      background: radial-gradient(100% 100% at 0% 0%, #f725bf59 0%, rgba(20, 208, 230, 0) 80%);
   }

   .BottomRight {
      background: radial-gradient(100% 100% at 100% 100%, #f725bf59 0%, rgba(20, 208, 230, 0) 80%);
   }
}

.BoostWrapper {
   .Sellected {
      -webkit-box-shadow: 0px 0px 9px 3px rgba(45, 255, 196, 0.74);
      -moz-box-shadow: 0px 0px 9px 3px rgba(45, 255, 196, 0.74);
      box-shadow: 0px 0px 9px 3px rgba(45, 255, 196, 0.74);
      border: solid 1px #a4ffe5;
   }
}

.Boostount {
   display: flex;
   gap: 5px;
}

.BoostIndicatorIcon {
   width: 33px;
   height: 33px;
   position: relative;

   svg {
      fill: #ebcd00;
      stroke: none;
      stroke-width: 2px;
      stroke: #000;
      stroke-opacity: .8;
   }
}

.BonusPointWrapper {
   background-color: #000000;
   padding: 2px 6px;
   border-radius: 4px;
   margin-top: 2px;
   border: solid 1px #535559;
   color: #17EA85 !important;
   -webkit-box-shadow: 0px 0px 9px 3px rgba(45, 255, 196, 0.3);
   -moz-box-shadow: 0px 0px 17px 3px rgba(45, 255, 196, 0.47);
   box-shadow: 0px 0px 9px 3px rgba(45, 255, 196, 0.3);

   span {
      font-size: 10px !important;
      text-transform: uppercase;
      color: #fff;
      margin-right: 2px;

      img {
         width: 12px;
         height: 12px;
         margin-right: 2px;
         -webkit-animation: spin .8s linear infinite;
         animation: spin .8s linear infinite;
      }

      @-webkit-keyframes spin {
         0% {
            scale: 1;
         }
         50% {
            scale: 1.2;
            opacity: .5;
         }
         100% {
            scale: 1;
            opacity: 1;
         }
      }

      @keyframes spin {
         0% {
            scale: 1;
         }
         50% {
            scale: 1.2;
            opacity: .5;
         }
         100% {
            scale: 1;
            opacity: 1;
         }
      }
   }

   > div {
      font-size: 12px !important;
   }
}

.SpartIcon {
   width: 15px;
   margin-left: 3px;
}

.BonusPointCell {
   width: fit-content;
   min-width: 110px !important;
   height: 25px;

   > div {
      padding: 0 !important;
      padding-right: 4px !important;
      height: 24px;
      display: flex;
      align-items: center;

      > div {
         margin-left: auto;

         img {
            margin-left: 4px;
         }
      }

      > span {
         display: flex;
      }
   }
}

.BoostRemainingTime {
   position: relative;
   height: 1px;
   width: 82px;
   display: flex;
   justify-self: center;

   svg {
      position: absolute;
      left: 0;
      top: 0;
      width: 82px;
      height: 82px;

      .TimePath1 {
         stroke: transparent;
      }

      .TimePath2 {
         stroke: #ffe384;
         stroke-dasharray: 540;
         stroke-dashoffset: 530;
      }

   }
}

.ActiveRow {
   .BonusPointWrapper {
      color: #17EA85;
   }

   .BonusPointCell {
      > div {
         span {
            color: #fff !important;

            img {
               width: 12px !important;
               height: 12px !important;
               float: none;
            }
         }

      }

      .SpartIcon {
         width: 15px !important;
      }
   }
}

.FantasyCardWrapper {
   .CardWrapper {
      .BoostRemainingTime {
         svg {
            width: 63px !important;
            height: 63px !important;
            z-index: 2;
            left: 50%;
            transform: translate(-50%, 0);

            .TimePath1 {
               stroke-width: 20;
            }

            .TimePath2 {
               stroke-width: 8;
            }
         }
      }
   }
}

.NoFantasyTeamModalWrapper {
   .BoostRemainingTime {
      width: 84px !important;

      svg {
         top: -2px;
         width: 84px;
         height: 84px;
         // z-index: 1;
         .TimePath1 {
            stroke-width: 12;
         }

         .TimePath2 {
            stroke-width: 9;
         }
      }
   }

   .BoostIconWrapper {
      right: 8px;
      top: 30px;
   }

   .Card {
      .ProfilePic {
         width: 78px !important;
         height: 78px !important;
      }
   }
}

.PowerBoosterModalLoader {
   display: flex;
   justify-content: center;
}

.NoDataWrapperInFantasyTounament {
   display: flex;
   justify-content: center;
   gap: 10px;
}

.MatchOrDetailsWrapper {
   .radioButtonlbl {
      > span {
         font-size: 16px;
         padding: 14px 6px;
      }
   }
}

body, html {
   ::-webkit-scrollbar {
      // width: 10px;
      height: 8px;
   }

   ::-webkit-scrollbar-track {
      background: #233343;
      margin-left: 10px;
   }

   ::-webkit-scrollbar-thumb {
      background: linear-gradient(180deg, #224755 -19.6%, #878787 48.63%, #646464 110.8%);
      border-radius: 5px;
   }

   ::-webkit-scrollbar-thumb:hover {
      background: #555;
   }
}

.WinnerWrapperCol {
   // .BonusPointCell{
   //    background-color: green;
   // }
   --f: .5em;

   .RibbonWrapper {
      // margin: 2rem;
      width: 100%;
      height: 1px;
      position: relative;

      .Ribbon {
         position: absolute;
         top: -4px;
         left: 20px;
         line-height: 0.6;
         padding-inline: 1lh;
         padding-bottom: var(--f);
         border-image: conic-gradient(rgba(0, 0, 0, 0.5333333333) 0 0) 51% / var(--f);
         clip-path: polygon(100% calc(100% - var(--f)), 100% 100%, calc(100% - var(--f)) calc(100% - var(--f)), var(--f) calc(100% - var(--f)), 0 100%, 0 calc(100% - var(--f)), 999px calc(100% - var(--f) - 999px), calc(100% - 999px) calc(100% - var(--f) - 999px));
         transform: translate(-29.2893218813%, -100%) rotate(-45deg);
         transform-origin: 100% 100%;
         // background-image: linear-gradient(to bottom, #aca400 0%, #7c5d00 100%);
         background-image: linear-gradient(to bottom, #7c5d00 0%, #aca400 100%);

         height: 42px;
         scale: 1.3;

         .BonusPointWrapper {
            all: unset;
            background: none;
         }
      }

   }

}

.BonusPointWrapperWinPrizes {
   display: flex;
   flex-direction: column;
   width: 100px;
   margin-top: 3px;

   > div:first-child {
      width: 100px;
      font-size: 10px;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-bottom: 2px;
      margin-top: 1px;

      span {
         img {
            max-width: 11px !important;
            -webkit-animation: spin .8s linear infinite;
            animation: spin .8s linear infinite;
            -moz-box-shadow: 0px 0px 5px 1px rgb(255 251 251 / 75%);
            box-shadow: 0px 0px 5px 1px rgb(255 251 251 / 75%);
            // border-radius: 50%;
         }
      }
   }

   > div:last-child {
      width: 100px;
      font-size: 12px;
      color: #4cff8d;
      text-shadow: 0px 0px 2px #000;
      font-weight: bold;
      letter-spacing: 2px;

      .SpartIcon {
         margin-left: 5px !important;
         -moz-box-shadow: 0px 0px 5px 1px rgb(255 251 251 / 75%);
         box-shadow: 0px 0px 5px 1px rgb(255 251 251 / 75%);
         border-radius: 50%;
      }
   }
}

@media only screen and (max-width: 768px) {
   .CompletedMatchInfoBox {
      right: -54px;
      top: 50px;
      scale: .8;
   }
}

@media only screen and (max-width: 576px) {
   .CompletedMatchInfoBox {
      right: -80px;
      top: 50px;
      scale: .7;
   }
   .MatchOrDetailsWrapper {
      .radioButtonlbl {
         > span {
            font-size: 14px;
            padding: 14px 6px;
         }
      }
   }
   .LeagueTournamentPage {
      .MatchOrDetailsWrapper {
         display: flex !important;
         justify-content: left;

         label {
            display: flex;
            justify-content: center;
         }

         span {
            font-size: 14px;
            padding: 10px 7px !important;
            width: fit-content;
         }
      }
   }
}

//heading text Colours
$headingGreen: #17EA85;
$headingBlue: #14D0E6;
$headingShadowBlue: #14d0e58f;

//body text colors
$textWhite: #fff;
$textGray1: #cbcfd2;
$textGray2: #95a6b7;
$textPink2: #ff006a;
$textturquiose: rgba(23, 234, 133, 1);
$textBlack: rgba(3, 22, 31, 1);


//Buttons


//gradients colors
$Blue01: #149AE6;
$Blue02: #14D0E6;
$Blue03: #04F0FF;
$Blue04: #04f0ff8c;
$Blue05: #103a5f;
$Pink01: #E61479;


/* Using SCSS variables to store breakpoints */
$breakpoint-tablet: 768px;


//Typography-Heading
$Montserrat-ExtraBoldItalic: "Montserrat-ExtraBoldItalic";
$Montserrat-ExtraBold: "Montserrat-ExtraBold";

$Montserrat-Regular: "Montserrat-Regular";
$Montserrat-Italic: "Montserrat-Italic";

$Montserrat-Bold: "Montserrat-Bold";
$Montserrat-BoldItalic: "Montserrat-BoldItalic";

$Montserrat-SemiBold: "Montserrat-SemiBold";
$Montserrat-SemiBoldItalic: "Montserrat-SemiBoldItalic";


$ObjectSans-Heavy: 'ObjectSans-Heavy';
//Typography - Sub titles
$subTitleFont: "SolinaExBI";
$subTitleFontSize: 52px;

//Typography - Section Title
$sectionTitleFont: "Solina-Light";
$sectionTitleFontSize: 24px;

//Typography - Section Title2
$sectionTitleFont2: "SolinaLight";
$sectionTitleFont2Url: "../../assets/fonts/Solina-Light.eot";


//Typography - Section contnet
$sectionContentFont: "ObjectSans-Regular";
$sectionContentFontSize: 22px;
$buttonText: "PPObjectSans-BoldSlanted";

//Typography - Section Sub Title
// $sectionSubTitleFont:"SolinaExBI";
// $sectionSubTitleFontSize:24px;

//TextShadow
$textShadowColorBlue: #14d0e58f;
$textShadowDepth: 0px 0px 3px;

//Heading Font variables
//Heading1-86
$h1FontSize-86: 86px;
$h1lineHeight-86: $h1FontSize-86 + 10px;
$h1letterSpacing2-86: 2%;
//Heading1
$h1FontSize: 60px;
$h1lineHeight: $h1FontSize + 10px;
$h1letterSpacing2: 2%;

//Heading2
$h2FontSize: 52px;
$h2lineHeight: $h2FontSize + 4px;
$h2letterSpacing2: 2%;

//Heading2Inside
$h2inFontSize: 52px;
$h2inlineHeight: $h2inFontSize + 6px;
$h2inletterSpacing2: 2%;

//Heading11
$h11FontSize: 42px;
$h11lineHeight: $h11FontSize + 8px;
$h11letterSpacing2: 2%;

//Heading3
$h3FontSize: 32px;
$h3lineHeight: $h3FontSize + 3px;
$h3letterSpacing2: 2%;

//Heading4
$h4FontSize: 24px;
$h4lineHeight: $h4FontSize + 4px;
$h4letterSpacing2: 2%;

//Heading5
$h5FontSize: 20px;
$h5lineHeight: $h5FontSize + 4px;
$h5letterSpacing2: 2%;

//Heading19
$h19FontSize: 19px;
$h19lineHeight: $h5FontSize + 4px;
$h19letterSpacing2: 2%;

//Heading6
$h6FontSize: 14px;
$h6lineHeight: $h6FontSize + 2px;
$h6letterSpacing2: -1px;

//Heading6
$h7FontSize: 18px;
$h7lineHeight: $h7FontSize + 2px;
$h7letterSpacing2: -1px;
//Typography-Body-text
$bodyTextMainFont: "ObjectSans-Regular";
$bodyTextMainFontThin: "SolinaLight";

//Button-text
$buttonTextFont: "PPObjectSans-BoldSlanted";
//FBody text variables
//Body Text 01
$b1FontSize: 22px;
$b1lineHeight: $b1FontSize + 6px;
$b1letterSpacing: -1px;


//Body Text 02
$b2FontSize: 18px;
$b2lineHeight: $b2FontSize + 6px;
$b2letterSpacing: -1px;


//Body Text 03
$b3FontSize: 16px;
$b3lineHeight: $b3FontSize + 6px;
$b3letterSpacing: -1px;

//Body Text 04
$b4FontSize: 14px;
$b4lineHeight: $b4FontSize + 4px;
$b4letterSpacing: -1px;

//Body Text 05
$b5FontSize: 12px;
$b5lineHeight: $b5FontSize + 2px;
$b5letterSpacing: 0px;

//Body Text 06
$b6FontSize: 10px;
$b6lineHeight: $b5FontSize + 2px;
$b6letterSpacing: 0px;
//Card Color
$card01: #1A263980;
$card02: #0B1C3580;

//Count Down bottom title
$countdown-bottom-font: "OdibeeSans-Regular";
$countdown-bottom-title: 14px;


//Count Down count
$countdown-count-font: "Audiowide-Regular";
$countdown-count: 23px;
$countdown-count-mobile: 20px;

//Gradient Color Variables
$Blue01: #149AE6;
$Blue02: #14D0E6;
$Blue03: #04F0FF;
$Blue04: #04f0ff8c;
$Blue05: #103a5f;
$Pink01: #E61479;

//Gradient Other
$GradPosLeft: 0 50%;
$GradPosRight: 100% 50%;
$GradPosCenter: 50% 50%;


//Media breakpoints
$Midia_xl: 1400px;
$Midia_lg: 1200px;
$Midia_md: 992px;
$Midia_sm: 768px;
$Midia_mob: 576px;

//Button Atributes
$buttonColor1: #FAFF01;
$buttonColor2: #39FF96;
$grayButtonColor1: #0E2029;
$grayButtonColor2: #0E2029;
$grayButtonHover: #1A4152;
$BtnboxShadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
$BtnBorderRadius: 2px;
$Btntransform: skew(-10deg, 0deg);
$Btnborder: 1px solid $buttonColor2;
$BtnfontSize: 16px;
$BtnlineHeight: $BtnfontSize + 4px;
$BtntextTransform: uppercase;
$BtnPadding: 17px 20px;
$BtnPaddingGray: 14px 20px;
$BtnColor: #000000;

//Privacy
$bg-color-start: rgba(5, 49, 69, 1.00);
$bg-color-end: rgba(2, 19, 29, 1.00);

$gradient-start-color: rgba(5, 49, 69, 1.00);
$gradient-end-color: rgba(2, 19, 29, 1.00);

//Terms and Conditions
$radial-gradient-styles: (
   width: 900px,
   height: 900px,
   mix-blend-mode: normal,
   position: absolute,
   opacity: 0.14,
   z-index: -1,
);

//PlayerProfile


/***********************/

//Primary palette
$turquiose: #17EA85;
$neutral-black: #010E14;
$neutral-white: #FFFFFF;

//Secondary Palette
$dark-red: #5F3349;
$intense-red: #FF002E;
$light-red: #DE4A4A;
$dark-green: #19624A;
$intense-green: #09AA5D;
$intense-blue: #111AF3;
$unsaturated-blue: #4E5D64;

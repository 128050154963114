@import '../../scss/globals/global';

html, body {
   overflow-x: hidden;
   // max-width: 1240px;
   margin: 0 auto;
}

body {
   width: 100%;
}

.pageWrapper {
   margin: 0 auto;
}

.bodyLeaderBoard {
   // background-image: url(https://storage.googleapis.com/meta11/serviceplatform/GradientBlue1.png),
   // 			  url(https://storage.googleapis.com/meta11/serviceplatform/GradentRed1.png),
   // 			  url(https://storage.googleapis.com/meta11/serviceplatform/GradentRed2.png);

   // background: {
   //   repeat: no-repeat, no-repeat, no-repeat;
   //   position: left top, right top, left 850px;
   //   size: auto auto;
   // }
   .TournamentButton {
      //border-radius: 90px !important;
      //padding: 12px 16px;
      border: 1px solid white;
      color: whitesmoke;
      background-color: whitesmoke;
      color: red;
      //background: #111E33;
      //color: #fff;
   }

   .ResponceCont {
      max-width: 1240px;
      margin: 0 auto;
   }

   .topLeadersRow {
      margin-bottom: 40px;

      > div {
         > div {
            padding: 0;
         }
      }

      .sectionTitle h1 {
         button {
            color: #fff !important;
            -webkit-text-fill-color: #fff !important;
         }
      }
   }

   .rightBannerRow {
      margin-bottom: 25px;

      img {
         width: 100%
      }
   }

   .horizontalDividerGradient {
      @include horizontal-divider-gradient;
   }

   .topBtnWrapper {
      padding: 0;
   }
}

h2.sectionTitle {
   margin: 30px 0 20px 14px;
   @include matchPageSectionTitle;
}

.showAllBtn {
   width: fit-content;
   color: #17EA85;
   font-weight: 700;
   font-size: 16px;
   text-align: right;
   background-color: transparent;
   border: none;
   margin-top: 35px;
   margin-right: 9px;
   float: right
}

.topBgGradientGreen {
   @include bgGradientGreen;
   top: -350px;
   left: -500px;
}

.centerBgGradientRed {
   @include bgGradientRed;
   top: 130px;
   left: 0;
   right: 0;
   margin-left: auto;
   margin-right: auto;
}

.rightBgGradientGreen {
   @include bgGradientGreen;
   top: 630px;
   right: -500px;
}

.OverviewBox {
   @include overview-box;
}


.OverviewBox {
   .smallText {
      font-size: 18px;
      color: #95A6B7;
      font-family: 'ObjectSans-Regular';
      margin-left: 10px;
   }

   .mainText {
      font-size: 36px;
      font-family: 'SolinaExBI';
      color: #ffffff;
      line-height: 70px;
   }

   .smallText {
      font-size: 18px;
      color: #95A6B7;
      font-family: 'ObjectSans-Regular';
      margin-left: 10px;
   }

   img {
      width: auto;
   }
}


.SplashBox {
   min-height: 320px;
   padding-bottom: 40px !important;
   //background-image: url(../../assets/images/leader-board-splash.webp);
   background-repeat: no-repeat;
   background-position: center 0%;
}

.upChevron {
   width: 24px;
   height: 24px;
   margin: 0 auto;
   margin-bottom: 10px;
   //background-image: url(../../assets/images/up-chevron.webp);
   background-repeat: no-repeat;
}

.downChevron {
   width: 24px;
   height: 24px;
   margin: 0 auto;
   margin-bottom: 10px;
   //background-image: url(../../assets/images/down-chevron.webp);
   background-repeat: no-repeat;
}

.outCurcleWrapper {
   width: 144px;
   height: 144px;
   position: relative;
   margin: 0 auto;
}

.curcleOut1 {
   width: 100%;
   position: relative;

   .upDown {
      margin-left: auto;
      margin-right: auto;
      width: 16px;
      height: 16px;
      margin-bottom: 5px;
   }

   .OutCurcle {
      margin-left: auto;
      margin-right: auto;
      background-image: linear-gradient(to bottom, #FAFF01 0%, #39FF96 100%);
      width: 124px;
      height: 124px;
      border-radius: 50%;
      padding-top: 2px;
      padding-right: 2px;
      padding-bottom: 2px;
      padding-left: 2px;
      box-shadow: 0px 0px 40px rgba(211, 255, 30, 0.20);
      -webkit-box-shadow: 0px 0px 40px rgba(211, 255, 30, 0.20);
   }

   .InCurcle {
      width: 120px;
      height: 120px;
      background-size: 100% auto;
      border-radius: 50%;
   }
}


.curcleCount {
   position: absolute;
   width: 28px;
   height: 28px;
   left: 0;
   right: 0;
   border-radius: 50%;
   margin-left: auto;
   margin-right: auto;
   background-image: linear-gradient(to bottom, #FAFF01 0%, #39FF96 100%);
   font-size: 14px;
   text-align: center;
   padding-top: 8px;
   margin-top: -14px;
   font-family: 'ObjectSans-Heavy';
   color: #000;
   line-height: 14px;
}

.curcleOut2 {
   width: 100%;
   // padding-top: 26px;
   .upDown {
      margin-left: auto;
      margin-right: auto;
      width: 30px;
      height: 23px;
      margin-bottom: 10px;
   }

   .OutCurcle {
      margin-left: auto;
      margin-right: auto;
      background-image: linear-gradient(to bottom, #FAFF01 0%, #39FF96 100%);
      width: 144px;
      height: 144px;
      border-radius: 50%;
      padding-top: 2px;
      padding-right: 2px;
      padding-bottom: 2px;
      padding-left: 2px;
      box-shadow: 0px 0px 40px rgba(211, 255, 30, 0.30);
      -webkit-box-shadow: 0px 0px 40px rgba(211, 255, 30, 0.30);

   }

   .InCurcle {
      width: 140px;
      height: 140px;
      background-size: 100% auto;
      border-radius: 50%;
   }

}

.leaderBox {
   height: 300px;

   > a {
      div {
         height: 60px;
         font-size: 18px;
         color: #ffffff;
         font-family: 'ObjectSans-Regular';
         text-transform: uppercase;
         letter-spacing: -0.01em;
         line-height: 24px;
         font-style: italic;
         font-weight: 700;
         margin-bottom: 5px !important;
      }
   }

   .marks {
      width: fit-content;
      padding: 0 3px;
      background: #172842;
      border: 1px solid #253342;
      border-radius: 4px;
      margin-left: auto;
      margin-right: auto;

      i {
         font-size: 10px;
         padding-right: 5px;
      }

      span {
         font-size: 14px;
         color: rgb(23, 234, 133);
      }
   }

   > a:hover {
      text-decoration: none;
   }
}

.leaderBox:nth-child(1),
.leaderBox:nth-child(3) {
   .outCurcleWrapper {
      margin-top: 27px;
   }

   .curcleOut1 {
      margin-top: 52px;
      position: relative;
   }

   .downChevron,
   .upChevron {
      position: absolute;
      left: 50%;
      top: -15%;
      transform: translate(-50%, -50%);
      max-width: 50%;
   }
}

.leaderBox:nth-child(2) {
   .trophyTextW {
      margin-top: 35px !important;
   }
}

.trophyTextW,
.leaderBoardUserName {
   height: 50px;
   font-size: 18px;
   color: #ffffff;
   font-family: 'ObjectSans-Regular';
   text-transform: uppercase;
   letter-spacing: -0.01em;
   line-height: 24px;
   font-style: italic;
   font-weight: 700;
}


.leaderBoardHigh {
   color: #000000 !important;
   background-image: linear-gradient(to bottom, #FAFF01 0%, #39FF96 100%);
   font-weight: bold;

   button {
      padding: 0 !important;
   }
}

.leaderBoardHigh td, .leaderBoardHigh td a {
   color: #000000 !important;
}

.leaderBoardHigh div .greenText {
   color: #000000 !important;
}

.leaderBoard {
   width: 100%;
   border-collapse: collapse;
   font-size: 16px;
   font-family: 'ObjectSans-Regular';
   background-color: #0c1926;
}

.leaderBoard th:not(th:nth-child(1),th:nth-child(2),th:nth-child(3),th:nth-child(9)) {
   width: 15% !important;
}

.leaderBoard th:nth-child(1), .leaderBoard td:nth-child(1) {
   width: 70px !important;
   max-width: 70px !important;
}

.leaderBoard th:nth-child(2), .leaderBoard td:nth-child(2) {
   min-width: 230px !important;
   max-width: 230px !important;
}

.leaderBoard th:nth-child(4), .leaderBoard th:nth-child(5), .leaderBoard th:nth-child(6), .leaderBoard th:nth-child(7), .leaderBoard th:nth-child(8), .leaderBoard th:nth-child(9) {
   // width: unset !important;
   // width: calc((100% - 450px) / 6);
   // min-width: calc((100% - 450px) / 6) !important;
   // width: calc((100% - 450px) / 6);
}

.leaderBoard td {
   padding: 7px;
   padding-top: 10px;
   padding-bottom: 10px;

}

.leaderBoard tr {
   color: #fff;
   cursor: pointer;
}

.leaderBoard tr a {
   color: #fff;
   cursor: pointer;
}

.leaderBoardHigh {
   color: #000000 !important;
   background-image: linear-gradient(to bottom, #FAFF01 0%, #39FF96 100%);
   font-weight: bold;

   > td * {
      color: #000 !important;
      text-align: left;
   }

   .ViewTeamBtnCell {
      display: grid;
      grid-template-columns: 1fr;

      label {
         width: 100%;
      }

      button {
         font-size: 12px !important;
         padding: 0 !important;
      }
   }
}

.leaderBoardHigh td, .leaderBoardHigh td a {
   color: #000000 !important;
}

.leaderBoardHigh td:nth-child(3) {
   min-width: 150px;
}

.leaderBoardHigh div .greenText {
   color: #000000 !important;
}

.leaderBoard text {
   color: #17EA85 !important;
}

.leaderBoard tr:nth-child(even) {
   background-color: #131f30;
   line-height: 30px;
}

.leaderBoard tr:nth-child(odd) {
   background-color: #0c1926;
   line-height: 30px;
}

.leaderBoard tr > td:first-child {
   width: 7%;
   text-align: center;
}

.leaderBoard tr > td:last-child {
   width: 23%;
}

.leaderboardRow {
   min-height: 200px;

   table.leaderBoard {
      width: 1216px;

      thead {
         tr {
            th {
               padding: 5px 6px;
               color: #95A6B7;
               font-size: 15px;
               font-style: italic;
               background-color: #1F2A36;
               // opacity: .8;
               .titleWrapper {
                  display: grid;
                  grid-template-columns: 1fr 17px;
               }

               .titleWrap {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
               }

               .topRow {
                  font-size: 16px;
                  font-weight: bold;
               }

               .bottomRow {
                  font-size: 12px;
               }

            }

            > th:nth-child(3) {
               width: 140px;
            }

            th:nth-child(5),
            th:nth-child(6) {
               max-width: 80px;
               width: fit-content;
            }

            th:nth-child(4) {
               min-width: 200px;

               .topRow {
                  width: 100%;
                  text-align: center;
                  border-bottom: solid 1px #cccccc38;
               }

               .bottomRow {
                  th {
                     min-width: 100px !important;
                  }
               }
            }

            div.sort {
               //background-image: url('../../assets/images/sort_icon.webp');
               background-repeat: no-repeat;
               background-position: right center;
            }

            th:hover {
               color: rgb(255 255 255);
               cursor: pointer;
               opacity: 1;
            }

            th.selectedcol {
               color: rgb(255 255 255);
               cursor: pointer;
               opacity: 1;
            }
         }
      }

      tr {
         td * {
            font-size: 13px;
         }

         td {
            a {
               font-size: 14px;
            }

            div {
               display: flex;
               align-items: center;

               label {
                  margin: 0;
               }
            }

            .ViewTeamBtn {
               background: #152D39;
               font-size: 14px;
               color: #17EA85 !important;
               font-style: italic;
               width: 50px;
               border-radius: 6px;
               padding: 8px 0;
               border: none;
               text-align: center;
            }

            .ViewTeamBtn:hover {
               cursor: pointer;
               opacity: .9;
            }
         }

         td:nth-child(1) { //1st col
            width: fit-content;
            width: 75px;

            > div {
               > div:nth-child(1) {
                  width: 20px;
               }

               > div:nth-child(2) {
                  width: fit-content;
               }
            }

            > div > div a {
               div, img {
                  float: left;
                  width: 39px;
                  height: 39px;
               }

               span {
                  display: block;
                  width: fit-content;
                  float: left;
                  padding-top: 2px;
               }
            }
         }

         td:nth-child(3) {
            div {
               .ViewTeamBtnCell {
                  display: flex !important;
                  min-width: 60px;
               }
            }
         }

         td:nth-child(3),
         td:nth-child(4),
         td:nth-child(5),
         td:nth-child(6) {
            max-width: 70px;
            width: fit-content;

            span {
               padding-left: 4px;
               color: #95A6B7;
               font-size: 12px;
            }
         }

         td:nth-child(5) {
            max-width: 70px;

            > div {
               display: grid;
               grid-template-columns: 13px 1fr 10px;
            }

            i {
               font-size: 10px;
               padding: 0;
               margin-top: 10px;
            }

            span {
               color: #17EA85;
            }
         }
      }

      tr {
         td {
            a {
               div.proTextImg {
                  float: left;
                  width: 39px;
                  height: 39px;
               }

               span {
                  display: block;
                  width: fit-content;
                  float: left;
               }
            }
         }
      }

      tr.leaderBoardHigh {
         td:nth-child(5) {
            span {
               color: #000;
            }
         }
      }
   }
}

.CorrectPresentCell {
   display: flex;
   gap: 15px;

   i {
      display: flex;
      align-items: center;
   }

   .BonusPointWrapper {
      background-color: #000000;
      padding: 2px 6px;
      border-radius: 4px;
      margin-top: 2px;
      border: solid 1px #535559;
      color: #17EA85;
      -webkit-box-shadow: 0px 0px 9px 3px rgba(45, 255, 196, 0.3);
      -moz-box-shadow: 0px 0px 17px 3px rgba(45, 255, 196, 0.47);
      box-shadow: 0px 0px 9px 3px rgba(45, 255, 196, 0.3);

      span {
         font-size: 10px !important;
         text-transform: uppercase;
         color: #fff;
         margin-right: 2px;

         img {
            width: 12px;
            height: 12px;
            -webkit-animation: spin .8s linear infinite;
            animation: spin .8s linear infinite;
         }

         @-webkit-keyframes spin {
            0% {
               scale: 1;
            }
            50% {
               scale: 1.2;
               opacity: .5;
            }
            100% {
               scale: 1;
               opacity: 1;
            }
         }

         @keyframes spin {
            0% {
               scale: 1;
            }
            50% {
               scale: 1.2;
               opacity: .5;
            }
            100% {
               scale: 1;
               opacity: 1;
            }
         }
      }

      > div {
         font-size: 12px !important;
      }
   }

   .BonusPointWrapper.Top3Bonus {
      background-image: linear-gradient(to bottom, #7c5d00 0%, #aca400 100%);
      box-shadow: 0px 0px 9px 3px rgb(250 255 221 / 30%);
   }

   .SpartIcon {
      width: 15px;
      margin-left: 3px;
   }
}

tr.leaderBoardHigh {
   .BonusPointWrapper {
      background-color: #670025;
      padding: 3px 6px !important;
      border-radius: 4px;
      margin-top: 2px;
      border: solid 1px #8f002c;
      // scale: 1.05;
      color: #fff !important;
      font-weight: normal;

      span {
         color: #fff !important;
         font-weight: normal;
      }

      div {
         color: #fff !important;
         font-weight: normal;
      }
   }
}

.mobileCellRow {
   // display: grid;
   // grid-template-columns: 100% 1fr 1fr 1fr;
   display: grid;

   grid-template-rows: 1fr 1fr;
   grid-template-columns: 1fr 1fr 1fr;

   gap: 0px;
   height: 100%;
   width: 100%;
   background-color: transparent !important;
   margin-bottom: 20px;

   .mobileCell {
      * {
         text-align: left;
      }

      .mobileCelltitle {
         padding: 0;
         padding-left: 0;
         color: #95A6B7;
         font-size: 14px;
         font-style: italic;
         min-width: 110px;
      }
   }

   > td {
      padding: unset;
      width: 100%;
      // font-size: 14px !important;
      > div {
         // line-height: normal;
         // padding: 0 !important;
         // line-height: unset;
      }
   }

   td:nth-child(1) {
      min-width: unset;
      grid-row-start: 1;
      grid-column-start: 1;

      grid-row-end: 2;
      grid-column-end: 4;
   }

   td:nth-child(2) {
      min-width: unset;
      grid-row-start: 2;
      grid-column-start: 1;

      grid-row-end: 3;
      grid-column-end: 2;
   }

   td:nth-child(3) {
      min-width: unset;
      grid-row-start: 2;
      grid-column-start: 2;

      grid-row-end: 3;
      grid-column-end: 3;
   }

   td:nth-child(4) {
      min-width: unset;
      grid-row-start: 2;
      grid-column-start: 3;

      grid-row-end: 3;
      grid-column-end: 4;
   }
}

.Footer {
   position: fixed;
   bottom: 0;
   width: 100%;
   left: 0;
}

.loaderContainer {
   position: fixed;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
}

.accordionBtnWrapper {
   position: absolute;
   right: 20px;
   top: 12px;
}

.proImg {
   margin-right: 10px;
   height: 36px;
   width: 36px;
   border-Radius: 50%;
   //z-index: -100;
}

.proTextImg {
   margin-right: 10px;
   height: 36px;
   width: 36px;
   display: flex;
   align-items: center;
   //background-image: url('../../assets/images/LbTextAvatarBg.svg');
   background-size: cover;
   justify-content: center;
}

.proTextImgLetters {
   background: -webkit-linear-gradient(#FAFF01, #39FF96);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   font-weight: 700;
   font-family: 'Montserrat', sans-serif;

}

.proTopUserImg {
   height: 100%;
   width: 100%;
   border-Radius: 50%;
}

.proTopUserTextImg {
   height: 100%;
   width: 100%;
   display: flex;
   align-items: center;
   //background-image: url('../../assets/images/LbTextAvatarBgWithoutBorder.svg');
   background-size: cover;
   justify-content: center;
   z-index: -10;
}

.proTopUserTextImgLetters {
   background: -webkit-linear-gradient(#FAFF01, #39FF96);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   font-weight: 500;
   font-size: 50px;
   font-family: 'Montserrat', sans-serif;
}

.notFound {
   margin: 0 auto;
   transform: translateY(400%);
   text-align: center;
   position: absolute;
}

.leaderBoardShareBtn {
   position: absolute;
   top: 20px;
   right: 15px;
}

.LeaderBoardTblWrapper::-webkit-scrollbar {
   height: 10px;
}

.LeaderBoardTblWrapper::-webkit-scrollbar-track {
   background: #f1f1f1;
}

.LeaderBoardTblWrapper::-webkit-scrollbar-thumb {
   background: linear-gradient(180deg, #224755 -19.6%, #878787 48.63%, #646464 110.8%);
   border-radius: 5px;
}

.LeaderBoardTblWrapper::-webkit-scrollbar-thumb:hover {
   background: #555;
}

.CurrentUserBoost {
   color: rgba(255, 255, 255, 0.4745098039);

   span {
      color: #fff;
      padding-left: 5px;
   }
}

@media (max-width: 1200px) {
   .topBgGradientGreen {
      display: none !important;
   }
   .centerBgGradientRed {
      display: none !important;
   }
   .rightBgGradientGreen {
      display: none !important;
   }
   .rightBannerRow:nth-child(2) {
      display: none;
   }
}

@media (min-width: 1024px) {
   .accordionBtnUp,
   .accordionBtnDown {
      display: none;
   }
   .mobileCellRow {
      display: none;;
   }
   .leaderBoard {
      margin-bottom: 20px;
   }
}

@media (max-width: 1024px) {
   .leaderBox {
      > a {
         div {
            font-size: 12px;
         }
      }
   }
   .leaderboardRow {
      margin-bottom: 20px;
   }
}

@media (max-width: 768px) {
   .leaderBox {
      height: 250px;
   }
   .SplashBox {
      min-height: 250px;
   }
   .outCurcleWrapper {
      height: 75px !important;
      width: fit-content;
   }

   .OverviewBox .mainText {
      width: 100%;
      font-size: 18px;
      font-family: 'SolinaExBI';
      color: #ffffff;
      line-height: 22px;
   }

   .OverviewBox .smallText {
      font-size: 12px;
      color: #95A6B7;
      font-family: 'ObjectSans-Regular';
      margin-left: 0px;
      line-height: 40px;
   }

   .OverviewBox .mainText img {
      max-width: 42px;
      margin-right: 80%;
      margin-bottom: 5px;
      margin-left: -10px;
   }

   .curcleOut1 .InCurcle {
      width: 60px;
      height: 60px;
      background-size: 100% auto;
      border-radius: 50%;
   }

   .curcleOut1 .OutCurcle {
      width: 64px;
      height: 64px;
   }

   .curcleOut2 {
      .InCurcle {
         width: 80px;
         height: 80px;
         background-size: 100% auto;
         border-radius: 50%;
      }

      .OutCurcle {
         width: 84px;
         height: 84px;
      }
   }

   .trophyTextW {
      height: 50px;
      font-size: 18px;
      color: #ffffff;
      font-family: 'ObjectSans-Regular';
      text-transform: uppercase;
      letter-spacing: -0.01em;
      line-height: 24px;
      font-style: italic;
      font-weight: 700;
   }

   .trophyText {
      font-Size: 14px;
      color: #17EA85;
      margin-left: 3px;
   }
   .trophyTextW {
      height: 50px;
      font-size: 18px;
      letter-spacing: -0.01em;
      line-height: 24px;
   }


   .trophyText {
      font-Size: 12px;
      color: #17EA85;
      margin-left: 3px;
   }

   .trophyTextW {
      height: 35px;
      font-size: 14px;
      letter-spacing: -0.01em;
      line-height: 18px;
   }

   .trophyTextW {
      height: 35px;
      font-size: 14px;
      letter-spacing: -0.01em;
      line-height: 18px;
   }
   // table.leaderBoard tr{
   //   position: relative;
   //   display: block;
   // }
   .leaderBoard tr > td:first-child {
      width: 7%;
      text-align: center;

   }

   .leaderBoard tr > td:last-child {
      width: 28%;
      padding: 0;
      padding-right: 5px;
   }

   .curcleCount {
      width: 22px;
      height: 22px;
      font-size: 13px;
      line-height: 7px;
   }
}

@media (max-width: 576px) {
   .leaderboardRow table.leaderBoard tr td:nth-child(1) > div > div a span {
      width: 100px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
   }
   .accordionBtnWrapper {
      position: absolute;
      right: 5px;
      top: 14px;
   }
   .leaderBox {
      > a {
         div {
            max-width: 85px;
            text-align: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0 auto;
         }
      }
   }
   .sectionTitle {
      font-size: 20px;
   }
   .SplashBox {
      min-height: auto;
      padding-bottom: 5px !important;
   }
   .leaderboardRow {
      > div {
         padding-left: 0;
         padding-right: 0;
         overflow-x: scroll !important;
      }

      table.leaderBoard {
         width: 900px;

         thead {
            tr {
               th {
                  font-size: 12px;
               }

               th:nth-child(1) {
                  width: 40px !important;
                  min-width: 40px !important;
                  // background-color: rgb(12 25 38 /1);
                  width: 40px !important;
                  min-width: 40px !important;
                  position: sticky;
                  left: 0 !important;
                  z-index: 1;
               }

               th:nth-child(2) {
                  width: 40px !important;
                  min-width: 40px !important;
                  background-color: #1F2A36;
                  width: 40px !important;
                  min-width: 40px !important;
                  position: relative;
                  left: unset;
               }
            }
         }

         tr {
            td * {
               font-size: 12px;
            }

            td:nth-child(1) {
               width: 20px !important;
               min-width: 20px !important;
               position: sticky;
               left: 0;
               background-color: rgb(19 31 48 / 1);
               z-index: 1;
            }

            td:nth-child(2) {
               max-width: 200px;
               min-width: 200px !important;
               // position: fixed;
               // z-index: 2;
            }

            td:nth-child(2) {
               label {
                  max-width: 180px;
                  min-width: 180px !important;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
               }
            }
         }

         .LeaderBoard_leaderBoard__ONZV5 tr:nth-child(odd) {
            td:nth-child(2) {
               background-color: #0c1926 !important;
            }
         }

         tr.leaderBoardHigh {
            td:nth-child(1), td:nth-child(2) {
               width: 40px !important;
               min-width: 40px !important;
               // position: sticky;
               left: 0;
               background-image: linear-gradient(to bottom, #FAFF01 0%, #39FF96 100%);
            }
         }
      }
   }
   .Globalleaderboard {
      .PaginatorWrapper {
         overflow: hidden !important;
      }
   }
   .leaderBox {
      .marks {
         display: flex;
         flex-direction: column;
         justify-content: center;
         padding: 10px 15px 02px 15px;

         img {
            margin: unset !important;
            max-width: 33px;
            display: block;
            margin: 0 auto !important;

         }
      }
   }
   .SplashBox {
      min-height: 315px;
   }
}

.statTable {
   table * {
      font-size: 13px;

      td:nth-child(3) {
         min-width: 135px;
      }

      td:nth-child(5) {
         min-width: 90px;
      }

      td:nth-child(6) {
         min-width: 140px;
      }

      td {
         padding-left: 16px;
         padding-right: 16px;

         img {
            float: left;
         }
      }
   }

   table {
      min-width: 850px;

      thead {
         tr {
            background-color: #0c1926;
            line-height: 30px;

            th {
               font-weight: bold;
               padding-left: 16px;
               padding-right: 16px;
            }
         }
      }

      tr:nth-child(even) {
         background-color: #131f30;
         line-height: 30px;
      }

      .tr:nth-child(odd) {
         background-color: #0c1926;
         line-height: 30px;
      }
   }

   .TableWrapper {
      overflow-x: auto;
      min-height: calc(100vh - 600px);
   }
}




.SchedulePage {
   form {
      max-width: 800px;
      margin: 0 auto;
      padding: 20px;
      //background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 8px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      margin-top: 16px;
   }

   .formGroup {
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;
   }

   .formGroup label {
      margin-right: 10px;
      font-weight: bold;
      color: #555;
   }

   .formGroup select,
   .formGroup input {
      flex: 1;
      padding: 8px !important;
      border: 1px solid #ccc;
      border-radius: 4px;
   }

   .teamSelectorContainer {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 20px;

      .teamSelector {
         flex: 0 0 48%; /* Each team selector takes 48% width, allowing two per row */
         margin-bottom: 10px;

         label {
            display: block;
            margin-bottom: 8px;
            font-weight: bold;
            color: #555;
         }

         select {
            width: 80%;
            padding: 8px 8px 8px 35px; /* Increased left padding to make room for image */
            border: 1px solid #ccc;
            border-radius: 4px;
            margin-left: 40px;
            background-repeat: no-repeat;
            background-position: 5px center;
            background-size: 25px 25px;
         }

         select option {
            padding-left: 35px;
            background-repeat: no-repeat;
            background-position: 5px center;
            background-size: 25px 25px;
         }
      }
   }

   .submitBtn {
      background: linear-gradient(95.34deg, #FAFF00 6.28%, #00FFC2 111.13%);
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
      border-radius: 4px;
      transform: skew(-10deg, 0deg);
      border: 1px solid #2FFF9E;
      text-align: center;
      letter-spacing: -0.01em;
      padding: 14px 27px;
      width: 100%;
      height: 54px;
      font-family: "ObjectSans-Heavy", Helvetica, Arial, sans-serif;
      font-size: 15px;
      line-height: 18px;
      text-transform: uppercase;
   }
}

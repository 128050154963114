@import '../../scss/globals/global';

.termsAndConditionsPage {

   .Radial1 {
      @each $property, $value in $radial-gradient-styles {
         #{$property}: $value;
      }

      left: -515px;
      top: -350px;
      background: radial-gradient(32.65% 32.65% at 50% 50%, #04F0FF 0%, rgba(20, 208, 230, 0) 100%);
   }

   .Radial2 {
      @each $property, $value in $radial-gradient-styles {
         #{$property}: $value;
      }

      width: 500px;
      height: 800px;
      right: 0px;
      top: 1050px;
      background: radial-gradient(32.65% 32.65% at 110% 50%, #04F0FF 0%, rgba(230, 20, 20, 0) 100%);
   }

   a {
      color: #ffffff !important;
   }

   .termsAndConditionsPage {
      background-size: auto auto;
   }

   .bodyPrivacy {
      background-image: url(https://storage.googleapis.com/meta11/serviceplatform/GradientBlue1.png),
      url(https://storage.googleapis.com/meta11/serviceplatform/GradentRed1.png),
      url(https://storage.googleapis.com/meta11/serviceplatform/GradentRed2.png);

      background-repeat: no-repeat, no-repeat, no-repeat;
      background-position: left top, right top, left 850px;
      background-size: auto auto;

   }

   .ResponceCont {
      max-width: 1240px;
      margin-left: auto;
      margin-right: auto;
   }

   .orderedList {
      margin-left: 20px;
      margin-bottom: 0px;
   }

   .privacyTable1 {
      background-color: #111E33;
      width: 100%;
      border: 1px solid #1F2A36;
      color: #95A6B7;

      th {
         color: #cfd7df;
         padding: 1%;
         border: 1px solid #1F2A36;
         font-size: 20px;
         line-height: 22px;
      }

      td {
         padding: 1%;
         border: 1px solid #1F2A36;
         font-size: 15px;
         line-height: 18px;
         vertical-align: top;
      }
   }

   .sectionTitle {
      font-size: 27px;
      line-height: 35px;
   }

   .sectionContent {
      a {
         padding-left: 4px;
      }

      a:hover {
         text-decoration: none;
         cursor: pointer;
      }
   }

   @media (max-width: 768px) {
      .sectionTitle {
         font-size: 25px;
      }
      .privacyTable1 {
         background-color: #111E33;
         width: 100%;
         border: 1px solid #1F2A36;
         color: #95A6B7;

         th {
            color: #cfd7df;
            padding: 2%;
            border: 1px solid #1F2A36;
            font-size: 17px;
            line-height: 19px;
         }

         td {
            padding: 2%;
            border: 1px solid #1F2A36;
            font-size: 12px;
            line-height: 16px;
            vertical-align: top;
         }
      }
      .pageWrapper {
         padding-top: 0 !important;
         margin-top: 0 !important;

         div.pageTitle {
            font-size: 32px;
            margin-top: -10px !important;
         }
      }

   }


   .bold {
      font-weight: 900;
   }

   .eventBannerOne {
      background-image: url("https://storage.googleapis.com/meta11/serviceplatform/event_banner_1.png");
      width: 100%;
      height: 390px;
      background-repeat: no-repeat;
      background-position: right 0%;
      background-size: auto 100%;
   }

   .eventBannerTwo {
      background-image: url("https://storage.googleapis.com/meta11/serviceplatform/event_banner_2.png");
      width: 100%;
      height: 390px;
      background-repeat: no-repeat;
      background-position: right 0%;
      background-size: auto 100%;
   }

   .cardCustom {
      background-color: hsla(180, 50%, 2%, 0.50);
      border-radius: 15px;
      border-width: 0px;
      padding-right: 0px;
      margin-bottom: 20px;
   }

   .greenGradientText {
      background: linear-gradient(to right, #19EA17 0%, #14D0E6 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
   }

   .pageContent {
      width: 100%;
      padding-top: 20px;
      padding-bottom: 10px;
      padding-left: 5%;
      padding-right: 5%;
      margin: 0 auto;
      background-image: linear-gradient(270deg, rgba(5, 49, 69, 1.00) 0%, rgba(2, 19, 29, 1.00) 100%);
      background-image: -moz-linear-gradient(270deg, rgba(5, 49, 69, 1.00) 0%, rgba(2, 19, 29, 1.00) 100%);
      background-image: -o-linear-gradient(270deg, rgba(5, 49, 69, 1.00) 0%, rgba(2, 19, 29, 1.00) 100%);
      background-image: linear-gradient(180deg, rgba(5, 49, 69, 1.00) 0%, rgba(2, 19, 29, 1.00) 100%);
   }

   @media (max-width: 768px) {

      .eventBannerOne {
         background-image: url("https://storage.googleapis.com/meta11/serviceplatform/event_banner_1.png");
         width: 100%;
         height: 390px;
         background-repeat: no-repeat;
         background-position: right 0%;
         background-size: 100% auto;
      }

      .largeCard {
         background-image: url('https://storage.googleapis.com/meta11/serviceplatform/corner-small.png'), url('https://storage.googleapis.com/meta11/serviceplatform/Frame%20804.webp');
         background-repeat: no-repeat;
         background-position: right top, 20px 380px;
         height: auto;
      }

      .smallCard {
         background-image: url('https://storage.googleapis.com/meta11/serviceplatform/corner-small.png'), url('https://storage.googleapis.com/meta11/serviceplatform/Frame%20804.webp');
         background-repeat: no-repeat;
         background-position: right top, 20px 340px;
         height: auto;
      }

      .smallLongCard {
         background-image: url('https://storage.googleapis.com/meta11/serviceplatform/corner-small.png'), url('https://storage.googleapis.com/meta11/serviceplatform/Frame%20804.webp');
         background-repeat: no-repeat;
         background-position: right top, 20px 460px;
         height: auto;
      }

      .card1Title {
         padding-left: 10px;
         padding-right: 10px;
         font-size: 25px;
         line-height: 30px;
         font-family: 'Rubik-medium', Helvetica, Arial, sans-serif;
      }

      .card1Content {
         padding-left: 10px;
         padding-right: 10px;
         font-size: 15px;
         line-height: 20px;
      }

      .card2Title {
         font-size: 18px;
      }

      .card2Content {
         padding-right: 10px;
         padding-left: 10px;
         font-size: 15px;
         line-height: 20px;
      }

      .bigBtn {
         background-color: #000000;
         padding: 10px 20px;
         color: #fff;
         font-size: 15px;
         border-radius: 20px;
         border: 2px solid hsla(0, 0%, 100%, 1.00);
         line-height: 10px;
         width: 175px;
         height: 42px;
         font-family: 'Rubik-Medium', Helvetica, Arial, sans-serif;
      }

      .orderedList {
         margin-left: 8px;
      }


      .privacyTable th {
         padding: 8px;
         font-size: 15px;
      }

      .privacyTable td {
         text-align: justify;
         text-justify: inter-word;
         padding: 7px;
         font-size: 14px;
      }
   }

   @media (min-width: 768px) {

      .largeCard {
         background-image: url('https://storage.googleapis.com/meta11/serviceplatform/corner-small.png'), url('https://storage.googleapis.com/meta11/serviceplatform/Frame%20804.webp');
         background-repeat: no-repeat;
         background-position: right top, 20px 290px;
         height: auto;
      }

      .smallCard {
         background-image: url('https://storage.googleapis.com/meta11/serviceplatform/corner-small.png'), url('https://storage.googleapis.com/meta11/serviceplatform/Frame%20804.webp');
         background-repeat: no-repeat;
         background-position: right top, 20px 170px;
         height: 200px;
      }

      .smallLongCard {
         background-image: url('https://storage.googleapis.com/meta11/serviceplatform/corner-small.png'), url('https://storage.googleapis.com/meta11/serviceplatform/Frame%20804.webp');
         background-repeat: no-repeat;
         background-position: right top, 20px 430px;
         height: 470px;
      }

      .card1Title {
         padding-left: 20px;
         padding-right: 20px;
         font-size: 35px;
         line-height: 50px;
         font-family: 'Rubik-medium', Helvetica, Arial, sans-serif;
      }

      .card1Content {
         padding-left: 20px;
         padding-right: 20px;
         font-size: 20px;
         line-height: 26px;
      }

      .card2Title {
         font-size: 23px;
      }

      .card2Content {
         padding-right: 20px;
         padding-left: 20px;
         font-size: 19px;
         line-height: 22px;
      }

      .bigBtn {
         background-color: #000000;
         padding: 10px 20px;
         color: #fff;
         font-size: 15px;
         border-radius: 20px;
         border: 2px solid hsla(0, 0%, 100%, 1.00);
         line-height: 10px;
         width: 175px;
         height: 42px;
         font-family: 'Rubik-Medium', Helvetica, Arial, sans-serif;
      }

   }

   @media (min-width: 1200px) {

      .largeCard {
         background-image: url('https://storage.googleapis.com/meta11/serviceplatform/corner-small.png'), url('https://storage.googleapis.com/meta11/serviceplatform/Frame%20804.webp');
         background-repeat: no-repeat;
         background-position: right top, 20px 170px;
         height: 200px;
      }

      .smallCard {
         background-image: url('https://storage.googleapis.com/meta11/serviceplatform/corner-small.png'), url('https://storage.googleapis.com/meta11/serviceplatform/Frame%20804.webp');
         background-repeat: no-repeat;
         background-position: right top, 20px 170px;
         height: 200px;
      }

      .smallLongCard {
         background-image: url('https://storage.googleapis.com/meta11/serviceplatform/corner-small.png'), url('https://storage.googleapis.com/meta11/serviceplatform/Frame%20804.webp');
         background-repeat: no-repeat;
         background-position: right top, 20px 440px;
         height: 470px;
      }

      .card1Title {
         font-size: 40px;
         line-height: 55px;
         font-family: 'Rubik-medium', Helvetica, Arial, sans-serif;
      }

      .card1Content {
         padding-left: 20px;
         padding-right: 20px;
         font-size: 20px;
         line-height: 31px;
      }

      .card2Title {
         font-size: 25px;
      }

      .card2Content {
         padding-right: 20px;
         padding-left: 20px;
         font-size: 20px;
         line-height: 25px;
      }

      .bigBtn {
         background-color: #000000;
         padding: 10px 20px;
         color: #fff;
         font-size: 15px;
         border-radius: 20px;
         border: 2px solid hsla(0, 0%, 100%, 1.00);
         line-height: 10px;
         width: 175px;
         height: 42px;
         font-family: 'Rubik-Medium', Helvetica, Arial, sans-serif;
      }
   }

   @media (min-width: 1440px) {

      .largeCard {
         background-image: url('https://storage.googleapis.com/meta11/serviceplatform/corner-small.png'), url('https://storage.googleapis.com/meta11/serviceplatform/Frame%20804.webp');
         background-repeat: no-repeat;
         background-position: right top, 20px 170px;
         height: 200px;
      }

      .smallCard {
         background-image: url('https://storage.googleapis.com/meta11/serviceplatform/corner-small.png'), url('https://storage.googleapis.com/meta11/serviceplatform/Frame%20804.webp');
         background-repeat: no-repeat;
         background-position: right top, 20px 170px;
         height: 200px;
      }

      .smallLongCard {
         background-image: url('https://storage.googleapis.com/meta11/serviceplatform/corner-small.png'), url('https://storage.googleapis.com/meta11/serviceplatform/Frame%20804.webp');
         background-repeat: no-repeat;
         background-position: right top, 20px 400px;
         height: 430px;
      }

      .card1Title {
         font-size: 45px;
         line-height: 50px;
         font-family: 'Rubik-medium', Helvetica, Arial, sans-serif;
      }

      .card1Content {
         font-size: 23px;
         line-height: 36px;
      }

      .card2Title {
         font-size: 25px;
      }

      .card2Content {
         font-size: 20px;
         line-height: 25px;
      }

      .bigBtn {
         background-color: #000000;
         padding: 10px 20px;
         color: #fff;
         font-size: 15px;
         border-radius: 20px;
         border: 2px solid hsla(0, 0%, 100%, 1.00);
         line-height: 10px;
         width: 175px;
         height: 42px;
         font-family: 'Rubik-Medium', Helvetica, Arial, sans-serif;
      }
   }

   .slashBig {
      background-image: url("https://storage.googleapis.com/meta11/serviceplatform/slash-big.png");
      background-repeat: no-repeat;
      background-position: left bottom;
   }

   .slashSmall {
      background-image: url("https://storage.googleapis.com/meta11/serviceplatform/slash-small.png");
      background-repeat: no-repeat;
      background-position: left top;
   }

   .gradientBg {
      background-image: -webkit-linear-gradient(270deg, rgba(5, 49, 69, 1.00) 0%, rgba(2, 19, 29, 1.00) 100%);
      background-image: -moz-linear-gradient(270deg, rgba(5, 49, 69, 1.00) 0%, rgba(2, 19, 29, 1.00) 100%);
      background-image: -o-linear-gradient(270deg, rgba(5, 49, 69, 1.00) 0%, rgba(2, 19, 29, 1.00) 100%);
      background-image: linear-gradient(180deg, rgba(5, 49, 69, 1.00) 0%, rgba(2, 19, 29, 1.00) 100%);
   }
}

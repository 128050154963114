//width 1400px
@media (min-width: $Midia_xl) {

}

@media (max-width: $Midia_xl) {

}

//width 1200px
@media (max-width: $Midia_lg) {

}

//width 992px
@media (max-width: $Midia_md) {
   .socialModalWidth > div {
      width: 800vw;
   }
   .black-social-btn {
      font-size: 14px;
      height: 36px;
   }
}

//width 768px
@media (max-width: $Midia_sm) {
   //Tables-leaderboard
   .leaderBoard {
      width: 100%;
      border-collapse: collapse;
      font-size: 14px;
      font-family: 'ObjectSans-Regular';
   }
   .leaderBoard tr > td:first-child {
      width: 12%;
      text-align: center;

   }
   .leaderBoard tr > td:last-child {
      width: 25%;
      padding: 0;
   }
   //Buttons
   .lightGreenButton {
      font-size: 16px;
      height: 42px;
      padding: 20px 20px;
      line-height: 12px;
   }
   .GrayButton, .lightGreenButton {
      width: 46%;
      height: auto;
   }
   .GrayButton {
      padding: 10px 5px;
   }
   .GrayButton text {
      font-size: 14px;
      line-height: 12px;
   }
   .Sign-up-to-secure .GrayButton {
      width: 100px;
   }
   .blue-notification-wrapper {
      width: calc(100% - 20px);
   }
   .arrow-right {
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #122249;
      position: absolute;
      top: -20px;
      right: inherit;
   }
   .arrow-rightOutter {
      width: 0;
      height: 0;
      border-left: 11px solid transparent;
      border-right: 11px solid transparent;
      border-bottom: 11px solid #ffffff22;
      position: absolute;
      top: -23px;
      right: inherit;
   }
   .socialModalWidth > div {
      width: 90vw;
   }
   .socialModalWidth > div > div {
      width: 90vw;
   }
   .SplashBox {
      min-height: 280px;
      padding-bottom: 13px !important;
   }
   .black-social-btn {
      font-size: 14px;
      height: 36px;
   }
   ul.q-bot-dropdown {
      top: auto;
      bottom: -15px;
   }

}

//width 576px
@media (max-width: $Midia_mob) {
   .socialModalWidth > div {
      width: 90vw;
   }
   .black-social-btn {
      margin-bottom: 10px;
      height: 33px;
   }
   .lightGreenButton {
      font-size: 14px;
      padding: 10px 20px;
      line-height: 16px;
   }
   .black-social-btn {
      font-size: 14px;
      height: 36px;
   }
}

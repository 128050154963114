@import 'variables';
@import 'mixins';

//Heading Font Face

@font-face {
   font-family: "Montserrat";
   @include metaFontFace('../../assets/fonts/montserrat/' + $Montserrat-ExtraBoldItalic + '.eot', '../../assets/fonts/montserrat/' + $Montserrat-ExtraBoldItalic + '.woff2', '../../assets/fonts/montserrat/' + $Montserrat-ExtraBoldItalic + '.woff', '../../assets/fonts/montserrat/' + $Montserrat-ExtraBoldItalic + '.otf');
   font-weight: 800;
   font-style: italic;
}

@font-face {
   font-family: "Montserrat";
   @include metaFontFace('../../assets/fonts/montserrat/' + $Montserrat-ExtraBold + '.eot', '../../assets/fonts/montserrat/' + $Montserrat-ExtraBold + '.woff2', '../../assets/fonts/montserrat/' + $Montserrat-ExtraBold + '.woff', '../../assets/fonts/montserrat/' + $Montserrat-ExtraBold + '.otf');
   font-weight: 800;
}

@font-face {
   font-family: "Montserrat";
   @include metaFontFace('../../assets/fonts/montserrat/' + $Montserrat-Regular + '.eot', '../../assets/fonts/montserrat/' + $Montserrat-Regular + '.woff2', '../../assets/fonts/montserrat/' + $Montserrat-Regular + '.woff', '../../assets/fonts/montserrat/' + $Montserrat-Regular + '.otf');
   font-weight: 400;
}

@font-face {
   font-family: "Montserrat";
   @include metaFontFace('../../assets/fonts/montserrat/' + $Montserrat-Italic + '.eot', '../../assets/fonts/montserrat/' + $Montserrat-Italic + '.woff2', '../../assets/fonts/montserrat/' + $Montserrat-Italic + '.woff', '../../assets/fonts/montserrat/' + $Montserrat-Italic + '.otf');
   font-weight: 400;
   font-style: italic;
}

@font-face {
   font-family: "Montserrat";
   @include metaFontFace('../../assets/fonts/montserrat/' + $Montserrat-Bold + '.eot', '../../assets/fonts/montserrat/' + $Montserrat-Bold + '.woff2', '../../assets/fonts/montserrat/' + $Montserrat-Bold + '.woff', '../../assets/fonts/montserrat/' + $Montserrat-Bold + '.otf');
   font-weight: 700;
}

@font-face {
   font-family: "Montserrat";
   @include metaFontFace('../../assets/fonts/montserrat/' + $Montserrat-BoldItalic + '.eot', '../../assets/fonts/montserrat/' + $Montserrat-BoldItalic + '.woff2', '../../assets/fonts/montserrat/' + $Montserrat-BoldItalic + '.woff', '../../assets/fonts/montserrat/' + $Montserrat-BoldItalic + '.otf');
   font-weight: 700;
   font-style: italic;
}

@font-face {
   font-family: "Montserrat";
   @include metaFontFace('../../assets/fonts/montserrat/' + $Montserrat-SemiBold + '.eot', '../../assets/fonts/montserrat/' + $Montserrat-SemiBold + '.woff2', '../../assets/fonts/montserrat/' + $Montserrat-SemiBold + '.woff', '../../assets/fonts/montserrat/' + $Montserrat-SemiBold + '.otf');
   font-weight: 600;
}

@font-face {
   font-family: "Montserrat";
   @include metaFontFace('../../assets/fonts/montserrat/' + $Montserrat-SemiBoldItalic + '.eot', '../../assets/fonts/montserrat/' + $Montserrat-SemiBoldItalic + '.woff2', '../../assets/fonts/montserrat/' + $Montserrat-SemiBoldItalic + '.woff', '../../assets/fonts/montserrat/' + $Montserrat-SemiBoldItalic + '.otf');
   font-weight: 600;
   font-style: italic;
}

@font-face {
   font-family: "ObjectSans-Heavy";
   @include metaFontFace('../../assets/fonts/'+ $ObjectSans-Heavy +'.eot', '../../assets/fonts/'+ $ObjectSans-Heavy +'.woff2', '../../assets/fonts/'+ $ObjectSans-Heavy + '.woff', '../../assets/fonts/'+ $ObjectSans-Heavy +'.otf')
}

/*body text*/
@font-face {
   font-family: $bodyTextMainFont;
   @include metaFontFace('../../assets/fonts/' + $bodyTextMainFont + '.eot', '../../assets/fonts/' + $bodyTextMainFont + '.woff2', '../../assets/fonts/' + $bodyTextMainFont + '.woff', '../../assets/fonts/' + $bodyTextMainFont + '.otf')
}

/* Countdown*/
@font-face {
   font-family: $countdown-count-font;
   @include metaFontFace('../../assets/fonts/' + $countdown-count-font + '.eot', '../../assets/fonts/' + $countdown-count-font + '.woff2', '../../assets/fonts/' + $countdown-count-font + '.woff', '../../assets/fonts/' + $countdown-count-font + '.otf')
}

@font-face {
   font-family: $countdown-bottom-font;
   @include metaFontFace('../../assets/fonts/' + $countdown-bottom-font + '.eot', '../../assets/fonts/' + $countdown-bottom-font + '.woff2', '../../assets/fonts/' + $countdown-bottom-font + '.woff', '../../assets/fonts/' + $countdown-bottom-font + '.otf')
}

/*Button text*/
@font-face {
   font-family: $buttonTextFont;
   @include metaFontFace('../../assets/fonts/' + $buttonTextFont + '.eot', '../../assets/fonts/' + $buttonTextFont + '.woff2', '../../assets/fonts/' + $buttonTextFont + '.woff', '../../assets/fonts/' + $buttonTextFont + '.otf')
}

@font-face {
   font-family: $buttonTextFont;
   @include metaFontFace('../../assets/fonts/' + $buttonTextFont + '.eot', '../../assets/fonts/' + $buttonTextFont + '.woff2', '../../assets/fonts/' + $buttonTextFont + '.woff', '../../assets/fonts/' + $buttonTextFont + '.otf')
}

/*profile text*/
// @font-face {
//     @include metaFontFace2($bodyTextMainFontThin, '../../assets/fonts/' + $bodyTextMainFontThin + '.eot',  '../../assets/fonts/' + $bodyTextMainFontThin + '.woff2', '../../assets/fonts/' + $bodyTextMainFontThin + '.woff')
// }

/*Section Title Font Solina-Light*/
@font-face {
   font-family: $sectionTitleFont;
   @include metaFontFace2('../../assets/fonts/' + $sectionTitleFont + '.eot', '../../assets/fonts/' + $sectionTitleFont + '.woff2', '../../assets/fonts/' + $sectionTitleFont + '.woff')
}

// @font-face{
//     @include metaFontFace2($sectionTitleFont,'../../assets/fonts/' + $sectionTitleFont + '.eot', $sectionTitleFont, '../../assets/fonts/' + $sectionTitleFont + '.woff2', $sectionTitleFont,'../../assets/fonts/' + $sectionTitleFont + '.woff')
// }


// @font-face {
//     @include MIfontface('OswaldLight', 'Assets/fonts/Oswald-Light.eot', 'Assets/fonts/Oswald-Light.eot?#iefix', 'Assets/fonts/Oswald-Light.woff2', 'Assets/fonts/Oswald-Light.woff', 'Assets/fonts/Oswald-Light.ttf', 'Assets/fonts/Oswald-Light.svg#Oswald-Light','300')
// }
// @font-face {
//     @include MIfontface('OswaldBold', 'Assets/fonts/Oswald-Bold.eot', 'Assets/fonts/Oswald-Bold.eot?#iefix', 'Assets/fonts/Oswald-Bold.woff2', 'Assets/fonts/Oswald-Bold.woff', 'Assets/fonts/Oswald-Bold.ttf', 'Assets/fonts/Oswald-Bold.svg#Oswald-Bold','700')
// }

//Heding Types
.heading1 {
   font-family: "Montserrat";
   @include Typography($h1FontSize, $h1lineHeight, $h1letterSpacing2, uppercase);
   font-weight: 800;
   font-style: italic;
}

.heading1Muted {
   font-family: "Montserrat";
   @include Typography(16px, 28px, 0px, uppercase);
}

// .heading2-1{
//     font-family: "Montserrat";
//     @include Typography($h2inFontSize, $h2inlineHeight, $h2inletterSpacing2, uppercase);
//     @include GradientHeadingColor()
// }
.heading2 {
   font-family: "Montserrat";
   @include Typography($h2FontSize, $h2lineHeight, $h2letterSpacing2, uppercase);
   font-weight: 800;
   font-style: italic;
}

.heading3 {
   font-family: "Montserrat";
   @include Typography($h3FontSize, $h3lineHeight, $h3letterSpacing2, uppercase);
}

// .heading3SemiBold {
//     font-family: "Montserrat";
//     @include Typography($h3FontSize, $h3lineHeight, $h3letterSpacing2, uppercase);
//     @include solidHeadingColor($textWhite)
// }
// .heading3GradientGreen {
//     font-family: "Montserrat";
//     @include Typography($h3FontSize, $h3lineHeight, $h3letterSpacing2, uppercase);
//     @include GradientHeadingColor()
// }
.heading4 {
   font-family: "Montserrat";
   @include Typography($h4FontSize, $h4lineHeight, $h4letterSpacing2, capitalize);
   font-weight: 700;
}

// .heading4-semi-bold {
//     font-family: "Montserrat";
//     @include Typography($h4FontSize, $h4lineHeight, $h4letterSpacing2, capitalize);
// }
// .heading4-bold {
//     font-family: "Montserrat";
//     @include Typography($h4FontSize, $h4lineHeight, $h4letterSpacing2, capitalize);
// }
// .heading4-extra-bold {
//     font-family: "Montserrat";
//     @include Typography($h4FontSize, $h4lineHeight, $h4letterSpacing2, capitalize);
// }
.heading5 {
   font-family: "Montserrat";
   @include Typography($h5FontSize, $h5lineHeight, $h5letterSpacing2, capitalize);
   @include solidHeadingColor($textWhite)
}

.heading19 {
   font-family: "Montserrat";
   @include Typography($h19FontSize, $h19lineHeight, $h19letterSpacing2, capitalize);
   @include solidHeadingColor($textWhite)
}

.heading6 {
   font-family: "Montserrat";
   @include Typography($h6FontSize, $h6lineHeight, $h6letterSpacing2, capitalize);
   @include solidHeadingColor($textWhite)
}

.heading7 {
   font-family: "Montserrat";
   @include Typography($h7FontSize, $h7lineHeight, $h7letterSpacing2, capitalize);
   @include solidHeadingColor($textWhite)
}

.heading8 {
   font-family: "Montserrat";
   @include Typography($h3FontSize, $h3lineHeight, $h3letterSpacing2, initial);
}

.headingWhite86 {
   font-family: "Montserrat";
   @include Typography($h1FontSize-86, $h1lineHeight-86, $h2letterSpacing2, uppercase);
   @include WhiteHeadingColor()
}

.profileText {
   font-family: $bodyTextMainFontThin;
   @include Typography($h4FontSize, $h4lineHeight, $h4letterSpacing2, capitalize);
   @include solidHeadingColor($textWhite)
}

//Body Text Types
.body-text1 {
   font-family: $bodyTextMainFont;
   @include Typography($b1FontSize, $b1lineHeight, $b1letterSpacing, initial);
   margin: 0;
}

.body-text2 {
   font-family: $bodyTextMainFont;
   @include Typography($b2FontSize, $b2lineHeight, $b2letterSpacing, initial);
   margin: 0;
}

.body-text2-b-italic {
   font-family: $buttonTextFont;
   @include Typography($b2FontSize, $b2lineHeight, $b2letterSpacing, initial);
   margin: 0;
}

.body-text3 {
   font-family: $bodyTextMainFont;
   @include Typography($b3FontSize, $b3lineHeight, $b3letterSpacing, initial);
   margin: 0;
}

.body-text3-b-italic {
   font-family: $buttonTextFont;
   @include Typography($b3FontSize, $b3lineHeight, $b3letterSpacing, initial);
   margin: 0;
}

.body-text4 {
   font-family: $bodyTextMainFont;
   @include Typography($b4FontSize, $b4lineHeight, $b4letterSpacing, initial);
   margin: 0;
}

.body-text5 {
   font-family: $bodyTextMainFont;
   @include Typography($b5FontSize, $b5lineHeight, $b5letterSpacing, initial);
   margin: 0;
}

.body-text5-b-italic {
   font-family: $buttonTextFont;
   @include Typography($b5FontSize, $b5lineHeight, $b5letterSpacing, initial);
   margin: 0;
}

.body-text6 {
   font-family: $bodyTextMainFont;
   @include Typography($b6FontSize, $b6lineHeight, $b6letterSpacing, initial);
   margin: 0;
}

.button-text3 {
   font-family: $ObjectSans-Heavy;
   @include Typography($b3FontSize, 16px, $b3letterSpacing, initial);
   margin: 0;
}

/*Text Color*/
.color-gray1 {
   color: $textGray1;
}

.color-gray2 {
   color: $textGray2;
}

.color-white {
   color: $textWhite;
}

.color-black {
   color: $textBlack;
}

.color-textturquiose {
   color: $textturquiose !important;
   text-decoration: none !important;
}

.underline-link {
   text-decoration: underline !important;
}

.color-pink {
   color: $textPink2;
}

.green-glow {
   text-shadow: rgb(23, 234, 133) 1px 0 10px;
}

.upper-case {
   text-transform: uppercase !important;
}

.lower-case {
   text-transform: lowercase !important;
}

.capitalize {
   text-transform: capitalize !important;
}

.heading-italic {
   font-family: "Montserrat";
   font-weight: 800;
   font-style: italic;
}

.italic {
   font-style: italic;
}

.regular {
   font-weight: 400;
}

.semi-bold {
   font-weight: 600;
}

.bold {
   font-weight: 700;
}

.extra-bold {
   font-weight: 800;
}

.gradient-color {
   text-transform: uppercase;
   background: linear-gradient(89.7deg, $headingGreen 2.27%, $headingBlue 23.6%, $headingGreen 44.41%, $headingBlue 64.17%, $headingGreen 82.99%, $headingBlue 99.74%);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   background-clip: text;
   text-fill-color: transparent;
   text-shadow: $textShadowDepth $textShadowColorBlue;
}

@media (max-width: $breakpoint-tablet) {
   .heading1 {
      @include Typography($h11FontSize, $h11lineHeight, $h11letterSpacing2, uppercase);
   }
   .heading2 {
      @include Typography($h3FontSize, $h3lineHeight, $h3letterSpacing2, uppercase);
   }
   .heading3 {
      @include Typography($h5FontSize, $h5lineHeight, $h5letterSpacing2, uppercase);
   }
   .heading4 {
      @include Typography($b2FontSize, $b2lineHeight, $b2letterSpacing, capitalize);
   }
   .headingWhite86 {
      @include Typography($h1FontSize, $h1lineHeight, $h1letterSpacing2, uppercase);
   }
   .heading8 {
      @include Typography($h5FontSize, $h5lineHeight, $h5letterSpacing2, initial);
   }

   .body-text1 {
      @include Typography($b2FontSize, $b2lineHeight, $b5letterSpacing, initial);
   }
   .body-text2 {
      @include Typography($b5FontSize, $b5lineHeight, $b5letterSpacing, initial);
   }
   .profileText {
      @include Typography($b2FontSize, $b2lineHeight, $b2letterSpacing, capitalize);
      @include solidHeadingColor($textWhite)
   }
}

@media (max-width: $Midia_mob) {
   .body-text3-b-italic {
      font-size: 12px;
   }
   .heading4-bold {
      @include Typography(16px, 18px, $h4letterSpacing2, capitalize);
   }
   .body-text2-b-italic {
      @include Typography(14px, 16px, $b2letterSpacing, initial);
      margin: 0;
   }
   .body-text4 {
      @include Typography(12px, 14px, $b4letterSpacing, initial);
      margin: 0;
   }
   .button-text3 {
      @include Typography(14px, 16px, $b3letterSpacing, initial);
      margin: 0;
   }
   // .heading3SemiBold {
   //     @include Typography($h4FontSize, $h4lineHeight, $h4letterSpacing2, uppercase);
   //     @include solidHeadingColor($textWhite)
   // }
}

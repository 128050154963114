.socialIcon {
   height: 50px;
   width: 50px;
   border-radius: 50%;
   float: left;
   margin-left: 5px;
   margin-right: 5px;
   background-image: -webkit-linear-gradient(0deg, rgba(25, 234, 23, 1.00) 0%, rgba(20, 208, 230, 1.00) 100%);
   background-image: -moz-linear-gradient(0deg, rgba(25, 234, 23, 1.00) 0%, rgba(20, 208, 230, 1.00) 100%);
   background-image: -o-linear-gradient(0deg, rgba(25, 234, 23, 1.00) 0%, rgba(20, 208, 230, 1.00) 100%);
   background-image: linear-gradient(90deg, rgba(25, 234, 23, 1.00) 0%, rgba(20, 208, 230, 1.00) 100%);
   color: hsla(0, 0%, 0%, 1.00);

   i {
      font-size: 20px;
      line-height: 50px;
   }
}

.footLogo {
   height: 25px;
}

a {
   color: #000000;
}

a:hover {
   color: #ffffff
}

.copyright {
   font-size: 12px;

   a {
      color: #697778;
      text-decoration: none;
   }

   a:hover {
      color: #54e8f8;
      text-decoration: none;
   }
}

.socialUi {
   padding-left: 0;
   list-style-type: none;
   width: fit-content;
   float: right;

   li {
      float: left;
      margin-left: 20px;
   }

   li:first-child {
      margin-left: 0;
   }
}

.social {
   display: flex;
   float: right;
   // width: 52px;
   // height: 52px;
   background: #0E2029;
   border-radius: 90px;
   justify-content: center;

   img {
      width: 24px;
      padding-top: 15px;
   }
}

.footerLinks {
   text-decoration: none;
   // font-size: 16px;
   color: #fff;
   font-style: normal;
   font-weight: 400;
   line-height: 20px;
   letter-spacing: -0.01em;
   font-family: 'ObjectSans-Regular';
}

.grayText {
   color: #95A6B7;
   font-size: 16px;
   text-align: left;

   a {
      color: #95A6B7 !important;
      cursor: pointer;
   }
}

@media only screen and (min-width: 1024px) {
   .footerWrapper {
      padding-top: 100px;
   }
}

@media only screen and (min-width: 992px) {
   .copyRightMsg2 {
      display: none;
   }
   .footerLinks {
      a {
         font-size: 16px;
         padding-left: 10px;
         padding-right: 10px;
      }
   }
   .socialLinksWrapper {
      ul {
         li {
            .social {
               width: 40px;
               height: 40px;
               border-radius: 50%;

               img {
                  padding-top: 9px;
               }
            }
         }
      }

   }
   .mobileLang {
      margin: 0 auto;
   }

}

@media only screen and (max-width: 992px) {
   .menuFooter {
      padding-top: 50px;
   }
   .Footer_social {
      width: 240px;
      position: fixed;
      bottom: 15px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
   }

   .siteFooter {
      display: unset;
      width: 100%;
      position: relative;

      .copyRightMsg {
         bottom: 20px;
         width: 100%;
         text-align: center;
      }

      .footerLinks {
         display: block;
         width: 100%;

         a {
            width: 100%;
            text-align: center;
            margin-bottom: 13px;
         }
      }

   }
   .copyRightMsg, .copyRightMsg a {
      font-family: 'ObjectSans-Regular';
      color: #95A6B7;
      font-size: 14px;
      text-align: center;
      width: fit-content;
   }
   .copyRightMsg {
      display: none;
   }
   .copyRightMsg2 {
      text-align: center;
      padding-top: 20px;
      padding-bottom: 20px;
   }

   .socialLinksWrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      //all: unset;
      height: 90px;

      > ul {
         margin-top: -4px;
         bottom: -73px !important;

         li {
            .social {
               width: 40px;
               height: 40px;
               border-radius: 50%;
               margin-left: 3px;
               margin-right: 3px;

               img {
                  padding-top: 9px;
               }
            }
         }
      }
   }
   .uppeFooter {
      margin-top: 60px;
   }
}


@media screen and (max-width: 992px) and (min-width: 768px) {
   .footerWrapper {
      .metalogoWrapper {
         top: -85px;
      }

      .socialLinksWrapper {
         margin-top: -30px;
         display: grid !important;
         grid-template-columns: 1fr;

         > div, .socialUi {
            display: block;
            width: fit-content !important;
            margin: 0 auto !important;
            // margin-top: -11px !important;

         }
      }
   }
}

@media (max-width: 768px) {
   .socialIcon {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      float: left;

      i {
         font-size: 15px;
         line-height: 30px;
      }
   }


   .social {
      width: 44px;
      height: 44px;

      img {
         width: 20px;
         padding-top: 13px;
      }
   }

   .socialUi {
      padding-left: 0;
      list-style-type: none;
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
      float: none;
      display: flex;
   }
   .footerLinks {
      text-decoration: none;
      font-size: 14px;
      color: #fff;
      font-style: normal;
      font-weight: 100;
      line-height: 20px;
      margin-top: 15px;

      > a {
         line-height: 10px;
         height: 20px !important;
      }
   }
   .grayText {
      color: #95A6B7;
      font-size: 10px;
      text-align: center;
   }
   .socialLinksWrapper {
      display: block !important;
      margin-top: -40px;

      > .socialUi {
         margin-bottom: 2px;
         margin-top: 10px;
      }

      .LangWrapper {
         min-width: 100%;
         margin: 0 auto;
         height: 47px;

         > div {
            width: fit-content;
            margin: 0 auto;
         }
      }
   }
   .copyRightMsg2 {
      margin-top: -30px;
   }
}

@media (max-width: 576px) {
   .socialUi {
      scale: .8;
   }
}

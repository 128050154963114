@import "../../scss/globals/global";

.back404 {
   background: rgba(2, 3, 12, 1);
   height: 100vh;
}

.logoImgWrapper {
   position: relative;
   z-index: 1;

   .logoImg {
      width: 225px;
      float: left;
   }
}

.mainImgWrapper {
   width: 100%;
   text-align: center;
   position: relative;
   top: -86px;
   z-index: 0;

   .MainImg {
      width: 66%;
      position: relative;
      z-index: 1;
   }

}

.mainText {
   position: relative;
   margin-top: 85px;
}

.mainBtnWrapper {
   position: relative;
   margin-top: 25px;
   text-align: center;
}

.error404Container {
   height: 100vh; /* Set the height to the viewport height */
   overflow-y: auto; /* Enable vertical scrolling */
   overflow-x: hidden; /* Hide horizontal scrolling */
}

.errorImgWrapper {
   width: 100%;
   text-align: center;
   position: absolute;
   z-index: 1;

   .errorNo404 {
      font-family: Montserrat;
      font-size: 292px;
      color: rgba(255, 255, 255, 0);
      position: absolute;
      left: 0;
      width: 100%;
      text-align: center;
      top: 12vw;
      font-weight: 800;
      line-height: 292px;
      letter-spacing: 0.1em;

   }

   .errorNo4041 {
      -webkit-text-stroke-width: 3.7px;
      -webkit-text-stroke-color: rgba(255, 255, 255, 1);
      z-index: 5;
   }

   .errorNo4042 {
      -webkit-text-stroke-width: 7.4px;
      -webkit-text-stroke-color: rgba(251, 55, 255, 1);
      z-index: 4;
   }

   .errorNo4043 {
      -webkit-text-stroke-width: 6px;
      -webkit-text-stroke-color: rgba(251, 55, 255, 0.2);
      filter: blur(5px);
      z-index: 3;
   }

   .errorNo4044 {
      -webkit-text-stroke-width: 24px;
      -webkit-text-stroke-color: rgb(255, 38, 194);
      animation: Flash 5s infinite;
      filter: blur(10px);
      z-index: 2;
   }

   .errorNo4045 {
      -webkit-text-stroke-width: 6px;
      color: rgba(243, 46, 124, 1);
      filter: blur(40px);
      -webkit-text-stroke-color: rgba(243, 46, 124, 1);
      animation: Flash 5s infinite;
      z-index: 1;
   }

   .errorTxtEllipse {
      width: 100%;
      height: 22vw;
      left: 0px;
      bottom: 0;
      background: radial-gradient(44.65% 47.65% at 50% 50%, rgba(255, 55, 128, 0.35) 0%, rgba(20, 208, 230, 0) 100%);
      position: absolute;
      mix-blend-mode: screen;
      z-index: 0;
      animation: Flash 5s infinite;
   }
}

.ellipse {
   position: absolute;
   opacity: 0.29;
   pointer-events: none;
   z-index: -1;

}

.ellipse.e1 {
   width: 35vw;
   height: 64vh;
   left: 0px;
   bottom: 165px;
   background: radial-gradient(47.65% 47.65% at 0 50%, #14D0E6 0%, rgba(20, 208, 230, 0) 100%);
   position: absolute;
   mix-blend-mode: screen;
   z-index: 2;
   animation: grad_blue_Sphere_Anim 5s infinite;
}

.ellipse.e2 {
   position: absolute;
   width: 25vw;
   height: 35vw;
   right: 0;
   bottom: 0px;
   mix-blend-mode: screen;
   opacity: 0.5;
   background: radial-gradient(60.65% 39.65% at 90% 55%, #e61479 0%, rgba(230, 20, 20, 0) 100%);
   z-index: 0;
   pointer-events: none;
   animation: grad_red_Sphere_Anim 5s infinite;
}

.ellipse.e3 {
   width: 20vw;
   height: 20vw;
   right: 0;
   bottom: 122px;
   mix-blend-mode: screen;
   opacity: 1;
   background-image: url("../../assets/images/Ellipse3.webp");
   background-position: 80px 0;
   background-repeat: no-repeat;
   background-size: 100%;
   z-index: 2;
   pointer-events: none;
   animation: multicolor1_Sphere_Anim 5s infinite;

}

.ellipse.e4 {
   position: absolute;
   width: 20vw;
   height: 20vw;
   left: 0;
   bottom: 43px;
   mix-blend-mode: screen;
   opacity: 1;
   background-image: url("../../assets/images/Ellipse4.webp");
   background-position: 0 0;
   background-repeat: no-repeat;
   background-size: 50%;
   z-index: 2;
   pointer-events: none;
   animation: multicolor2_Sphere_Anim 5s infinite;
}

@keyframes Flash {
   0% {
      opacity: 1;
   }
   50% {
      opacity: 0.5;
   }
   100% {
      opacity: 1;
   }
}

@keyframes grad_red_Sphere_Anim {
   0% {
      bottom: 0px;
   }
   50% {
      bottom: 300px;
   }
   100% {
      bottom: 0px;
   }
}

@keyframes multicolor1_Sphere_Anim {
   0% {
      bottom: 122px;
      right: -150px;
      background-position: 0 0;
   }
   50% {
      bottom: 300px;
      right: 0px;
      background-position: 0px 0;
   }
   100% {
      bottom: 122px;
      right: -150px;
      background-position: 0 0;
   }
}

@keyframes multicolor2_Sphere_Anim {
   0% {
      bottom: 43px;
      left: -50px;
      background-position: 0 0;
   }
   50% {
      bottom: 300px;
      left: 0px;
      background-position: 0px 0;
   }
   100% {
      bottom: 43px;
      left: -50px;
      background-position: 0 0;
   }
}

@keyframes grad_blue_Sphere_Anim {
   0% {
      bottom: 300px;
   }
   50% {
      bottom: 0px;
   }
   100% {
      bottom: 300px;
   }
}

//width 1400px
@media (min-width: $Midia_xl) {
   .errorImgWrapper {
      .errorNoImg {
         margin-top: 29%;
      }
   }
   .mainText {
      margin-top: -165px;
   }
   .mainBtnWrapper {
      margin-top: -110px;
   }
}

//width 1400px
@media (max-width: $Midia_xl) {
   .mainText {
      margin-top: -80px;
   }
   .mainBtnWrapper {
      margin-top: 0;
   }
}

//width 1200px
@media (max-width: $Midia_lg) {
   .ellipse.e1 {
      width: 52vw;
   }
   .ellipse.e2 {
      width: 32vw;
      height: 45vw;
   }
   .ellipse.e3 {
      width: 30vw;
      height: 30vw;
   }
   .ellipse.e4 {
      width: 30vw;
      height: 30vw;
   }
   .mainText {
      margin-top: -80px;
   }
}

//width 992px
@media (max-width: $Midia_md) {
   .mainText {
      margin-top: -70px;
   }
   .mainBtnWrapper {
      margin-top: 0;
   }
   .errorImgWrapper {
      .errorNoImg {
         margin-top: 22%;
         width: 100%;
      }
   }
}

//width 768px
@media (max-width: $Midia_sm) {
   .mainImgWrapper {
      .MainImg {
         width: 100%;
      }
   }
   .errorImgWrapper {
      .errorNoImg {
         margin-top: 60%;
         width: 100%;
      }
   }
   .mainText {
      margin-top: -30px;
   }
   .logoImgWrapper {
      .logoImg {
         max-width: 120px;
      }
   }
   .lightGreenButton {
      width: auto;
   }
   .errorImgWrapper {
      .errorNo404 {
         font-size: 208.47px;
         top: 26vw;
      }
   }
}

//width 576px
@media (max-width: $Midia_mob) {
   .errorImgWrapper {
      .errorNo404 {
         font-size: 108.47px;
         top: 19vw;
      }
   }
}

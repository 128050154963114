/*new design*/
@import '../../scss/globals/global';

.bodyPrivacy {
   background-image: url(https://storage.googleapis.com/meta11/serviceplatform/GradientBlue1.png),
   url(https://storage.googleapis.com/meta11/serviceplatform/GradentRed1.png),
   url(https://storage.googleapis.com/meta11/serviceplatform/GradentRed2.png);

   background: {
      repeat: no-repeat, no-repeat, no-repeat;
      position: left top, right top, left 850px;
      size: auto auto;
   }

   .ResponceCont {
      max-width: 1240px;
      margin: {
         left: auto;
         right: auto;
      }
   }


   .pageTitle {
      @include pageTitle;
   }

   .sectionTitle {
      @include sectionTitle2;
   }

   .sectionContent {
      @include sectionContent;

      ul {
         list-style-type: none;
         margin-left: 10px;
         margin-bottom: 0;

         li {
            list-style-image: url(https://storage.googleapis.com/meta11/serviceplatform/SmallBullet.png);
         }
      }
   }

   .font22 {
      font-size: 22px;
      color: #95A6B7;
      font-family: 'ObjectSans-Regular';
      line-height: 32px;
      margin-top: 15px;

      ul {
         list-style-type: none;
         margin-left: 10px;
         margin-bottom: 0;

         li {
            list-style-image: url(https://storage.googleapis.com/meta11/serviceplatform/SmallBullet.png);
         }
      }
   }


   .orderedList {
      margin-left: 20px;
      margin-bottom: 0px;
   }

   .privacyTable1 {
      background-color: #111E33;
      width: 100%;
      border: 1px solid #1F2A36;
      color: #95A6B7;

      th {
         color: #cfd7df;
         padding: 1%;
         border: 1px solid #1F2A36;
         font-size: 20px;
         line-height: 22px;
      }

      td {
         padding: 1%;
         border: 1px solid #1F2A36;
         font-size: 15px;
         line-height: 18px;
         vertical-align: top;
      }
   }

   .sectionTitle {
      font-size: 27px;
      line-height: 35px;
   }

   .sectionContent {
      a {
         padding-left: 4px;
      }

      a:hover {
         text-decoration: none;
         cursor: pointer;
      }
   }

   @media (max-width: 768px) {
      .sectionTitle {
         font-size: 25px;
      }
      .privacyTable1 {
         background-color: #111E33;
         width: 100%;
         border: 1px solid #1F2A36;
         color: #95A6B7;

         th {
            color: #cfd7df;
            padding: 2%;
            border: 1px solid #1F2A36;
            font-size: 17px;
            line-height: 19px;
         }

         td {
            padding: 2%;
            border: 1px solid #1F2A36;
            font-size: 12px;
            line-height: 16px;
            vertical-align: top;
         }
      }

   }


   .bold {
      font-weight: 900;
   }

   .eventBannerOne {
      width: 100%;
      height: 390px;
      background: {
         image: url("https://storage.googleapis.com/meta11/serviceplatform/event_banner_1.png");
         repeat: no-repeat;
         position: right 0%;
         size: auto 100%;
      }
   }

   .eventBannerTwo {
      width: 100%;
      height: 390px;
      background: {
         image: url("https://storage.googleapis.com/meta11/serviceplatform/event_banner_2.png");
         repeat: no-repeat;
         position: right 0%;
         size: auto 100%;
      }
   }

   .cardCustom {
      background-color: hsla(180, 50%, 2%, 0.50);
      border: {
         radius: 15px;
         width: 0px;
      }
      padding-right: 0px;
      margin-bottom: 20px;
   }

   .greenGradientText {
      background: linear-gradient(to right, #19EA17 0%, #14D0E6 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
   }

   .pageContent {
      width: 100%;
      padding: {
         top: 20px;
         bottom: 10px;
         left: 5%;
         right: 5%;
      }
      margin: 0 auto;
      @include linear-gradient(270deg);
   }

   @media (max-width: 768px) {

      .eventBannerOne {
         width: 100%;
         height: 390px;
         background: {
            image: url("https://storage.googleapis.com/meta11/serviceplatform/event_banner_1.png");
            repeat: no-repeat;
            position: right 0%;
            size: 100% auto;
         }
      }

      .largeCard {
         background: {
            image: url('https://storage.googleapis.com/meta11/serviceplatform/corner-small.png'), url('https://storage.googleapis.com/meta11/serviceplatform/Frame%20804.webp');
            repeat: no-repeat;
            position: right top, 20px 380px;
         }
         height: auto;
      }

      .smallCard {
         background: {
            image: url('https://storage.googleapis.com/meta11/serviceplatform/corner-small.png'), url('https://storage.googleapis.com/meta11/serviceplatform/Frame%20804.webp');
            repeat: no-repeat;
            position: right top, 20px 340px;
         }
         height: auto;
      }

      .smallLongCard {
         background: {
            image: url('https://storage.googleapis.com/meta11/serviceplatform/corner-small.png'), url('https://storage.googleapis.com/meta11/serviceplatform/Frame%20804.webp');
            repeat: no-repeat;
            position: right top, 20px 460px;
         }
         height: auto;
      }

      .card1Title {
         padding: {
            left: 10px;
            right: 10px;
         }
         font-size: 25px;
         line-height: 30px;
         font-family: 'Rubik-medium', Helvetica, Arial, sans-serif;
      }

      .card1Content {
         padding: {
            left: 10px;
            right: 10px;
         }
         font-size: 15px;
         line-height: 20px;
      }

      .card2Title {
         font-size: 18px;
      }

      .card2Content {
         padding: {
            right: 10px;
            left: 10px;
         }
         font-size: 15px;
         line-height: 20px;
      }

      .bigBtn {
         background-color: #000000;
         padding: 10px 20px;
         color: #fff;
         font-size: 15px;
         border-radius: 20px;
         border: 2px solid hsla(0, 0%, 100%, 1.00);
         line-height: 10px;
         width: 175px;
         height: 42px;
         font-family: 'Rubik-Medium', Helvetica, Arial, sans-serif;
      }

      .orderedList {
         margin-left: 8px;
      }

      .privacyTable {

         th {
            padding: 8px;
            font-size: 15px;
         }

         td {
            text-align: justify;
            text-justify: inter-word;
            padding: 7px;
            font-size: 14px;
         }
      }
   }

   @media (min-width: 768px) {

      .largeCard {
         background: {
            image: url('https://storage.googleapis.com/meta11/serviceplatform/corner-small.png'), url('https://storage.googleapis.com/meta11/serviceplatform/Frame%20804.webp');
            repeat: no-repeat;
            position: right top, 20px 290px;
         }
         height: auto;
      }

      .smallCard {
         background: {
            image: url('https://storage.googleapis.com/meta11/serviceplatform/corner-small.png'), url('https://storage.googleapis.com/meta11/serviceplatform/Frame%20804.webp');
            repeat: no-repeat;
            position: right top, 20px 170px;
         }
         height: 200px;
      }

      .smallLongCard {
         background: {
            image: url('https://storage.googleapis.com/meta11/serviceplatform/corner-small.png'), url('https://storage.googleapis.com/meta11/serviceplatform/Frame%20804.webp');
            repeat: no-repeat;
            position: right top, 20px 430px;
         }
         height: 470px;
      }

      .card1Title {
         padding-left: 20px;
         padding-right: 20px;
         font-size: 35px;
         line-height: 50px;
         font-family: 'Rubik-medium', Helvetica, Arial, sans-serif;
      }

      .card1Content {
         padding: {
            left: 20px;
            right: 20px;
         }
         font-size: 20px;
         line-height: 26px;
      }

      .card2Title {
         font-size: 23px;
      }

      .card2Content {
         padding-right: 20px;
         padding-left: 20px;
         font-size: 19px;
         line-height: 22px;
      }

      .bigBtn {
         background-color: #000000;
         padding: 10px 20px;
         color: #fff;
         font-size: 15px;
         border-radius: 20px;
         border: 2px solid hsla(0, 0%, 100%, 1.00);
         line-height: 10px;
         width: 175px;
         height: 42px;
         font-family: 'Rubik-Medium', Helvetica, Arial, sans-serif;
      }

   }

   @media (min-width: 1200px) {

      .largeCard {
         background-image: url('https://storage.googleapis.com/meta11/serviceplatform/corner-small.png'), url('https://storage.googleapis.com/meta11/serviceplatform/Frame%20804.webp');
         background-repeat: no-repeat;
         background-position: right top, 20px 170px;
         height: 200px;
      }

      .smallCard {
         background-image: url('https://storage.googleapis.com/meta11/serviceplatform/corner-small.png'), url('https://storage.googleapis.com/meta11/serviceplatform/Frame%20804.webp');
         background-repeat: no-repeat;
         background-position: right top, 20px 170px;
         height: 200px;
      }

      .smallLongCard {
         background-image: url('https://storage.googleapis.com/meta11/serviceplatform/corner-small.png'), url('https://storage.googleapis.com/meta11/serviceplatform/Frame%20804.webp');
         background-repeat: no-repeat;
         background-position: right top, 20px 440px;
         height: 470px;
      }

      .card1Title {
         font-size: 40px;
         line-height: 55px;
         font-family: 'Rubik-medium', Helvetica, Arial, sans-serif;
      }

      .card1Content {
         padding-left: 20px;
         padding-right: 20px;
         font-size: 20px;
         line-height: 31px;
      }

      .card2Title {
         font-size: 25px;
      }

      .card2Content {
         padding-right: 20px;
         padding-left: 20px;
         font-size: 20px;
         line-height: 25px;
      }

      .bigBtn {
         background-color: #000000;
         padding: 10px 20px;
         color: #fff;
         font-size: 15px;
         border-radius: 20px;
         border: 2px solid hsla(0, 0%, 100%, 1.00);
         line-height: 10px;
         width: 175px;
         height: 42px;
         font-family: 'Rubik-Medium', Helvetica, Arial, sans-serif;
      }
   }

   @media (min-width: 1440px) {

      .largeCard {
         background: {
            image: url('https://storage.googleapis.com/meta11/serviceplatform/corner-small.png'), url('https://storage.googleapis.com/meta11/serviceplatform/Frame%20804.webp');
            repeat: no-repeat;
            position: right top, 20px 170px;
         }
         height: 200px;
      }

      .smallCard {
         background: {
            image: url('https://storage.googleapis.com/meta11/serviceplatform/corner-small.png'), url('https://storage.googleapis.com/meta11/serviceplatform/Frame%20804.webp');
            repeat: no-repeat;
            position: right top, 20px 170px;
         }
         height: 200px;
      }

      .smallLongCard {
         background: {
            image: url('https://storage.googleapis.com/meta11/serviceplatform/corner-small.png'), url('https://storage.googleapis.com/meta11/serviceplatform/Frame%20804.webp');
            repeat: no-repeat;
            position: right top, 20px 400px;
         }
         height: 430px;
      }

      // .pageTitle {
      //     font-size: 50px;
      //     font-family: 'Rubik-medium', Helvetica, Arial, sans-serif;
      //     line-height: 70px;

      // }

      .card1Title {
         font-size: 45px;
         line-height: 50px;
         font-family: 'Rubik-medium', Helvetica, Arial, sans-serif;
      }

      .card1Content {
         font-size: 23px;
         line-height: 36px;
      }

      .card2Title {
         font-size: 25px;
      }

      .card2Content {
         font-size: 20px;
         line-height: 25px;
      }

      .bigBtn {
         background-color: #000000;
         padding: 10px 20px;
         color: #fff;
         font-size: 15px;
         border-radius: 20px;
         border: 2px solid hsla(0, 0%, 100%, 1.00);
         line-height: 10px;
         width: 175px;
         height: 42px;
         font-family: 'Rubik-Medium', Helvetica, Arial, sans-serif;
      }
   }

   .slashBig {
      background-image: url("https://storage.googleapis.com/meta11/serviceplatform/slash-big.png");
      background-repeat: no-repeat;
      background-position: left bottom;
   }

   .slashSmall {
      background-image: url("https://storage.googleapis.com/meta11/serviceplatform/slash-small.png");
      background-repeat: no-repeat;
      background-position: left top;
   }

   .gradientBg {
      @include gradient(270deg);
   }

}



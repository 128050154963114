.ResponceCont {
   max-width: 1240px;
   margin: {
      left: auto;
      right: auto;
   }
}

.loaderContainer {
   margin: 0 auto;
   transform: translateY(400%);
   text-align: center;
}

.mainHeaderH1 {
   font: {
      family: 'SolinaExtraBoldItalic';
      size: 115px;
   }
   line-height: 125px;
   letter-spacing: 0.02em;
   text: {
      transform: uppercase;
      fill-color: transparent;
      shadow: 0px 0px 8px rgba(20, 208, 229, 0.56);

   }
   background: linear-gradient(89.7deg, #17EA85 2.27%, #14D0E6 23.6%, #17EA85 44.41%, #14D0E6 64.17%, #17EA85 82.99%, #14D0E6 99.74%);
   -webkit: {
      background-clip: text;
      text-fill-color: transparent;
   }
   background-clip: text;
   margin-top: 50px;
}

.subMainHeaderH1 {
   font: {
      family: 'SolinaExBI';
      weight: 800;
      size: 52px;
   }
   line-height: 58px;
   letter-spacing: 0.02em;
}

.contentWrapper {
   // min-height:calc(100vh - 580px);
   .loaderContainer {
      margin-top: -100px;
   }
}

.matchInfoHeaderContainer {
   .shareBtnWrapper {
      width: 45px;
      color: #fff;

      img {
         width: 24px;
         height: 24px;
         margin-left: 15px;
      }

      img:hover {
         opacity: .5;
      }
   }
}

.matchCardWrapper {
   .matchCard {
      .teamScoreWrapper {
         > label {
            display: block;
         }
      }

      .matchInfoHeaderContainer {
         display: block !important;
         padding-bottom: 8px;
         // > .row1, .row2{
         //   display: block;
         //   width: 100%;
         // }
         > .row1 {
            display: grid;
            grid-template-columns: 1fr 1fr;

            span {
               width: fit-content;
            }

            .shareBtnWrapper {
               position: absolute;
               right: 0;
               top: 17px;
            }
         }

         > .row2 {
            > span {
               display: grid;
               grid-template-columns: 100px 1fr;

               label {
                  display: block !important;
                  padding: 0;
                  margin-top: 8px;
               }

               label:nth-child(1) {
                  text-align: left;
                  margin-left: -10px;
               }

               label:nth-child(2) {
                  text-align: right;
               }
            }
         }

         .matchDateWrapper {
            label {
               display: block;
            }
         }
      }
   }
}

.searchField {
   flex-grow: 1;

   > div {
      > div {
         > div {
            position: absolute !important;
            top: 60px;

            .arrow-rightOutter {
               top: -17px;
               transform: rotate(-90deg);
            }
         }
      }
   }
}

.MatchTypeWrapperContainer {
   background-color: #ffffff1b !important;
}

.matchTypeWrapper {
   //background-color: #ffffff1b !important;
   background-color: transparent !important;
}


@keyframes BgGradient {
   0% {
      scale: 1;
      opacity: 1;
   }
   50% {
      scale: 1.1;
      opacity: .8;
   }
   90% {
      scale: 1.2;
      opacity: .3;
   }
   100% {
      scale: 1;
      opacity: .0;
   }
}

.PlayNowLiveTagContainer {
   position: relative;

   .PlayNowLiveTag {
      width: -moz-fit-content;
      width: fit-content;
      // background: linear-gradient(96.32deg, #B10B8F 1.99%, #E01655 99.35%), linear-gradient(92.2deg, #E11653 2.13%, #AD0A93 106.58%);
      background: linear-gradient(-45deg, #ee7752, #e73c7e, #931cb1, #e01655);
      transform: skew(-10deg, 0deg);
      bottom: 2px;
      font-family: "ObjectSans-Regular";
      letter-spacing: 2px;
      font-size: 12px;
      padding: 2px 10px;
      height: 24px;
      color: rgb(255, 255, 255);
      -webkit-text-fill-color: #FFFFFF !important;
      text-transform: uppercase;
      display: flex;
      font-size: 10px;
      background-size: 300% 200%;
      // animation: BgGradient 1s ease infinite;
      .LiveTag {
         padding-top: 2px;
      }

      .BlinkingDot {
         display: block;
         margin-top: 8px;
         margin-right: 4px;
      }
   }

   .PlayNowLiveTagWrapper {
      background: linear-gradient(-45deg, #ee7752, #e73c7e, #931cb1, #e01655);
      transform: skew(-10deg, 0deg);
      width: 102px;
      height: 27px;
      border-radius: 2px;
      position: absolute;
      top: -2px;
      animation: BgGradient 1s ease infinite;
   }
}

@media only screen and (min-width: 1200px) {
   .searchField {
      > div {
         > div {
            > div {
               position: absolute !important;
               top: -11px;
               left: -238px;
            }
         }
      }
   }

}

@media only screen and (max-width: 1200px) {
   .loaderContainer {
      display: flex;
      justify-content: center;

      > p {
         text-align: center !important;
      }
   }
}

@media only screen and (max-width: 1024px) {
   .matchTypeWrapper {
      .searchField {
         width: 100%;
         margin: unset !important;
         margin: 0;
         margin-bottom: 10px !important;
         margin-top: 20px !important;

         input {
            // margin-left: 30px;
         }
      }

      label {
         width: calc(100% / 4);
      }
   }
   .searchField {
      > div {
         > div {
            > div {
               position: absolute !important;
               top: 60px;
               left: 200px;
            }
         }
      }
   }


}

@media only screen and (min-width: 991px) {
   .contentWrapper {
      min-height: calc(100vh - 580px);
   }
}

@media only screen and (max-width: 768px) {
   .matches-radio-wrapper {
      justify-content: space-between;
   }

   .fc-wrapper {
      margin-bottom: 15px;
      text-align: center;
      display: flex;

   }

   .radioButtonlbl {
      margin-bottom: 15px;
      width: 47%;
   }

   .mainHeaderH1 {
      font-size: 26px;
      line-height: 30px;
   }

   .loaderContainer {
      display: contents;
   }
   .matchTypeWrapper {
      label {
         padding: unset !important;
         margin: unset !important;
         width: calc(50% - 10px) !important;
         margin-bottom: 12px !important;
         margin-right: 8px !important;
      }

      label:nth-child(2) {
         margin-right: 0 !important;
      }

      label:nth-child(4) {
         margin-right: 0 !important;
      }
   }
   .searchField {
      > div {
         > div {
            > div {
               position: absolute !important;
               top: 60px;

               .arrow-rightOutter {
                  transform: none;
               }
            }
         }
      }
   }
   .loaderContainer {
      > p {
         text-align: center !important;
      }
   }
}

@media only screen and (max-width: 576px) {
   .matchTypeWrapper {
      .searchField {
         margin-bottom: 15px !important;
      }

      label {
         padding: unset !important;
         margin: unset !important;
         width: calc(50% - 10px) !important;
         margin-bottom: 12px !important;
         margin-right: 8px !important;
      }

      label:nth-child(2) {
         margin-right: 0 !important;
         margin-left: 8px !important;
      }

      label:nth-child(4) {
         margin-right: 0 !important;
         margin-left: 8px !important;
      }
   }
}

/* iPhone Portrait */
@media screen and (max-device-width: 480px) and (orientation: portrait) {

}

/* iPhone Landscape */
@media screen and (max-device-width: 480px) and (orientation: landscape) {
}


@import '../utilities/variables';
@import '../utilities/mixins';

.ProfilePane {
   @include Card($card01, $card02);
}

.OverviewBox {
   @include Card($card01, $card02);
   padding: 12px 12px 12px 5px;
   position: relative;
}

//q-bot menu
.chatbothead {
   position: relative;
}

ul.q-bot-dropdown {
   list-style: none;
   padding: 0;
   position: absolute;
   right: 0;
   top: 0;
   background: linear-gradient(0deg, #1f2a3630, #1f2a3657), linear-gradient(180deg, rgba(26, 38, 57, 0.2) 0%, rgba(11, 28, 53, 0.2) 100%);
   width: 100%;
   z-index: 10000;
   backdrop-filter: blur(5px);
}


ul.q-bot-dropdown > li {
   padding: 5px;
   font-size: 16px;
   color: #fff;
   border-bottom: 1px solid rgba(31, 42, 54, 1);
}

ul.q-bot-dropdown > li > a {
   color: #fff;
   display: flex;
   position: relative;
   text-decoration: none;
   padding: 10px;
}

ul.q-bot-dropdown > li > a > span {
   font-size: 24px;
   display: flex;
   align-items: center;
   margin-right: 10px;
   top: 4px;
}

ul.q-bot-dropdown > li > a > label {
   display: flex;
   align-items: center;
   margin: 0;
}

ul.q-bot-dropdown > li > a > span.arrow-right1 {
   display: flex;
   align-items: center;
   position: absolute;
   right: 0;
   top: 10px;
}

ul.langWrapper {
   list-style: none;
}

ul.langWrapper > li > label > span, .langWrapper > li > label input:checked + span {
   backdrop-filter: none;
   padding: 7px 12px;
   background: #0000;
   color: $textGray2;
}

ul.langWrapper > li .radioButtonlbl span:hover, ul.langWrapper > li .radioButtonlbl span.active-lang {
   color: $textWhite;
   background: #0000;
}

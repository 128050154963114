@import "../../scss/globals/global";

.section1EndGradient {
   background: linear-gradient(
         178.27deg,
         rgba(3, 21, 30, 0) 5.01%,
         #010e14 78.82%
   );
   height: 40px;
   top: -123px;
   z-index: -1;
   filter: blur(20px);
}

.loaderContainer {
   position: fixed;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
}

.marginTop42 {
   margin-top: 42px;
}

.relative-pos {
   position: relative;
}

.profileTextTle {
   font-size: 24px;
   line-height: 32px;
   color: rgb(255, 255, 255);
   letter-spacing: 1px;
}

.profileText {
   font-size: 22px;
   line-height: 28px;
   color: rgba(203, 207, 210, 1);
   letter-spacing: -0.1px;
}

.profileTextSm {
   font-size: 18px;
   line-height: 24px;
   color: rgba(203, 207, 210, 1);
   letter-spacing: -0.1px;
}

.bigLogo {
   width: 352px;
   margin-bottom: 35px;
}

.mainHeaderH1 {
   font-family: "Montserrat-ExtraBoldItalic";
   font-size: 60px;
   line-height: 70px;
   letter-spacing: 2%;
   text-transform: uppercase;
   background: linear-gradient(
         89.7deg,
         #17ea85 2.27%,
         #14d0e6 23.6%,
         #17ea85 44.41%,
         #14d0e6 64.17%,
         #17ea85 82.99%,
         #14d0e6 99.74%
   );
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   background-clip: text;
   text-fill-color: transparent;
   text-shadow: 0px 0px 8px rgba(20, 208, 229, 0.56);
   margin-top: 50px;
   text-transform: uppercase;

   .white86 {
      background: #fff;
      color: #fff;
      width: 100%;
      float: left;
      display: block;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-shadow: 0px 0px 8px rgb(255 255 255 / 56%);
      font-size: 92px;
      line-height: 92px;
   }

   .text16Heading {
      font-family: "Montserrat-Regular";
      background: #fff;
      color: #fff;
      text-shadow: 0px 0px 8px rgb(255 255 255 / 100%);
      width: 100%;
      float: left;
      font-weight: 500;
      font-style: normal;
      display: flex;
      flex-direction: column;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-shadow: none;
      font-size: 16px;
      line-height: 30px;
      height: 55px;
      margin: 10px 0;
   }

}

.mainHeaderH1.text20 {
   font-size: 20px;
   line-height: 24px;
}

.mainHeaderH1.Sub {
   font-size: 52px;
   line-height: 58px;
   width: 100%;
}

.mainHeaderH1.Sub1 {
   font-size: 52px;
   line-height: 58px;
}

.gray-text {
   color: #95a6b7;
}

.blueFlash2 {
   width: 234px;
   height: 224px;
   margin-bottom: -120px;
   margin-left: 100px;
   background-image: url("https://storage.googleapis.com/meta11/serviceplatform/Blue-Light-Effect-Transparent.webp");
   /*mix-blend-mode: overlay;*/
   pointer-events: none;
}

.blueFlash {
   position: absolute;
   width: 234px;
   height: 224px;
   left: -111px;
   top: -81px;
   background-image: url(https://storage.googleapis.com/meta11/serviceplatform/Blue-Light-Effect-Transparent.webp);
   background-repeat: no-repeat;
   pointer-events: none;
   z-index: -1;
}

.ellipse {
   position: absolute;
   opacity: 0.29;
   pointer-events: none;
   z-index: -1;
}

.ellipse.e26 {
   @include gradientGeneralLeft(
         984px,
         984px,
         0px,
         -145px,
         $Blue01,
         32.65%,
         32.65%,
         $GradPosLeft
   );
   mix-blend-mode: screen;
   opacity: 0.29;
   z-index: 2;
}

.ellipse.e27 {
   @include gradientGeneralLeft(
         579px,
         579px,
         -355px,
         198px,
         $Blue02,
         32.65%,
         32.65%,
         $GradPosLeft
   );
   mix-blend-mode: screen;
   z-index: 2;
}

.ellipse.e28 {
   @include gradientGeneralLeft(
         45vw,
         106vh,
         0px,
         65px,
         $Pink01,
         32.65%,
         32.65%,
         $GradPosLeft
   );
   mix-blend-mode: screen;
   opacity: 0.24;
   z-index: 2;
}

.ellipse.e29 {
   @include gradientGeneralLeft(
         579px,
         579px,
         336px,
         256px,
         $Blue02,
         32.65%,
         32.65%,
         $GradPosCenter
   );
   mix-blend-mode: normal;
   z-index: 2;
}

.ellipse.e37 {
   @include gradientGeneralLeft(
         34vw,
         42vh,
         0,
         -352px,
         $Blue03,
         32.65%,
         32.65%,
         $GradPosLeft
   );
   mix-blend-mode: screen;
   opacity: 0.29;
}

.ellipse.e38 {
   @include gradientGeneralLeft(
         45vw,
         40vh,
         31%,
         17%,
         $Blue03,
         32.65%,
         32.65%,
         $GradPosCenter
   );
   mix-blend-mode: screen;
   opacity: 0.29;
   z-index: -1;
}

.ellipse.e39 {
   @include gradientGeneralRight(
         36vw,
         42vh,
         0,
         25%,
         $Blue05,
         32.65%,
         32.65%,
         $GradPosCenter
   );
   mix-blend-mode: screen;
   opacity: 0.4;
   z-index: 2;
}

.ellipse.e40 {
   width: 315px;
   height: 420px;
   left: 0;
   mix-blend-mode: screen;
   opacity: 0.8;
   background-image: url("https://storage.googleapis.com/meta11/serviceplatform/Ellipse19.webp");
   background-position: 0 0;
   background-repeat: no-repeat;
   z-index: -2;
   margin-bottom: -310px;
   position: relative;
}

.ellipse.e41 {
   @include gradientGeneralLeft(
         45vw,
         40vh,
         0,
         5%,
         $Blue05,
         53.25%,
         53.25%,
         $GradPosLeft
   );
   mix-blend-mode: screen;
   opacity: 1;
   z-index: 2;
}

.yellowBatBlur {
   position: absolute;
   background-image: url(https://storage.googleapis.com/meta11/serviceplatform/yellowbatBlur.webp);
   background-position: center right;
   background-repeat: no-repeat;
   background-size: 80% 100%;
   top: 40px;
   left: 10px;
   width: 100%;
   height: 100%;
   z-index: -1;
   pointer-events: none;
}

.yellowMainBack {
   position: absolute;
   background-image: url("https://storage.googleapis.com/meta11/serviceplatform/bg3About.webp");
   background-position: 0 0;
   background-repeat: no-repeat;
   background-size: 100%;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   z-index: -3;
   opacity: 0.3;
   pointer-events: none;
}

.yellowGradientBot {
   background: linear-gradient(
         180deg,
         rgba(3, 21, 30, 0) 17.39%,
         #010e14 90.22%
   );
   bottom: 0px;
   position: absolute;
   height: 138px;
   width: 100%;
   z-index: -2;
   pointer-events: none;
}

.yellowGradient01 {
   background: radial-gradient(
         32.65% 32.65% at 50% 50%,
         #e61479 0%,
         rgba(230, 20, 20, 0) 100%
   );
   position: absolute;
   width: 700px;
   height: 700px;
   right: 300px;
   top: 300px;
   mix-blend-mode: screen;
   opacity: 0.5;
   z-index: -1;
   pointer-events: none;
   opacity: 20%;
}

.yellowGradient02 {
   background: radial-gradient(
         32.65% 32.65% at 35% 50%,
         #04f0ff 0%,
         rgba(20, 208, 230, 0) 100%
   );
   position: absolute;
   width: 500px;
   height: 500px;
   left: 10px;
   top: 550px;
   mix-blend-mode: screen;
   opacity: 0.3;
   z-index: -1;
   pointer-events: none;
   opacity: 20%;
}

.yellowGradient03 {
   background: radial-gradient(
         32.65% 32.65% at 35% 50%,
         #103a5f 0%,
         rgba(3, 22, 31, 0) 100%
   );
   position: absolute;
   width: 30vw;
   height: 60vw;
   left: -55px;
   top: 30%;
   mix-blend-mode: screen;
   opacity: 0.5;
   z-index: -1;
   pointer-events: none;
}

.yellowRightSphere {
   position: absolute;
   width: 16vw;
   height: 34vw;
   right: 0;
   top: 5%;
   mix-blend-mode: screen;
   opacity: 1;
   background-image: url("https://storage.googleapis.com/meta11/serviceplatform/yellowRightSphere.webp");
   background-position: 0 0;
   background-repeat: no-repeat;
   background-size: 100%;
   z-index: -1;
   pointer-events: none;
}

.starryback {
   width: 100%;
   height: 100%;
   background-image: url("https://storage.googleapis.com/meta11/serviceplatform/starryback.webp");
   background-position: 0 -100px;
   background-repeat: no-repeat;
   background-size: 100%;
}

.leftRay {
   position: absolute;
   opacity: 0.3;
   left: -12px;

   img {
      width: 70%;
   }
}

.rightRay {
   position: absolute;
   opacity: 0.3;
   right: 0;
   text-align: right;

   img {
      width: 70%;
   }
}

.ladyBack {
   position: absolute;
   width: 100%;
   height: 100%;
   right: 0;
   top: 5%;
   opacity: 1;
   background-image: url("https://storage.googleapis.com/meta11/serviceplatform/BG-blue-ladyBack.webp");
   background-position: -1px -179px;
   background-repeat: no-repeat;
   background-size: 93%;
   z-index: -1;
   pointer-events: none;
}

.redManEllips {
   position: absolute;
   width: 30vw;
   height: 30vw;
   left: 0;
   mix-blend-mode: screen;
   opacity: 1;
   z-index: 0;
   pointer-events: none;
}

.redManEllipsRed {
   position: absolute;
   width: 28vw;
   height: 35vw;
   right: 0;
   bottom: 330px;
   mix-blend-mode: screen;
   opacity: 0.5;
   background: radial-gradient(
         32.65% 32.65% at 100% 50%,
         #e61479 0%,
         rgba(230, 20, 20, 0) 100%
   );
   z-index: -2;
   pointer-events: none;
}

.redMansphere {
   position: absolute;
   width: 16vw;
   height: 26vw;
   right: 0;
   bottom: -230px;
   mix-blend-mode: screen;
   opacity: 0.8;
   background-image: url("https://storage.googleapis.com/meta11/serviceplatform/redMansphere.webp");
   background-position: 0 0;
   background-repeat: no-repeat;
   background-size: 100%;
   z-index: -1;
   pointer-events: none;
}

.sectionPadding {
   padding: 80px 0 0 0;
}

.rightLightning {
   position: absolute;
   right: 0;
   top: 0;
   opacity: 0.6;
   mix-blend-mode: overlay;
}

.lineWaveImgWrapper {
   position: absolute;
   top: 469px;
   left: 0;
   overflow: hidden;

   img {
      width: 100%;
   }
}

.section1Container {
   height: auto;
}

.FaqFonts {
   font-size: 24px;
   font-family: "Montserrat", sans-serif;
   cursor: pointer;
   font-weight: 500;

   font {
      width: 80%;
      float: left;
   }
}

.FaqFontsP {
   font-size: 14px;
   font-family: "OSRegular", Helvetica, Arial, sans-serif;
   font-weight: 100;
   color: #95a6b7;
   line-height: 18px;
   text-align: justify;
   text-justify: inter-word;
   margin-top: 8px;
}

.FaqFontsOl {
   font-size: 14px;
   font-family: "OSRegular", Helvetica, Arial, sans-serif;
   font-weight: 100;
   color: #95a6b7;
   line-height: 18px;
   text-align: justify;
   text-justify: inter-word;

   li {
      margin-top: 8px;
   }
}


.joinComunitySection {
   background: rgb(57, 45, 252);
   background: linear-gradient(
         90deg,
         rgba(57, 45, 252, 0.2) 0%,
         rgba(255, 122, 0, 0.2) 100%
   );
   width: 100%;
   float: left;
   padding: 90px 40px;
   margin-top: 120px;
   display: flex;
   flex-direction: column;
   border-image: linear-gradient(to right, rgba(243, 221, 27, 0), #2DFEE5, rgba(243, 221, 27, 0)) 1 0 0 0;
   border-top: 1px solid;

   .leftShock {
      position: absolute;
      top: 0;
      left: 0;
      width: 25%;
      height: 100%;
      background-image: url("https://storage.googleapis.com/meta11/serviceplatform/leftShock.webp");
      background-size: 100% 100%;
      background-position: 0 0;
      z-index: -2;
      opacity: 0.7;
   }

   .rightShock {
      position: absolute;
      top: 0;
      right: 0;
      width: 25%;
      height: 100%;
      background-image: url("https://storage.googleapis.com/meta11/serviceplatform/rightShock.webp");
      background-size: 100% 100%;
      background-position: 0 0;
      z-index: -2;
      opacity: 0.7;
   }

   img {
      margin-top: -76px;
      width: 100%;
   }
}

.JoinComunityRowWrapper {
   position: relative;

   .ellipse.eMulticolorFull {

      @include eMulticolorFull;

      z-index: -1;
      width: 30vw;
      height: 30vw;
      max-width: 320px;
      max-height: 320px;
      right: -79px;
      opacity: 1;
      bottom: -93px;
   }

   .ellipse.e39 {
      width: 46vw;
      height: 76vh;
      right: -352px;
      max-width: 320px;
      max-height: 320px;
      top: 0%;
      opacity: 1;
   }

   .joinComunitySection {
      padding: 75px 50px 75px 50px;
   }
}

.section3Background {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-image: url("https://storage.googleapis.com/meta11/serviceplatform/BG-blue.webp");
   background-size: 100% 100%;
   background-position: 0 0;
   z-index: -2;
}

.section3Background2 {

   width: 100%;
   height: 100%;
   background-image: url("https://storage.googleapis.com/meta11/serviceplatform/BG-blue.webp");
   background-size: 100% 100%;
   background-position: 0 0;

}

.ResponceCont {
   max-width: 1240px;
   margin-left: auto;
   margin-right: auto;
}

.ResponceCont2 {
   max-width: 1440px;
   margin-left: auto;
   margin-right: auto;
}

.topline {
   background-image: url("https://storage.googleapis.com/meta11/serviceplatform/GradientTopBorder.webp");
   background-repeat: no-repeat;
   height: 2px;
   background-position: center 0%;
   background-size: 100%;
}

.inputHome[type="text"] {
   display: block;
   background-image: -webkit-linear-gradient(
         0deg,
         rgba(4, 21, 29, 1) 0%,
         rgba(11, 28, 53, 0) 100%
   );
   background-image: -moz-linear-gradient(
         0deg,
         rgba(4, 21, 29, 1) 0%,
         rgba(11, 28, 53, 0) 100%
   );
   background-image: -o-linear-gradient(
         0deg,
         rgba(4, 21, 29, 1) 0%,
         rgba(11, 28, 53, 0) 100%
   );
   background-image: linear-gradient(
         90deg,
         rgba(4, 21, 29, 1) 0%,
         rgba(11, 28, 53, 0) 100%
   );
   max-width: 100%;
   color: #ffffff;
   padding-left: 20px;
   padding-right: 50px;
   font-size: 16px;
   /*font-family: 'SolinaLight';*/
   width: 100%;
   box-sizing: border-box;
   border: solid 1px #3e5168;
   height: 54px;
   border-radius: 3px;
   background-color: #04151d;
   padding-bottom: 22px;
   padding-top: 20px;
   line-height: 10px;
   /*background-image: url("https://storage.googleapis.com/meta11/serviceplatform/pw-eye.png");*/
   background-repeat: no-repeat;
   background-position: 96% center;
   transform: skew(-10deg, 0deg);
   text-transform: skew(10deg, 0deg);
}

.inputHome[type="text"]:focus {
   border-style: solid;
   border-width: 1px;
   border-radius: 2px;
   outline: none;
}

/* active state */
.inputHome[type="text"]:focus ~ label,
.inputHome[type="text"]:valid ~ label {
   top: 10px;
   font-size: 12px;
   color: #95a6b7;
   padding-left: 6px;
}

.mainImgBg {
   // background-image: url("https://storage.googleapis.com/meta11/serviceplatform/home3-1.webp");
   background-repeat: no-repeat;
   background-position: right center;
   background-size: auto 100%;
   background-blend-mode: normal;
   // padding-bottom: 100px !important;
}


.startButtonWrapper {
   a, button {
      display: block;
      float: left;
      height: 53px;
      //line-height: 22px;
   }

   a {
      padding: 18px 15px;
   }
}

/* Countdown */
@mixin Countdown {
   .countdown {
      width: fit-content;
      margin: 0 auto;
      margin-top: 10px;
      display: grid;
      grid-template-columns: repeat(3, 84px);
      gap: 30px;

      > div {
         .cercalBack {
            width: 82px;
            height: 82px;
            // background-image: url('../images/backgrounds/countdown_circle-01.svg');
            background-image: url('../../assets/images/backgrounds/countdown_circle-01.svg');
            background-position: top left;
            background-repeat: no-repeat;
            position: relative;
         }

         .cercal {
            width: 82PX;
            height: 82px;
            border-radius: 50%;
            /* border: 1.69px solid; */
            border-color: linear-gradient(143.92deg, #FAFF01 13.27%, #39FF96 61.67%);

            > span {
               position: absolute;
               width: 50px;
               top: 29%;
               left: 20%;
               margin-left: auto;
               margin-right: auto;
               font-family: $countdown-count-font;
               // font-size: $countdown-count;
               font-size: 21px;
               letter-spacing: 2px;
               background: -webkit-linear-gradient(45deg, #FAFF00, #00FFC2 80%);
               -webkit-background-clip: text;
               -webkit-text-fill-color: transparent;
               text-align: center;
            }

         }

         > span {
            text-transform: uppercase;
            font-size: 14.82px;
            font-weight: 400;
            display: block;
            text-align: center;
            font-family: $countdown-bottom-font;
            font-size: $countdown-bottom-title;
            margin-top: 8px;
            background: -webkit-linear-gradient(45deg, #FAFF00, #00FFC2 80%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
         }

      }

      .timerWrapper {
         width: fit-content;
         position: absolute;
         top: 8px;
         margin-left: 1px;

         div {
            max-width: 80px;

            > svg {
               max-width: 80px;

               > :nth-child(1) {
                  stroke: unset !important;
               }
            }
         }

      }
   }
}

.nextTournamentCol {
   .nextTournamentWrapper {
      position: absolute;
      // left: 0;
      right: 10vw;
      margin-left: auto;
      margin-right: auto;

      bottom: 0;
      width: 480px;
      // height: 182px;
      border-radius: 3px;
      box-shadow: 0px 0px 55.573062896728516px 0px rgba(0, 255, 194, 0.7);
      background: #00000042;
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
      border: solid 1px;
      border-image: linear-gradient(45deg, #F9FF01, #2FFF9E) 1;
      border-width: 2px;
      padding-bottom: 10px;

      h2 {
         font-family: $countdown-bottom-font;
         font-size: 27px;
         font-weight: 400;
         line-height: 27px;
         letter-spacing: 0.28em;
         text-align: center;
         text-transform: uppercase;
         background: -webkit-linear-gradient(45deg, #FAFF00, #00FFC2 80%);
         -webkit-background-clip: text;
         -webkit-text-fill-color: transparent;
         margin-top: 20px;
      }

      @include Countdown;
   }
}

.faqListWrapper {
   float: left;
   width: 100%;
   border-bottom-width: 1px;
   border-bottom: 1px solid transparent;
   -webkit-border-bottom-width: 1px;
   border-style: solid;
   border-image: linear-gradient(to right, rgba(38, 200, 103, 0), rgba(38, 200, 103, 1), rgba(38, 200, 103, 0)) 0.8;
   -webkit-mask-image: linear-gradient(to right, rgba(38, 200, 103, 0), rgba(38, 200, 103, 1), rgba(38, 200, 103, 0)) 0.8;
   border-top: 0px;
}

.kumasContent {
   h2 {
      text-align: left;
      font-family: "Montserrat-Regular", ObjectSans-Regular;
      font-size: 32px;
      font-weight: 600;
      line-height: 42px;
      letter-spacing: 0.02em;
      margin-bottom: 14px;
   }

   p {
      text-align: left;
      font-size: 22px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: -0.01em;
      margin-bottom: 20px;
      font-family: "ObjectSans-Regular";
   }

   > div {
      background: linear-gradient(91deg, rgba(57, 45, 252, 0.2) 1.34%, rgba(255, 122, 0, 0.2) 100%) !important;
      border: 1px solid #0c2b3e;
      display: flex;
      align-items: center;

      > div:nth-child(1) {
         padding-top: 20px !important;
         padding-bottom: 20px !important;
         padding-right: 20px !important;
      }
   }

   img {
      width: unset;
      width: 100%;
      display: block;
      margin: 0 auto;
      max-width: 440px;
   }
}

.homeContainer {
   .mainH1Wrapper {
      position: relative;

      h1 {
         // font-size: 51px !important;
         // line-height: 50px;
         font-size: 42px;
         line-height: normal;
         position: relative;

         > div.timeNontText {
            font-size: 48px !important;
            line-height: normal !important;

            > label {
               font-size: 32px !important;
               font-weight: 600;
               text-transform: uppercase;
               background: linear-gradient(89.7deg, #17EA85 2.27%, #14D0E6 23.6%, #17EA85 44.41%, #14D0E6 64.17%, #17EA85 82.99%, #14D0E6 99.74%);
               -webkit-background-clip: text;
               -webkit-text-fill-color: transparent;
               background-clip: text;
               text-fill-color: transparent;
               text-shadow: 0px 0px 8px rgba(20, 208, 229, 0.5607843137);
            }
         }

         > span {
            display: block;
            font-size: 32px !important;
            line-height: 50px;
            -webkit-text-size-adjust: 50%;
            line-height: normal;
            margin-bottom: -15px !important;
         }
      }
   }

   .timeNontText {
      text-transform: uppercase;
      background: linear-gradient(89.7deg, #17EA85 2.27%, #14D0E6 23.6%, #17EA85 44.41%, #14D0E6 64.17%, #17EA85 82.99%, #14D0E6 99.74%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      text-shadow: 0px 0px 8px rgba(20, 208, 229, 0.5607843137);
   }
}

.homeContainer {
   .startButtonWrapper {
      > button {
         background: linear-gradient(0deg, #0E2029, #0E2029) padding-box !important;
      }
   }
}

.StepwizardRowWrapper {
   position: relative;
   background-image: url("https://storage.googleapis.com/meta11/serviceplatform/BG-blue.webp");
   background-size: 100% 100%;

   .ellipse.e39 {
      right: 27%;
      width: 36vw;
      height: 61vh;
      top: 54%;
   }
}

.StepwizardRow {
   // background-image: url("https://storage.googleapis.com/meta11/serviceplatform/BG-blue.webp");
   background-size: 100% 100%;

   .ellipse.e28 {
      width: 197vw;
      height: 134vh;
      left: -3vh;
      top: -40vh;
      opacity: 0.5;
      z-index: -1;
   }
}

.HowItWorks {
   padding: 20px;

   h1 {
      max-width: 830px;
      margin: 0 auto;
      text-align: center;
   }

   p {
      max-width: 800px;
      margin: 0 auto;
      text-align: center;
      font-size: 18px;
   }

   .Stepwizard {
      ul {
         display: flex;

         margin: 0 auto;
         margin-top: 20px;
         padding-left: 0;
         padding-right: 0;
         width: unset;
         width: fit-content;

         li {
            width: 240px;
            list-style: none;
            position: relative;

            h3 {
               font-size: 16px;
               font-family: "ObjectSans-Regular";
               text-align: center;
               font-weight: 300;
               text-transform: uppercase;
            }

            span {
               position: relative;
               text-align: center;
               width: 56px;
               height: 56px;
               display: flex;
               justify-content: center;
               align-items: center;
               margin: 0 auto;
               border-radius: 50%;
               background: linear-gradient(#F9FF01, #2FFF9E);
               border-width: 2px;

               > div {
                  width: 54px;
                  height: 54px;
                  background-color: #000;
                  border-radius: 50%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
               }
            }

            > div {
               margin: 10px;
            }
         }


         li::before {
            content: "";
            display: block;
            height: .5px;
            width: 100%;
            top: 32px;
            position: absolute;
            border-bottom: .5px solid transparent;
            -webkit-border-bottom-width: .5px;
            border-style: solid;
         }

         li:first-child:before {
            left: 50%;
            border-image: linear-gradient(to right, rgba(38, 200, 103, 0), rgb(38, 200, 103)) 1;
            -webkit-mask-image: linear-gradient(to right, rgba(38, 200, 103, 0), rgb(38, 200, 103)) 1;
         }

         li:nth-child(2):before {
            border-image: linear-gradient(to right, rgba(38, 200, 103, 0), rgb(38, 200, 103), rgba(38, 200, 103, 1)) 1;
            -webkit-mask-image: linear-gradient(to right, rgba(38, 200, 103, 0), rgb(38, 200, 103), rgba(38, 200, 103, 1)) 1;
         }

         li:nth-child(3):before {
            border-image: linear-gradient(to right, rgb(38, 200, 103), rgba(38, 200, 103, 1)) 1;
            -webkit-mask-image: linear-gradient(to right, rgb(38, 200, 103), rgba(38, 200, 103, 1)) 1;
         }

         li:last-child:before {
            position: absolute;
            right: 50%;
            border-image: linear-gradient(to right, rgb(38, 200, 103), rgb(38, 200, 103), rgba(38, 200, 103, 0)) 1;
            -webkit-mask-image: linear-gradient(to right, rgb(38, 200, 103), rgb(38, 200, 103), rgba(38, 200, 103, 0)) 1;
            z-index: -1;
         }

         li:hover {
            cursor: pointer;
         }

         li:nth-child(1) {
            .ellipse.eMulticolorFull.E1 {
               @include eMulticolorFull;

               z-index: -1;
               width: 70px;
               height: 70px;
               top: 64px;
               opacity: 1;
               left: 0px;
            }

            .ellipse.eMulticolorFull.E2 {
               @include eMulticolorFull;

               z-index: -1;
               width: 24px;
               height: 24px;
               top: 72px;
               opacity: 1;
               right: 11px;
            }

            .ellipse.eMulticolorFull.E3 {
               @include eMulticolorFull;

               opacity: 1;
               z-index: -1;
               width: 24px;
               height: 24px;
               bottom: 80px;
               right: 40px;
            }
         }

         li:nth-child(2) {
            .ellipse.eMulticolorFull.E1 {
               @include eMulticolorFull;

               z-index: -1;
               width: 24px;
               height: 24px;
               top: 144px;
               opacity: 1;
               left: 20px;
            }

            .ellipse.eMulticolorFull.E2 {
               @include eMulticolorFull;

               z-index: -1;
               width: 70px;
               height: 70px;
               top: 62px;
               opacity: 1;
               right: -11px;
            }

         }

         li:nth-child(3) {
            .ellipse.eMulticolorFull.E1 {
               @include eMulticolorFull;

               z-index: -1;
               width: 32px;
               height: 32px;
               top: 124px;
               opacity: 1;
               left: 10px;
            }

            .ellipse.eMulticolorFull.E2 {
               @include eMulticolorFull;

               z-index: -1;
               width: 57px;
               height: 57px;
               top: 160px;
               opacity: 1;
               right: -11px;
            }

         }

         li:nth-child(4) {
            .ellipse.eMulticolorFull.E1 {
               @include eMulticolorFull;

               z-index: -1;
               width: 32px;
               height: 32px;
               top: 150px;
               opacity: 1;
               left: 10px;
            }

            .ellipse.eMulticolorFull.E2 {
               @include eMulticolorFull;

               z-index: -1;
               width: 57px;
               height: 57px;
               top: 80px;
               opacity: 1;
               right: -11px;
            }

         }


      }
   }
}

.HowToPlayRow {
   position: relative;
   padding-top: 4%;

   .ellipse.e40 {
      position: absolute;
   }

   .ellipse.e38 {
      left: -295px;
      top: 52%;
      height: 73vh;
      width: 48vw;
   }

   .ellipse.e38.e38II {
      left: unset;
      right: -350px;
      top: 30%;
      height: 73vh;
   }
}

.FeaturedTournamentsRowWrapper {

}

.FeaturedTournaments {
   padding: 20px;

   h1 {
      text-align: center;
      padding-bottom: 40px;
   }

   .TournamentsWrapper {
      display: flex;
   }

   .BtnRow {
      justify-content: center;
      display: flex;
      margin-top: 20px;
   }

   @mixin ButtonStyle {
      button {
         min-width: 128px;
         width: fit-content;
         margin: 0;
         border-radius: 4px;
         height: 46px;
         font-size: 13px;
         text-align: center;
         line-height: 10px;
      }
      button.grayButton {
         color: #95A6B7;
      }
      button:hover {
         text-decoration: none;
      }
   }

   .TournamentsWrapper {
      // display: inline-flex;
      // flex-wrap: wrap;
      display: grid;
      grid-template-columns: 600px 600px;
      gap: 20px;
      width: fit-content;
      margin: 0 auto;

      .Tournament {
         max-width: 600px;
         height: 212px;
         padding: 24px;
         background-repeat: no-repeat !important;

         h4 {
            font-size: 16px;
            color: #17EA85;
            text-transform: uppercase;
            font-weight: 700;
            font-style: italic;
         }

         h3 {
            font-family: "Montserrat";
            font-size: 24px;
            text-transform: uppercase;
            font-weight: 800;
            font-style: italic;
            width: 310px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
         }

         .PrizePool {
            position: relative;
            background: linear-gradient(180deg, rgba(26, 38, 57, 0.5) 0%, rgba(11, 28, 53, 0.5) 100%);
            border: 1px solid #1F2A36;
            border-radius: 2px;
            width: 175px;
            padding-top: 10px;
            padding-bottom: 10px;

            .OminiCreditWrapper {
               display: flex;
               gap: 10px;
               font-size: 14px;
               justify-content: center;

               > div {
                  display: flex;
                  gap: 5px;
               }

               img {
                  width: 24px;
                  height: 24px;
               }
            }

            label:first-child {
               position: absolute;
               top: 0;
               display: block;
               height: 1px;
               width: 100%;
               border-top: 1px solid;
               border-image: linear-gradient(to right, rgba(243, 221, 27, 0), #2DFEE5, rgba(243, 221, 27, 0)) 1 0 0 0;
            }

            label:last-child {
               position: absolute;
               bottom: -11px;
               display: block;
               height: 1px;
               width: 100%;
               border-top: 1px solid;
               border-image: linear-gradient(to right, rgba(243, 221, 27, 0), #2DFEE5, rgba(243, 221, 27, 0)) 1 0 0 0;
            }
         }


         .Row1 {
            display: flex;

            > div {
               flex-grow: 1;
            }

            .Col2 {
               .nextTournamentWrapper {
                  width: 210px;
                  @include Countdown;

                  .countdown {
                     gap: 5px;
                     scale: 1;
                     margin-top: -10px;
                  }
               }

            }
         }

         .Row2 {
            display: flex;

            > div {
               flex-grow: 1;
            }

            .BtnRow {
               display: flex;
               gap: 10px;
               margin-right: -10px;

               @include ButtonStyle;

               .LockIcon {
                  margin-top: -6px;
                  margin-right: 5px;
                  margin-left: -6px;
               }
            }
         }

      }

      .ScheduleTournament {
         position: relative;

         .BtnRow {
            margin: 0;
         }

         .FooterRow {
            position: absolute;
            width: 100%;
            bottom: 16px;

            .BtnRow {
               margin: 0;
               padding: 0;
               justify-content: right;
               gap: 10px;
               margin-right: 49px;
               @include ButtonStyle;

               .LockIcon {
                  margin-top: -6px;
                  margin-right: 5px;
                  margin-left: -6px;
               }
            }

            .creditsWrapper {
               width: fit-content;
               display: inline-block;
               font-size: 14px;
               margin-right: -10px;

               img {
                  transform: skew(10deg, 0deg) !important;
                  margin: 0 8px;
               }

               span {
                  color: #17EA85;
                  font-size: 14px !important;
                  transform: skew(10deg, 0deg) !important;
               }

               label {
                  text-transform: capitalize;
                  font-size: 12px;
               }

               .credits {
                  padding: 6px 22px;
                  height: 46px;
                  border: none;
                  outline: none;
                  position: relative;
                  z-index: 1;
                  border-radius: 2px;
                  background: linear-gradient(103.05deg, #F9FF01 7.22%, #2FFF9E 93.09%);
                  //    cursor: pointer;
                  transform: skew(-9deg, 0deg);
                  text-transform: uppercase;
                  margin-right: 10px;
               }

               .credits::before {
                  content: "";
                  position: absolute;
                  left: 1px;
                  right: 1px;
                  top: 1px;
                  bottom: 1px;
                  border-radius: 4px;
                  background-color: #0E2029;
                  z-index: -1;
                  transition: 200ms
               }

               .credits::after {
                  content: attr(data);
                  font-size: 16px;
                  background: linear-gradient(to left, #00FFA3, #DC1FFF);
                  -webkit-background-clip: text;
                  color: #fff;
                  transition: 200ms
               }
            }
         }
      }

      .LiveTournament {
         .liveTagRow {
            float: right !important;
            width: fit-content;
            // height: 21px;

            @keyframes BgGradient {
               0% {
                  scale: 1;
                  opacity: 1;
               }
               50% {
                  scale: 1.1;
                  opacity: .8;
               }
               90% {
                  scale: 1.2;
                  opacity: .3;
               }
               100% {
                  scale: 1;
                  opacity: .0;
               }
            }

            .PlayNowLiveTagContainer {
               position: relative;

               .PlayNowLiveTag {
                  width: -moz-fit-content;
                  width: fit-content;
                  // background: linear-gradient(96.32deg, #B10B8F 1.99%, #E01655 99.35%), linear-gradient(92.2deg, #E11653 2.13%, #AD0A93 106.58%);
                  background: linear-gradient(-45deg, #ee7752, #e73c7e, #931cb1, #e01655);
                  transform: skew(-10deg, 0deg);
                  bottom: 2px;
                  font-family: "ObjectSans-Regular";
                  letter-spacing: 2px;
                  font-size: 12px;
                  padding: 2px 10px;
                  height: 24px;
                  color: rgb(255, 255, 255);
                  -webkit-text-fill-color: #FFFFFF !important;
                  text-transform: uppercase;
                  display: flex;
                  font-size: 10px;
                  background-size: 300% 200%;
                  // animation: BgGradient 1s ease infinite;
                  .LiveTag {
                     padding-top: 2px;
                  }

                  .BlinkingDot {
                     display: block;
                     margin-top: 8px;
                     margin-right: 4px;
                  }
               }

               .PlayNowLiveTagWrapper {
                  background: linear-gradient(-45deg, #ee7752, #e73c7e, #931cb1, #e01655);
                  transform: skew(-10deg, 0deg);
                  width: 102px;
                  height: 27px;
                  border-radius: 2px;
                  position: absolute;
                  top: -2px;
                  animation: BgGradient 1s ease infinite;
               }
            }

         }

         .Col2 {
            position: relative;

            .BtnRow {
               justify-content: right;
               margin-right: 0px;
               margin-top: 62px;
            }
         }

         .TwoPlayersWrapper {

            .TwoPlayers {
               margin-top: 12px;
               gap: 10px;
               margin-left: 6px;
               padding-top: 4px;

               > label {
                  display: block;
                  padding-right: 2px;

                  > img {
                     display: block;
                     margin: 0 auto;
                     max-width: 30px;
                     margin-top: 5px;
                  }
               }

               .PlayerWrapper {
                  .Player {
                     //   width: fit-content;
                     max-width: 200px;
                     padding: 2px 20px;
                     transform: skew(-10deg, 0deg);
                     border-width: 2px;
                     border-image-source: linear-gradient(99.98deg, rgba(213, 42, 177, 0.4) -2.72%, rgba(68, 91, 204, 0.4) 128.13%);
                     background: radial-gradient(106.3% 411.84% at 12.63% 50%, rgba(213, 42, 177, 0.3) 0%, rgba(68, 91, 204, 0.6) 100%);
                     border: 1px solid #5a66bb8f !important;
                     border-radius: 2px;

                     img {
                        width: 37px;
                        height: 42px;
                     }

                     .PlayerThumbWrapper {
                        width: 37px;
                        height: 42px;
                        position: absolute;
                        top: -12px;
                        left: -8px;
                        background-image: url(../../assets/images/PlayerBackground.webp);
                        background-repeat: no-repeat;
                        //transform: skew(-10deg, 0deg);
                        border-radius: 4px;
                     }

                     span {
                        display: block;
                        font-family: "ObjectSans-Regular";
                        font-size: 13px;
                        // font-weight: 700;
                        margin-left: 20px;
                        max-width: 140px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                     }
                  }
               }
            }
         }

         .creditsWrapper {
            width: fit-content;
            display: inline-block;
            font-size: 14px;
            margin-right: -10px;

            img {
               transform: skew(10deg, 0deg) !important;
               margin: 0 8px;
            }

            span {
               color: #17EA85;
               font-size: 14px !important;
               transform: skew(10deg, 0deg) !important;
            }

            label {
               text-transform: capitalize;
               font-size: 12px;
            }

            .credits {
               padding: 6px 22px;
               height: 46px;
               border: none;
               outline: none;
               position: relative;
               z-index: 1;
               border-radius: 2px;
               background: linear-gradient(103.05deg, #F9FF01 7.22%, #2FFF9E 93.09%);
               //    cursor: pointer;
               transform: skew(-9deg, 0deg);
               text-transform: uppercase;
               margin-right: 10px;
            }

            .credits::before {
               content: "";
               position: absolute;
               left: 1px;
               right: 1px;
               top: 1px;
               bottom: 1px;
               border-radius: 4px;
               background-color: #0E2029;
               z-index: -1;
               transition: 200ms
            }

            .credits::after {
               content: attr(data);
               font-size: 16px;
               background: linear-gradient(to left, #00FFA3, #DC1FFF);
               -webkit-background-clip: text;
               color: #fff;
               transition: 200ms
            }
         }
      }

   }

}

// .ssss{
//    background-color: red;

//
// }
.HowToPlay {
   h1 {

   }

   p {
      color: #CBCFD2;
      font-size: 18px;
      margin-bottom: 70px;
      text-align: center;
   }
}

.Testimonials {
   padding: 20px;

   h1 {
      text-align: center;
   }

   .Testimonial {
      border-top: 1px solid;
      border-image: linear-gradient(to right, rgba(243, 221, 27, 0), #53fda7, rgba(243, 221, 27, 0)) 1 0 0 0;
      max-width: 1000px;
      margin: 0 auto;
      // padding: 80px;
      // background-image: url("../../assets/images/backgrounds/Testimonials_background.png");
      // background-repeat: no-repeat;
      .TestimonialWrapper {
         margin: 80px;
         position: relative;
      }

      .Quote {
         width: 40PX;
         height: 28PX;
         position: absolute;
         left: -30px;
         top: 44px;
      }

      p {
         font-size: 16px;
         font-family: 'ObjectSans-Regular';
         font-weight: 400;
      }

      .Footer {
         .Row1 {
            display: flex;
            justify-content: space-between;
            max-width: 550px;
            width: 100%;
            margin: 0 auto;
            margin-top: 40px;

            .ProfilePic {
               width: 80px;
               height: 80px;
               border-radius: 50%;
               border: 2px solid #17EA85;
               // border-image-source: linear-gradient(89.7deg, #17EA85 2.27%, #14D0E6 23.6%, #17EA85 44.41%, #14D0E6 64.17%, #17EA85 82.99%, #14D0E6 99.74%);
            }

            a {
               color: #95A6B7;
               margin-top: 25px;
            }

            a:hover {
               color: #17EA85;
               cursor: pointer;
            }
         }

         .Row2 {
            padding-top: 24px;

            h4 {
               font-size: 22px;
               text-align: center;
               text-transform: uppercase;
               font-style: italic;
            }

            p {
               font-family: 'ObjectSans-Regular';
               font-weight: 400;
               font-size: 18px;
               text-align: center;
               color: #95A6B7;
            }
         }
      }
   }
}

.RoadMapRowWrapper {
   background-image: url("../../assets/images/backgrounds/BG-blue2.webp");
   background-repeat: no-repeat;
   background-size: 100% 100%;
   position: relative;

   .ellipse.ePinkFull {
      @include ePinkFull;

      z-index: -2;
      width: 45vw;
      height: 45vw;
      top: 28vw;
      left: -25vw;
      opacity: 0.8;
   }

   .RoadMap {

      h1 {
         padding-bottom: 20px;
      }

      .timeline {
         position: relative;
         max-width: 1200px;
         margin: 0 auto;

         h2 {
            font-size: 24px;
         }
      }

      /* Container around content */
      .container {
         padding: 0px 90px;
         position: relative;
         background-color: inherit;
         width: 50%;
         margin-top: -10%;
      }

      .container:first-child {
         margin-top: 0;
      }

      /* The circles on the timeline */
      .container::after {
         content: '';
         position: absolute;
         width: 25px;
         height: 25px;
         right: -10.5px;
         background-color: #17EA85;
         // border: 4px solid #FF9F55;
         top: 45%;
         border-radius: 50%;
         z-index: 1;
      }

      /* Place the container to the left */
      .left {
         left: 0;
      }

      /* Place the container to the right */
      .right {
         left: 50%;
      }

      /* Add arrows to the left container (pointing right) */
      .left::before {
         content: " ";
         right: -1px;
         position: absolute;
         width: 91px;
         height: 2px;
         top: calc(50% - 1px);
         background: #146339;
      }

      /* Add arrows to the right container (pointing left) */
      .right::before {
         content: " ";
         left: -3px;
         position: absolute;
         width: 91px;
         height: 2px;
         top: calc(50% - 1px);
         background: #146339;
      }

      /* Fix the circle for containers on the right side */
      .right::after {
         left: -16px;
      }

      /* The actual content */
      .content {
         padding: 20px 30px;
         background: linear-gradient(91.25deg, rgba(57, 45, 252, 0.2) 1.34%, rgba(255, 122, 0, 0.2) 100%);

         position: relative;
         border-radius: 6px;

         p {
            font-size: 18px;
            text-align: left;
            color: #95A6B7;
            line-height: 24px;
         }

         img {
            margin-bottom: 20px;
         }
      }

      .content.LeftBorder::after {
         content: "";
         position: absolute;
         width: 1px;
         background-color: #146339;
         top: 0;
         bottom: 0;
         left: -92px;
         margin-left: -2px;
         z-index: 1;
         height: 101%;
      }

      .content.RightBorder::after {
         content: "";
         position: absolute;
         width: 1px;
         background-color: #146339;
         top: 0;
         bottom: 0;
         right: -87px;
         margin-left: -3px;
         z-index: 1;
      }


      .container:first-child {
         .content.RightBorder::after {
            content: "";
            position: absolute;
            width: 1px;
            background-color: #146339;
            top: 50%;
            bottom: 0;
            right: -87px;
            margin-left: -3px;
            z-index: 1;
            height: 70%;
         }
      }

      .container:last-child {
         .content.RightBorder::after {
            content: "";
            position: absolute;
            width: 1px;
            background-color: #146339;
            // top: 50%;
            bottom: 50%;
            right: -87px;
            margin-left: -3px;
            z-index: 1;
            height: 50%;
         }
      }

      .Topborder {
         border-image: linear-gradient(to right, rgba(243, 221, 27, 0), #F3DE1B, rgba(243, 221, 27, 0)) 1 0 0 0;
         border-top: 1px solid;
         height: 2px;
         width: 100%;
      }

   }
}

.SprtsTokenRowWrapper {
   position: relative;

   .ellipse.ePinkFull {
      @include ePinkFull;

      z-index: -2;
      width: 65vw;
      height: 65vw;
      top: 10vw;
      right: -30vw;
   }

   .ellipse.eMulticolorFull {
      @include eMulticolorFull;

      z-index: -1;
      width: 30vw;
      height: 30vw;
      max-width: 320px;
      max-height: 320px;
      top: 20vw;
      right: 5vw;
      opacity: 1;
   }

   .SprtsToken {
      display: flex;

      .Col1 {
         img {
            max-width: 488px;
         }
      }

      .Col2 {
         display: flex;
         align-items: center;

         > div {
            margin-top: -40px;
         }

         h1 {
            flex-direction: column;
            width: 100%;
         }

         a {
            min-width: 100px;
         }
      }
   }
}

.JoinComunityRowWrapper {
   .joinComunitySection {
      .BtnRowWrapper {
         > div {
            input, button {
               height: 56px !important;
            }

            input {
               height: 58px !important;
               margin-top: -2px;
            }

            button {
               padding: 16px !important;
            }
         }
      }
   }
}

.mainH1 {
   margin-bottom: 20px;
}

.MainVideoCol {
   display: flex;
   align-items: flex-start;
   justify-content: right;

   video {

      width: 85%;
      aspect-ratio: 1.77917 / 1;
      border-image: linear-gradient(45deg, #f9ff01, #2fff9e) 1;
      border-radius: 3px;
      bottom: 0;
      box-shadow: 0 0 55.5730628967px 0 #00ffc2b3;

      source {
         width: 100% !important;
      }
   }
}

.mainImgBg {
   h1 {
      text-align: center;

      span {
         text-align: left;
      }
   }
}

.mainH1WrapperWithStar {
   position: relative;
   display: flex;
   justify-content: center;
}

.eMulticolorFullHeader {
   @include eMulticolorFull;
   top: 9vw;
   right: -5.28vw;
   width: 250px;
   height: 250px;
   opacity: .6;
}

.HeaderTopContentWrapper {
   display: flex;
   flex-direction: column;
   justify-content: space-evenly;
}

.startButtonWrapper {
   a, button {
      font-family: "OSHeavySlanted", Helvetica, Arial, sans-serif !important;
      font-weight: 700 !important;
      letter-spacing: -0.01em !important;
      font-size: 16px !important;
   }

   button {
      padding: 12px 16px !important;
      font-size: 15px !important;
   }
}

@media screen and (min-width: 1400px) {
   .nextTournamentLeftCol {
      display: none;
   }
   .nextTournamentCol {
      position: relative;
      width: 100%;
      max-width: 1400px;
      margin: 0 auto;

      .nextTournamentWrapper {
         right: 0;
      }
   }
   .TournamentsWrapper {
      position: relative;
      min-height: 100px;

      .LoaderIconWrapper {
         position: absolute;
         left: 0;
         right: 0;
         margin-left: auto;
         margin-right: auto;
      }
   }
   .mainH1WrapperWithStar {
      margin-bottom: 40px;

      h1 {
         font-size: 52px !important;

         .startButtonWrapper {
            a, button {
               font-family: "OSHeavySlanted", Helvetica, Arial, sans-serif !important;
               font-weight: 700 !important;
               letter-spacing: -0.01em !important;
            }
         }
      }
   }
}


@media screen and (max-width: 1400px) {
   .container {
      max-width: 100%;
   }
   .FeaturedTournaments {
      .TournamentsWrapper {
         display: block;

         .Tournament {
            width: 600px;
            margin: 0 auto;
            margin-bottom: 20px;
         }

         // .Tournament:last-child{
         //    margin-right: 0;
         // }
      }
   }
}

// @media screen and (max-width: 1244px) {
//    .mainImgBg {
//       h1.mainH1 {
//          zoom: 0.75;
//       }
//    }
// }

@media screen and (max-width: 1024px) {
   .faqListWrapper:last-child {
      margin-bottom: 70px;
   }
   .SprtsToken {
      flex-direction: column;

      .Col1 {
         width: 100%;
         order: 2;

         img {
            display: block;
            max-width: 311px !important;
            margin: 0 auto;
         }
      }

      .Col2 {
         padding: 0 20px;

         a {
            width: fit-content;
         }
      }
   }
   .mainImgBg {
      p {
         font-size: 20px;
         line-height: 25px;
      }

      .startButtonWrapper {
         a, button {
            height: 50px;
            padding: 16px 15px;
         }

         button {
            padding: 14px 15px;
         }
      }

      .MainVideoCol {
         align-items: center;

         video {
            width: 90% !important;
         }
      }
   }
   .SprtsTokenRowWrapper {
      margin-top: 3rem;
   }
}


@media screen and (max-width: 1200px) {
   .faqIMG {
      width: 80% !important;
   }

   .mainImgBg {
      background-size: auto 80%;
      padding-bottom: 50px !important;
   }

   .blueFlash2 {
      display: none;
   }
   .Stepwizard {

      > ul {
         width: fit-content !important;
         min-width: 800px;
         scale: .7;

         > li {
            // float: left;
            width: 200px !important;
         }
      }
   }

   .JoinComunityRowWrapper {
      .joinComunitySection {
         margin-top: 0;
      }
   }
}

@media screen and (max-width: 1200px) and (min-width: 992px) {
   .mainHeaderH1 {
      font-size: 50px;
      line-height: 50px;
      text-align: left;
      margin-top: 10px;
   }

   .section1Container {
      height: auto;
   }

   .ellipse29Img,
   .ellipse.e33,
   .ellipse.e29,
   .ellipse.e25,
   .ellipse.e26,
   .ellipse.e27 {
      display: none; //ellipse.e28
   }
   .transCard {
      margin-bottom: 25px;
   }
   .marginTop42 {
      margin-top: 20px;
   }
   .homeContainer {
      .mainImgBg {
         background-size: auto 70% !important;
      }
   }
}

@media screen and (max-width: 992px) {
   .homeContainer {
      .mainImgBg {
         background-size: auto 62% !important;
      }
   }
   .mainH1Wrapper {
      p {
         font-size: 20px;
      }
   }
   .RoadMap {
      width: 90% !important;
      margin: 0 auto;

      .container {
         width: 100% !important;
         padding: 10px;
         margin: 0 auto;
         margin-bottom: 20px !important;
      }


      .container::before {
         display: none;
      }

      .container::after {
         display: none;
      }

      .left::after, .right::after {
         all: unset;
         display: none;
      }

      .right, .left {
         all: unset !important;
      }

      .content {
         margin-bottom: 20px !important;
      }
   }
   .mainImgBg {
      h1 {
         text-align: center;
         font-size: 36px !important;

         span {
            text-align: center;
            margin-bottom: 10px !important;
         }
      }

      p {
         text-align: center;
      }

      .startButtonWrapper {
         height: 82px;
         width: fit-content;
         margin: 0 auto;
         margin-bottom: 20px;
      }

      .MainVideoCol {
         justify-content: center;

         video {
            width: 80% !important;
         }
      }
   }
}

@media screen and (max-width: 876px) {
   .Stepwizard {

      > ul {
         display: block !important;
         min-width: 240px;
         scale: 1;

         > li {
            width: 240px !important;
            margin: 0 auto;
            margin-bottom: 20px;
         }

         > li:before {
            display: none !important;
         }
      }
   }
   .Testimonials .Testimonial .TestimonialWrapper {
      margin: 30px;
   }
   .StepwizardRowWrapper {
      background-image: none;
   }
   h1 {
      .blueFlash {
         display: none;
      }
   }
}

@media screen and (max-width: 992px) and (min-width: 768px) {

   .section1Container {
      height: auto;
   }

   .ellipse.e31,
   .ellipse29Img,
   .ellipse.e33,
   .ellipse.e29,
   .ellipse.e25,
   .ellipse.e26,
   .ellipse.e27,
   .ellipse.e30 {
      display: none; //.ellipse.e28
   }
   .transCard {
      margin-bottom: 25px;
   }
   .marginTop42 {
      margin-top: 20px;
   }
   .profileTextTle {
      font-size: 22px;
      line-height: 26px;
      color: rgb(255, 255, 255);
      letter-spacing: 1px;
   }

   .profileText {
      font-size: 20px;
      line-height: 24px;
   }

   .profileTextSm {
      font-size: 16px;
      line-height: 20px;
      color: rgba(203, 207, 210, 1);
      letter-spacing: -0.1px;
   }

   .bigLogo {
      width: 300px;
      margin-bottom: 25px;
   }

   .mainHeaderH1 {
      font-size: 50px;
      line-height: 60px;
   }

   .mainHeaderH1 .white86 {
      font-size: 76px;
      line-height: 86px;
   }

   .mainHeaderH1Sub {
      font-size: 40px;
      line-height: 50px;
   }

   .mainHeaderH1Sub1 {
      font-size: 30px;
      line-height: 35px;
   }

   .nextTournamentWrapper {
      right: 3vw !important;
      zoom: .8;

      .countdown > div > span {
         font-size: 20px !important;
      }
   }

}

@media screen and (max-width: 768px) {

   .topline {
      background-image: url("https://storage.googleapis.com/meta11/serviceplatform/GradientTopBorder.webp");
      background-repeat: no-repeat;
      height: 2px;
      background-position: left 0%;
      background-size: auto;
   }
   .yellowBatBlur {
      left: 0px;
   }

   .FaqFontsP {
      font-size: 18px;
      font-family: "OSRegular", Helvetica, Arial, sans-serif;
      font-weight: 100;
      color: #95a6b7;
      line-height: 24px;
      text-align: left;
      text-justify: inter-word;
      margin-top: 12px;
   }

   .FaqFontsOl {
      font-size: 18px;
      font-family: "OSRegular", Helvetica, Arial, sans-serif;
      font-weight: 100;
      color: #95a6b7;
      line-height: 24px;
      text-align: justify;
      text-justify: inter-word;

      li {
         margin-top: 12px;
      }

   }

   .JoinComunityRowWrapper {
      .joinComunitySection {
         padding: 75px 50px 75px 50px;
      }
   }


   .profileTextTle {
      font-size: 20px;
      line-height: 28px;
      color: rgb(255, 255, 255);
      letter-spacing: 1px;
   }

   .profileText {
      font-size: 18px;
      line-height: 22px;
   }

   .profileTextSm {
      font-size: 14px;
      line-height: 20px;
      color: rgba(203, 207, 210, 1);
      letter-spacing: -0.1px;
   }

   .bigLogo {
      width: 250px;
      margin-bottom: 25px;
   }

   .mainHeaderH1 {
      font-size: 40px;
      line-height: 50px;
   }

   .mainHeaderH1 .white86 {
      font-size: 56px;
      line-height: 56px;
   }

   .mainHeaderH1Sub {
      font-size: 26px;
      line-height: 30px;
   }

   .mainHeaderH1Sub1 {
      font-size: 25px;
      line-height: 30px;
   }

   .mainHeaderH1 {
      font-size: 50px;
      line-height: 50px;
      text-align: left;
      margin-top: 10px;
   }

   .section1Container {
      height: auto;
   }

   .ellipse.e31,
   .ellipse29Img,
   .ellipse.e33,
   .ellipse.e29,
   .ellipse.e25,
   .ellipse.e27,
   .ellipse.e30 {
      display: none; // .ellipse.e28
   }

   .ellipse.e26 {
      width: 100%;
      display: inline-block;
   }
   .transCard {
      margin-bottom: 25px;
   }
   .marginTop42 {
      margin-top: 20px;
   }
   .ellipse34 {
      position: absolute;
      width: 100%;
      height: 600px;
      background: radial-gradient(
            51.65% 28.65% at 50% 47%,
            #14d0e6 0%,
            rgba(20, 208, 230, 0) 100%
      );
   }

   .ellipse35 {
      display: none;
   }

   .faqIMG {
      width: 100% !important;
   }

   .mainImgBg {
      background-image: none;
      padding-bottom: 0px !important;
   }

   .mainHeaderH1.text20 {
      font-size: 14px;
      line-height: 18px;
   }
   .yellowGradient02 {
      .yellowGradient02 {
         width: 100%;
         height: 500px;
      }

   }

   .nextTournamentWrapper {
      left: 0 !important;
      right: 0 !important;
   }
   .kumasContent {
      h2 {
         font-size: 28px;
      }

      p {
         font-size: 18px;
      }
   }
   .blueFlash {
      left: -114px !important;
      top: -77px !important;
   }
   .FeaturedTournaments {
      .TournamentsWrapper {
         .Tournament {
            .lightGreenButton {
               padding: 17px 15px !important;
               position: relative;

               .LockIcon {
                  margin-top: -7px;
                  margin-left: -9px;
                  margin-right: 10px;
               }
            }
         }
      }

      .ViewAllTournaments {
         display: block;
         width: fit-content;

         a {
            display: block;
            width: fit-content;
         }
      }
   }
   .RoadMap {
      .container {
         width: 100%;
         padding: 10px;
      }

      /* Make sure that all arrows are pointing leftwards */
      .container::before {
         display: none;
      }

      .container::after {
         display: none;
      }

      /* Make sure all circles are at the same spot */
      .left::after, .right::after {
         left: 15px;
      }

      /* Make all right containers behave like the left ones */
      .right {
         left: 0%;
      }

   }

   .mainImgBg {
      h1 {
         text-align: center;
         font-size: 38px !important;

         span {
            text-align: center;
            margin-bottom: 10px !important;
         }
      }

      p {
         text-align: center;
      }

      .startButtonWrapper {
         height: 82px;
         width: fit-content;
         margin: 0 auto;
         margin-bottom: 20px;

         a, button {
            width: fit-content;
         }
      }

      .MainVideoCol {
         justify-content: center;

         video {
            width: 80% !important;
         }
      }
   }
}

@media only screen and (max-width: 676px) {
   .FeaturedTournaments {
      padding: 0;

      .TournamentsWrapper {
         padding: 0;
         margin: 0;
         width: 100%;

         .Tournament {
            width: unset !important;
            min-width: 100%;
            height: unset !important;
            background-size: cover !important;
            background-position: center !important;
            background-repeat: no-repeat !important;
            min-height: 260px !important;

            .Row1 {
               display: block;

               .nextTournamentWrapper {
                  width: 100% !important;

                  .countdown {
                     scale: unset !important;
                     display: block;
                     float: left;
                     width: fit-content;
                     padding: 0;
                  }
               }
            }

            .Row2 {
               // display: block;
               display: flex;
               width: 100%;
            }

            h3 {
               width: 98%;
               max-width: 95%;
               white-space: nowrap;
               overflow: hidden;
               text-overflow: ellipsis;
               font-size: 22px;
               padding-top: 6px;
            }

            button {
               width: fit-content;
               font-size: 12px !important;

               span {
                  font-size: 12px !important;
               }
            }

            .creditsWrapper {
               .credits {
                  padding-top: 7.5px;
               }
            }

            .FooterRow {
               left: 20px;

               button {
                  width: fit-content;
                  font-size: 12px !important;
                  padding: 17px 15px !important;

                  span {
                     font-size: 12px !important;
                  }
               }
            }

            > div {
               min-height: 212px;
            }
         }

         .LiveTournament {
            .liveTagRow {
               position: relative;
               width: 33px;
               height: 33px;

               .PlayNowLiveTagContainer {
                  top: -66px;
                  left: -73px;
                  width: 104px;
                  scale: .95;
               }
            }

            .liveTag {
               position: absolute;
               right: 0;
               top: -65px;
            }

            .TwoPlayers {
               max-width: 240px;
            }
         }

         .ScheduleTournament {
            .FooterRow {
               // left: -20px !important;
               .BtnRow {
                  position: absolute;
                  bottom: 0;
                  right: 0;
               }
            }
         }

         .FreeTournament {
            .FooterRow {
               left: 20px !important;
            }
         }
      }

   }
}

@media only screen and (max-width: 576px) {
   .container {
      max-width: 100%;
   }

   p {
      font-size: 16px;
      line-height: 20px;
   }
   .mainHeaderH1 {
      font-size: 26px;
      line-height: 30px;
      text-align: left;
      margin-top: 10px;
   }

   .blueFlash {
      display: block;
      left: -108px !important;
      top: -77px !important;
      background-size: 60%;
      background-position: center;
      z-index: 0;
   }

   .section1Container {
      height: auto;
   }

   .ellipse.e31,
   .ellipse29Img,
   .ellipse.e33,
   .ellipse.e29,
   .ellipse.e25,
   .ellipse.e27,
   .ellipse.e30 {
      display: none; //, .ellipse.e28
   }
   .ellipse.e26 {
      display: inline-block;
      width: 100%;
   }

   .transCard {
      margin-bottom: 25px;
   }
   .marginTop42 {
      margin-top: 20px;
   }
   .textCenter {
      text-align: center;
   }

   .faqHeading {
      margin-top: 15px;
      position: relative;
   }

   .FaqFonts {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      /*padding-right: 50px;*/
   }

   .openedFaq {
      position: absolute;
      right: 4px;
      top: 0;
   }

   .navBarLoginButtonShow {
      right: 0;
   }

   .LoginButton {
      height: 47px;
      padding: 14px 20px;
   }
   h1.mainH1 {
      zoom: 1;
      font-size: 36px !important;
   }
   .nextTournamentWrapper {
      left: 0 !important;
      right: 0 !important;
      zoom: .8;
      width: 80% !important;
   }
   .kumasContent {
      margin-bottom: 50px;

      h2 {
         font-size: 22px;
      }

      img {
         padding-bottom: 20px;
      }

      > div {
         padding: 10px !important;
      }

      > div > div:nth-child(1) {
         padding: 0 !important;
      }
   }

   .homeContainer {
      .section1Container {
         .mainH1Wrapper {
            h1 {
               font-size: 53px !important;
               line-height: 60px;
               max-width: 100%;
               zoom: 0.5 !important;

               > div.timeNontText {
                  font-size: 45px !important;
                  -webkit-text-size-adjust: 50%;
                  line-height: normal !important;
                  display: block;
                  width: 100%;

                  > label {
                     font-size: 32px !important;
                     font-weight: 600;
                  }
               }
            }
         }

         .timeNontText {

         }
      }

      .MainVideoCol {
         video {
            width: 100% !important;
         }
      }

      .startButtonWrapper {
         a, button {
            display: flex;
            height: 48px;
            width: fit-content;
            padding-left: 10px;
            padding-right: 10px;
            align-items: center;
         }

         > a {
            padding: 14px 10px;
         }
      }
   }

   .FeaturedTournaments {
      .TournamentsWrapper {
         .Tournament {
            .Row1 {
               .nextTournamentWrapper {
                  .countdown {

                  }
               }
            }

            .Row2 {
               display: block;

               .BtnRow {
                  justify-content: left;
               }
            }

            h3 {

            }

            .TwoPlayersWrapper {
               position: absolute;
               top: -55px;

               .TwoPlayers {
                  max-width: unset;
                  width: fit-content;
                  display: block;
               }
            }

            > div {
               position: relative;
               overflow: hidden;
               display: block;
               min-height: 235px;

               > .Row2 {
                  position: absolute;
                  bottom: 0;
                  padding-left: 5px;
               }
            }
         }
      }

      .ScheduleTournament {
         .FooterRow {
            .BtnRow {
               right: unset !important;
               left: 8px;
            }
         }
      }
   }
   .mainH1Wrapper {
      p {
         font-size: 18px;
         line-height: 22px;
      }
   }

   .nextTournamentWrapper {
      position: unset !important;
   }
   .HowItWorks {
      p {
         text-align: center;
         font-size: 18px;
      }
   }
   .JoinComunityRowWrapper {
      > div {
         > div {
            > div {
               max-width: 95% !important;
               margin: 0 auto !important;
            }
         }
      }

      .ellipse.eMulticolorFull {
         left: -52px;
         top: 109px;
         transform: rotate(281deg);
      }

      .ellipse.e28 {
         top: unset !important;
         display: block;
         opacity: 1;
         width: 500px !important;
         height: 500px !important;
         left: 0;
         position: absolute;
         top: -113px !important;
         z-index: -1;
      }
   }
   .ellipse.e37.Faq {
      width: 800px;
      height: 800px;
      opacity: .3;
      position: absolute;
      left: 93px;
      top: 20px;
      // transform: rotate(281deg);
      background: radial-gradient(32.65% 32.65% at 50% 50%, #04F0FF 0%, rgba(20, 208, 230, 0) 100%);
   }
   .redManEllipsRed.Faq {
      right: 0;
      width: 400px !important;
      height: 400px !important;
      bottom: 140px;
      opacity: .5;
      position: absolute;

      background: radial-gradient(32.65% 32.65% at 100% 50%, #e61479 0%, rgba(230, 20, 20, 0) 100%);
   }
   .RoadMapRowWrapper {
      .ellipse.ePinkFull {
         left: unset;
         right: -287px;
         top: -568px;
         width: 500px;
         height: 500px;
         position: absolute;
      }

      > div {
         padding-top: 0 !important;
         margin-top: 0 !important;
      }
   }
   .SprtsTokenRowWrapper {
      .ellipse.eMulticolorFull {
         top: unset;
         right: -15vw;
         opacity: 1;
         bottom: -10vh;
      }
   }
   .startButtonWrapper {
      > a {
         min-width: fit-content !important;
         padding: 16px 18px;
         height: 48px;
      }

      * {
         zoom: .9;
      }
   }
}

@media only screen and (max-width: 376px) {

   .FeaturedTournaments .TournamentsWrapper {
      .liveTagRow {
         scale: .95;
         margin-right: -10px;
         margin-top: -7px;
      }

      .LiveTournament {
         .liveTagRow {
            .PlayNowLiveTagContainer {
               top: -66px;
               left: -85px;
               width: 104px;
               scale: .95;
            }
         }
      }

      .Tournament {
         padding: 16px;

         .BtnRow {
            gap: 5px !important;

            button {
               min-width: 110px !important;
               padding: 14px 8px !important;
            }
         }

      }

      .Row2 {
         justify-content: center !important;
      }
   }
   .homeContainer {
      .startButtonWrapper {
         zoom: .95;
      }
   }
}

/*********  iPad layouts *********/
@media only screen and (device-width: 768px) {
   /* For general iPad layouts */
   .homeContainer {
      .aiAthletesRow {
         .heading2.gradient-color {
            text-shadow: 0 0 0 rgba(20, 208, 229, 1);
         }
      }

      .faqTitleWrapper {
         .heading2.gradient-color {
            text-shadow: 0 0 0 rgba(20, 208, 229, 1);
         }
      }
   }
   .navItem .active {
      color: #17ea85 !important;
      background: unset;
      -webkit-text-fill-color: #17ea85 !important;
   }
   .navbar-expand-lg .navbar-collapse {
      justify-content: flex-end;
   }
}

/*********  iphone 15 *********/
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
   .faqListContainer {
      .faqListWrapper {
         border: none;

         background: linear-gradient(to right, rgba(38, 200, 103, 0) 0%, rgba(38, 200, 103, 1) 49%, rgba(38, 200, 103, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

         padding-top: 0;
         padding-bottom: 0;
         margin-top: 0px;
         margin-bottom: 10px;
         padding-bottom: 1px;

         > div {
            background-color: #010d14;
            padding: 10px;
         }

         > div:nth-child(1) {
            margin-top: 0px;
            padding: 20px 10px;
         }
      }
   }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
   /* For portrait layouts only */
   .navItem .active {
      color: #17ea85 !important;
      background: unset;
      -webkit-text-fill-color: #17ea85 !important;
   }
   .navbar-expand-lg .navbar-collapse {
      justify-content: flex-end;
   }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
   /* For landscape layouts only */
   .navItem .active {
      color: #17ea85 !important;
      background: unset;
      -webkit-text-fill-color: #17ea85 !important;
   }
   .navbar-expand-lg .navbar-collapse {
      justify-content: flex-end;
   }
}

/* Portrait */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {

   h1.mainH1 {
      .blueFlash {
         top: -51px !important;
      }
   }
}


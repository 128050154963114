.BlackSkBtn text {

   background-image: linear-gradient(0deg, #FAFF01, #39FF96);
   background-clip: text;
   color: transparent;
   font-family: "ObjectSans-Heavy";
   font-size: 16px;
   line-height: 18px;
}

.BlackSkBtn {
   background-color: #0E2029;
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
   border-radius: 4px;
   transform: skew(-10deg, 0deg);
   border: 1px solid rgba(0, 255, 194, 0.09);

   /* identical to box height, or 129% */
   width: 48%;
   text-align: center;
   letter-spacing: -0.01em;
   text-transform: uppercase;
   padding: 14px 27px;
   font-weight: 700;
   //height: 48px;
}

.BlackSkBtn:hover {
   background: #1A4152;
}

.BlackSkBtn:visited {
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
   cursor: not-allowed;
}

.BlackSkBtn:disabled,
.BlackSkBtn[disabled] {
   background: #0E2029;
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
   backdrop-filter: blur(45px);
}

.smallBtn {
   background-color: #000000;
   border: 2px solid #97FCE9;
   padding-top: 10px;
   padding-right: 20px;
   padding-bottom: 10px;
   padding-left: 20px;
   color: #FFFFFF;
   font-size: 14px;
   border-radius: 20px;

}

.smallBtn2:hover {
   background-color: #000000;
   padding-top: 10px;
   padding-right: 20px;
   padding-bottom: 10px;
   padding-left: 20px;
   color: #FFFFFF;
   font-size: 17px;
   border: 3px solid #97FCE9;
   font-family: 'Rubik-Medium';
}

.smallBtn2 {
   background-image: -webkit-linear-gradient(0deg, rgba(25, 234, 23, 1.00) 0%, rgba(20, 208, 230, 1.00) 100%);
   background-image: -moz-linear-gradient(0deg, rgba(25, 234, 23, 1.00) 0%, rgba(20, 208, 230, 1.00) 100%);
   background-image: -o-linear-gradient(0deg, rgba(25, 234, 23, 1.00) 0%, rgba(20, 208, 230, 1.00) 100%);
   background-image: linear-gradient(90deg, rgba(25, 234, 23, 1.00) 0%, rgba(20, 208, 230, 1.00) 100%);
   font-family: 'Rubik-Medium';
   color: #000000;
   padding-top: 10px;
   padding-right: 20px;
   padding-bottom: 10px;
   padding-left: 20px;
   font-size: 17px;
   border-radius: 20px;
   border: 3px solid hsla(0, 0%, 100%, 1.00);
   line-height: 19px;
}

.logoutBtn:hover {
   color: #000000 !important;
   background-color: #ffffff;
}

.logoutBtn:focus {
   color: #000000 !important;
   background-color: #ffffff;
}

.disabledBtn {
   background-color: #000000;
   border: 2px solid #97FCE9;
   padding-top: 10px;
   padding-right: 20px;
   padding-bottom: 10px;
   padding-left: 20px;
   font-family: 'Rubik-Medium';
   color: #FFFFFF;
   padding-top: 10px;
   padding-right: 20px;
   padding-bottom: 10px;
   padding-left: 20px;
   font-size: 17px;
   border-radius: 20px;
   border: 3px solid hsla(0, 0%, 100%, 1.00);
   line-height: 19px;
}

@media only screen and (max-width: 552px) {
   .BlackSkBtn {
      width: 47%;
      padding: 10px 27px;
      height: 46px;

   }

   .BlackSkBtn .text {
      font-size: 14px;

   }
}

@media (max-width: 330px) {
   .BlackSkBtn text {
      font-size: 14px;
      line-height: 16px;
   }
   .BlackSkBtn {
      width: 47%;
      padding: 10px 20px;
      height: 46px;

   }

}

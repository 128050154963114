@import '../utilities/variables';
@import '../utilities/mixins';

.lightGreenButton {
   @include LightButtonAtributes($buttonColor1, $buttonColor2, $BtnboxShadow, $BtnBorderRadius, $Btntransform, $Btnborder, $BtnfontSize, $BtnlineHeight, $BtntextTransform, $BtnPadding, $BtnColor);
   @include css3Anim();
   font-family: $buttonTextFont;
   text-align: center;
   letter-spacing: -0.01em;
   display: inline-block;
   width: fit-content;

}

.lightGreenButton:hover {
   background: linear-gradient(95.34deg, $buttonColor2 6.28%, $buttonColor1 111.13%);
   text-decoration: none;
}

.GrayButton {
   @include GrayButtonAtributes($grayButtonColor1, $grayButtonColor2, $buttonColor1, $buttonColor2, $BtnBorderRadius, $Btntransform, $BtntextTransform, $BtnPaddingGray);
   @include css3Anim();
   border: 1px solid $buttonColor1;
   background-clip: text;
   color: transparent;

   text {
      @include GrayButtonText($buttonColor1, $buttonColor2, $BtnfontSize, $BtnlineHeight);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
      font-family: $buttonTextFont;
   }
}

.GrayButton:hover {
   background: $grayButtonHover;
}

.cardProgressBar {
   margin-top: 16px;
   background: rgb(21, 45, 57) !important;
   border: 0;
   padding: 0;
   border-radius: 0 !important;
   height: 4px !important;
   font-weight: bold;
   display: inline-block !important;
   width: 100%;

   .progress-bar {
      background: linear-gradient(to right, #19EA17 0%, #14D0E6 100%) !important;
      border: 0 !important;
      height: 4px;
      border-radius: 0 !important;
   }
}

.blackBtn {
   background: #111E33;
   border: 1px solid rgba(255, 255, 255, 0.06);
   border-radius: 2px;
   color: #fff;
   padding: 10px 20px;
   font-size: 16px;
   line-height: 20px;
   letter-spacing: -0.01em;
   color: #FFFFFF;
   margin-bottom: 15px;
   text-transform: uppercase;
}

.blackBtn text {
   font-size: 18px;
}

.blackBtn svg {
   margin-top: -3px;
   position: relative;
   left: -9px;
}

.blackBtn:hover {
   background: #203455;
}

.roundBtn {
   border-radius: 90px !important;
   padding: 12px 16px;
   color: #95A6B7;
}

.roundBtn:hover {
   border: 1px solid #FAFF00;
   background: #111E33;
   color: #fff;
}

.selectedBtn {
   border: 3px solid;
   border-color: rgba(0, 255, 194, 1);
   background: #111E33;
   color: #fff;
}

/* @font-face {
    font-family: 'Solina-ExtraBoldItalic';
    src:  url('./fonts/Solina-ExtraBoldItalic.eot') format('eot'),
          url('./fonts/Solina-ExtraBoldItalic.woff') format('woff'),
          url('./fonts/Solina-ExtraBoldItalic.woff2') format('woff2');
          font-style: normal;
}
@font-face {
    font-family: 'Solina-Light';
    src:  url('./fonts/Solina-Light.eot') format('eot'),
          url('./fonts/Solina-Light.woff') format('woff'),
          url('./fonts/Solina-Light.woff2') format('woff2');
          font-style: normal;
} */
@font-face {
   font-family: 'OSHeavy';
   src: url('../fonts/ObjectSans-Heavy.eot') format('woff2'),
   url('../fonts/ObjectSans-Heavy.otf') format('otf'),
   url('../fonts/ObjectSans-Heavy.woff') format('woff'),
   url('../fonts/ObjectSans-Heavy.woff') format('woff');
   font-style: normal;
}

@font-face {
   font-family: 'OSHeavySlanted';
   src: url('../fonts/ObjectSans-HeavySlanted.eot') format('woff2'),
   url('../fonts/ObjectSans-HeavySlanted.otf') format('otf'),
   url('../fonts/ObjectSans-HeavySlanted.woff') format('woff'),
   url('../fonts/ObjectSans-HeavySlanted.woff') format('woff');
   font-style: normal;
}

@font-face {
   font-family: 'OSRegular';
   src: url('../fonts/ObjectSans-Regular.eot') format('woff2'),
   url('../fonts/ObjectSans-Regular.otf') format('otf'),
   url('../fonts/ObjectSans-Regular.woff') format('woff'),
   url('../fonts/ObjectSans-Regular.woff') format('woff');
   font-style: normal;
}

@font-face {
   font-family: 'OSSlanted';
   src: url('../fonts/ObjectSans-Slanted.eot') format('woff2'),
   url('../fonts/ObjectSans-Slanted.otf') format('otf'),
   url('../fonts/ObjectSans-Slanted.woff') format('woff'),
   url('../fonts/ObjectSans-Slanted.woff') format('woff');
   font-style: normal;
}

@font-face {
   font-family: 'Montserrat-ExtraBoldItalic';
   src: url('../fonts/montserrat/Montserrat-ExtraBoldItalic.eot') format('eot'),
   url('../fonts/montserrat/Montserrat-ExtraBoldItalic.otf') format('otf'),
   url('../fonts/montserrat/Montserrat-ExtraBoldItalic.woff') format('woff'),
   url('../fonts/montserrat/Montserrat-ExtraBoldItalic.woff2') format('woff2');
   font-style: normal;
}

@font-face {
   font-family: 'Montserrat-Regular';
   src: url('../fonts/montserrat/Montserrat-Regular.eot') format('eot'),
   url('../fonts/montserrat/Montserrat-Regular.otf') format('otf'),
   url('../fonts/montserrat/Montserrat-Regular.woff') format('woff'),
   url('../fonts/montserrat/Montserrat-Regular.woff2') format('woff2');
   font-style: normal;
}

@font-face {
   font-family: 'SolinaLight';
   src: url('../fonts/Solina-Light.eot') format('eot'),
   url('../fonts/Solina-Light.woff') format('woff');
   font-style: normal;
}

@font-face {
   font-family: 'SolinaExtraBoldItalic';
   src: url('../fonts/Solina-ExtraBoldItalic.eot') format('eot'),
   url('../fonts/Solina-ExtraBoldItalic.woff') format('woff');
   font-style: normal;
}

@font-face {
   font-family: 'Khand';
   src: url('../fonts/Khand-Bold.eot') format('eot'),
   url('../fonts/Khand-Bold.woff') format('woff');
   font-style: bold;
}

/*Buttons*/
.lightGreenButton {
   background: linear-gradient(95.34deg, #FAFF00 6.28%, #00FFC2 111.13%);
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
   border-radius: 2px;
   transform: skew(-10deg, 0deg);
   border: 1px solid #00FFC2;
   font-family: "OSHeavySlanted", Helvetica, Arial, sans-serif;
   font-size: 16px;
   line-height: 18px;
   text-align: center;
   letter-spacing: -0.01em;
   text-transform: uppercase;
   padding: 17px 20px;
   font-weight: 700;
   display: inline-block;
   width: fit-content;
   -webkit-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
   transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
   color: #000000 !important;
}

.lightGreenButton a {
   color: #000000 !important;
}

.lightGreenButton:hover {
   background: linear-gradient(95.34deg, #00FFC2 6.28%, #FAFF00 111.13%);
}

.grayButton {
   background: linear-gradient(#0E2029, #0E2029) padding-box, linear-gradient(0deg, #39FF96, #FAFF01) border-box;
   border-radius: 2px;
   transform: skew(-10deg, 0deg);
   text-align: center;
   letter-spacing: -0.01em;
   text-transform: uppercase;
   padding: 17px 20px;
   font-weight: 700;
   border: 1px solid transparent;
}

.grayButton text {
   background-image: linear-gradient(0deg, #FAFF01, #39FF96);
   -webkit-background-clip: text;
   background-clip: text;
   color: transparent;
   font-family: "ObjectSans-Heavy";
   font-size: 16px;
   line-height: 18px;
}

.grayButton:hover {
   background: #1A4152;
}

/*Main Heading*/
.mainHeaderH1 {
   font-family: 'Montserrat-ExtraBoldItalic';
   font-size: 60px;
   line-height: 70px;
   letter-spacing: 2%;
   text-transform: uppercase;
   background: linear-gradient(89.7deg, #17EA85 2.27%, #14D0E6 23.6%, #17EA85 44.41%, #14D0E6 64.17%, #17EA85 82.99%, #14D0E6 99.74%);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   background-clip: text;
   text-fill-color: transparent;
   /* text-shadow: 0px 0px 8px rgba(20, 208, 229, 0.56);*/
   margin-top: 50px;
   text-transform: uppercase;
}

/**/
body {
   overflow: unset !important;
   overflow: hidden !important;
   margin: 0 auto;
}

html {
   overflow: unset !important;
   overflow-y: auto;
   overflow-x: hidden !important;
   margin: 0 auto;
}

.relative-pos {
   position: relative;
}

.margin-right-min-108 {
   margin-right: -108px;
}

p {
   font-family: 'OSRegular';
   font-style: normal;
   font-weight: 400;
   font-size: 22px;
   line-height: 28px;
   letter-spacing: 0.04em;
}

.gray-text {
   color: rgba(203, 207, 210, 1);
}

.font24 {
   font-size: 24px;
}

.font18 {
   font-size: 18px;
   line-height: 24px;
}

.font16 {
   font-size: 16px;
}

.font14 {
   font-size: 14px;
   line-height: 18px;
}

/*bs change*/
.page-link {
   position: relative;
   display: block;
   font-size: 16px !important;
   color: #95A6B7 !important;
   text-decoration: none;
   transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
   background: #0000;
   border: 1px solid #1F2A36;
   box-shadow: 10px 22px 47px rgb(0 0 0 / 4%);
   backdrop-filter: blur(20px);
   border-radius: 0;
   padding: 9px 10px;
   width: 42px;
   text-align: center;
   height: 42px;
   margin-bottom: 6px;
}

.active > .page-link, .page-link.active {
   z-index: 3;
   color: #000 !important;
   background-color: #17EA85;
   border-color: #17EA85;
   font-weight: 700 !important;
}

.page-link:hover, .page-link:focus {
   z-index: 2;
   color: #000 !important;
   background-color: #17EA85;
   border-color: #17EA85;
   font-weight: 700 !important;
}

.page-item:not(:first-child:last-child) .page-link {
   margin-left: -1px;
   border: 0;
   border-radius: 3px;
   color: #ffffff !important;
}

.page-item:first-child .page-link, .page-item:last-child .page-link {
   background: linear-gradient(180deg, rgba(26, 38, 57, 0.5) 0%, rgba(11, 28, 53, 0.5) 100%);
   border: 1px solid #ffffff12 !important;
   box-shadow: 10px 22px 47px rgba(0, 0, 0, 0.13);
   -webkit-backdrop-filter: blur(20px);
   backdrop-filter: blur(20px);
   border-radius: 0;
   color: #fff;
}

.page-item:first-child .page-link:hover, .page-item:last-child .page-link:hover {
   color: #000;
   background-color: #17EA85;
   border-color: #17EA85;
}

.css3-anim {
   -webkit-transition-property: all;
   -webkit-transition-duration: 0.4s;
   -webkit-transition-timing-function: ease;
   transition-property: all;
   transition-duration: 0.4s;
   transition-timing-function: ease;
   -moz-transition-property: all;
   -moz-transition-duration: 0.4s;
   -moz-transition-timing-function: ease;
   -o-transition-property: all;
   -o-transition-duration: 0.4s;
   -o-transition-timing-function: ease;
   -webkit-animation-name: icons-anim;
   -webkit-animation-timing-function: ease;
   -webkit-animation-iteration-count: 1;
   -webkit-animation-duration: 0.8s;
   -webkit-transform-style: preserve-3d;
}

.Match_leaderBoardHigh__bv1Kt .blackTextStyle {
   color: #000 !important;
}

.pagination li a.disabled {
   opacity: 0.7;
}

.bottom-footer * {
   font-size: 16px;
}

@media (max-width: 1200px) {
   .bottom-footer * {
      font-size: 12px;
   }
}

@media screen and (max-width: 768px) {
   .lightGreenButton {
      font-size: 14px;
      padding: 10px 20px;
      line-height: 16px;
   }

   .GrayButton, .lightGreenButton {
      width: 46%;
      height: auto;
   }

   .GrayButton {
      padding: 10px 5px;
   }

   .GrayButton text {
      font-size: 14px;
      line-height: 12px;
   }

   .Sign-up-to-secure .GrayButton {
      width: 100px;
   }
}

@media screen and (max-width: 576px) {
   .lightGreenButton {
      font-size: 14px;
      padding: 10px 20px;
      line-height: 16px;
   }
}


@import '../../scss/utilities/typography';

.mainHeaderH1 {
   font-size: 52px;
   line-height: 58px;
   text-align: left;
   margin-top: 10px;
   font-family: "Montserrat";
   font-weight: 800;
   font-style: italic;
   letter-spacing: 0.02em;
   text-transform: uppercase;
   /* text-shadow: 0px 0px 8px rgba(20, 208, 229, 0.56);*/
   background: linear-gradient(89.7deg, #17EA85 2.27%, #14D0E6 23.6%, #17EA85 44.41%, #14D0E6 64.17%, #17EA85 82.99%, #14D0E6 99.74%);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   background-clip: text;
   text-fill-color: transparent;

}

@media only screen and (max-width: 1400px) {
   .mainHeaderH1 {
      font-size: 42px;
      line-height: 42px;
   }

   .mainHeaderH1 span .img-span {
      top: 47px;
      left: -112px;
   }

}

@media only screen and (max-width: 1400px) {
   .flexParent {
      column-count: 3;
   }

   .matchCards {
      column-count: 2;
   }
   .modal .modal-lg, .modal .modal-xl {
      --bs-modal-width: 90vw;
   }
   /*Home*/
}

@media screen and (max-width: 1200px) {
   .next-tournament-col {
      .next-tournament-wrapper {
         width: 400px;
         bottom: 3vw;
      }
   }
   .flexParent {
      column-count: 3;
   }

   .matchCards {
      column-count: 2;
   }
   /*Home*/
   /*Home*/
   .mainHeadingWrapper {
      position: relative;
      left: unset;
      width: auto;
   }
   .mainHeaderH1 {
      font-size: 52px;
      line-height: 58px;
      text-align: left;
      margin-top: 10px;
      font-family: 'SolinaExBI';
      letter-spacing: 0.02em;
      text-transform: uppercase;
      /*text-shadow: 0px 0px 8px rgba(20, 208, 229, 0.56);
      background: linear-gradient(89.7deg, #17EA85 2.27%, #14D0E6 23.6%, #17EA85 44.41%, #14D0E6 64.17%, #17EA85 82.99%, #14D0E6 99.74%);*/
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;

   }
   .subHeadingWrapper::before {
      display: none;
   }
   .subHeadingWrap {
      width: auto;
      float: left;
      position: relative;
      padding: 0 20px !important;
      text-align: center;
   }
   .mainImageWrapper {
      margin-top: 10px;
      position: relative;
      right: unset;
      width: auto;
   }
   .p18 {
      font-size: 16px;
   }
   .gradientSphear {
      display: block;
   }
   .transCard {
      text-align: center;
   }
   .transCard img {
      padding-bottom: 10px;
      width: 100px;
   }
   .neonRight {
      height: 400px;
      width: 185px;
      right: 0;
      bottom: 170px;
   }
   .neonLeft {

      height: 430px;
      width: 190px;
      left: 0;
      top: -170px;
      opacity: 1;
   }
   .sectionWrapper {
      width: 100%;
      display: inline-flex;
      flex-wrap: wrap;
   }
   .subMainHeaderH1Wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      text-align: left;
   }
   .mt129, .mt99 {
      margin-top: 20px !important;
   }
   .mt-50 {
      margin-top: 0;
   }
   .mt149 {
      margin-top: 45px !important;
   }
   .advertWrapper {
      flex-wrap: wrap;
   }
   .doubleSizeDiv, .singleSizeDiv {
      width: 100%;
   }
   .imgManGradient {
      bottom: 15px;
   }
   .section2Img {
      width: 100%;
      padding: 60px 10px 20px 10px;
   }
   .gray-text {
      text-align: left;
   }
   .design-section {
      text-align: center;
      margin-bottom: 30px;
   }

   .faqDetail {
      padding: 15px;
      text-align: center;
   }

   .fourcards, .threecards {
      width: calc(50% - 25px);
      margin-right: 25px;
   }

   .matches-radio-wrapper .searchtxt {
      width: auto;
      padding-right: 13px;
      padding-left: 10px;
   }
   .mainHeaderH1 span .img-span {
      top: -30px;
      left: -118px;
   }
   .topBgGradientGreen,
   .centerBgGradientRed,
   .rightBgGradientGreen {
      display: none !important;
   }

   .kumasContent {
      .transCard img {
         width: unset;
         width: 100%;
      }
   }
}

@media only screen and (min-width: 992px) {
   .NavigationBar_mL91__-pqO0 {
      margin-right: 10px;
   }
   .menuFooter {
      // display: none;
   }
   .mobile-footer-links {
      display: none;
   }
   .navbar > a > svg {
      display: none;
   }
   .MobileMenuFooter {
      background-color: #54e8f8;
      display: none !important;
   }

}

@media only screen and (max-width: 992px) {
   .next-tournament-col {
      .next-tournament-wrapper {
         h2 {
            font-weight: 700;
         }
      }
   }
   .mainHeaderH1 {

      text-align: center;
   }

   .flexParent {
      column-count: 2;
      text-align: center;
   }

   .matchCards {
      column-count: 2;
   }

   .container {
      width: 100%;
   }
   /*Home*/
   .uniformImgwrapper {
      position: relative;
      right: 0;
   }
   .uniformImgwrapper img {
      width: 100%;
   }
   body {
      overflow-x: hidden;
   }
   .matchCardInnerWrapper {
      border-radius: 0;

   }
   .f-matchCardInnerWrapper {
      margin-bottom: 0;
   }
   .matchCards {
      justify-content: space-between;
   }

   .fourcards, .threecards {
      width: calc(50% - 12.5px);
      margin-right: 0;
   }

   .gray-text {
      text-align: left;
   }

   .fc-wrapper .searchtxt {
      width: auto;
      padding-left: 0;
      padding-right: 0;
   }
   .MobileMenuFooter {
      // background-color: #36565a;
      position: fixed;
      width: 100%;
      height: 170px;
      bottom: 0;

      .uppeFooter {
         ul {
            width: 236px;
            position: fixed;
            bottom: 30px;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;

            li {
               > div {
                  width: 30px;
                  height: 30px;

                  a {
                     img {
                        width: 16px;
                        padding-top: 8px;
                     }
                  }
               }
            }
         }

         > div.row {
            div.col-12.col-xs-12.col-sm-12.col-md-8.col-lg-8.col-xl-8.col-xxl-8,
            div.col-12.col-xs-12.col-sm-12.col-md-4.col-lg-4.col-xl-4.col-xxl-4 {
               all: unset;
            }
         }

         .metalogoWrapper {
            bottom: 120px;
         }
      }

   }
   .OmniCreditsNavBarLi {
      order: 10;
   }
   .navbarShowWrapper .navCollapse {
      ul {
         li:last-child {
            > div {
               width: fit-content !important;
               // border: 1px solid #F7D14E !important;
               max-width: fit-content !important;
               margin: 0 auto !important;
            }
         }
      }
   }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
   .next-tournament-col {
      .next-tournament-wrapper {
         // zoom: .8;
      }
   }
   .start-button-wrapper {
      zoom: .9;
   }
   .container {
      max-width: 100%;
   }

   /*Home*/
   /*Home*/
   .mainHeadingWrapper {
      position: relative;
      left: unset;
      width: auto;
   }
   .mainHeaderH1 {

      text-align: left;
      margin-top: 10px;
   }
   .subHeadingWrapper::before {
      display: none;
   }
   .subHeadingWrap {
      width: auto;
      float: left;
      position: relative;
      padding: 0 20px !important;
      text-align: center;
   }
   .mainImageWrapper {
      margin-top: 10px;
      position: relative;
      right: unset;
      width: auto;
   }
   .p18 {
      font-size: 16px;
   }
   .gradientSphear {
      display: none;
   }
   .transCard {
      text-align: center;
   }
   .transCard img {
      padding-bottom: 10px;
      width: 100px;
   }
   .neonRight {
      height: 400px;
      width: 185px;
      right: 0;
      bottom: 170px;
   }
   .neonLeft {

      height: 430px;
      width: 190px;
      left: 0;
      top: -170px;
      opacity: 1;
   }
   .sectionWrapper {
      width: 100%;
      display: inline-flex;
      flex-wrap: wrap;
   }
   .matchCardInnerWrapper .teamDetail table thead.hideThead {
      display: none;
   }
   .subMainHeaderH1Wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      text-align: left;
   }
   .mt129, .mt99 {
      margin-top: 20px !important;
   }
   .mt-50 {
      margin-top: 0;
   }
   .mt149 {
      margin-top: 45px !important;
   }
   .advertWrapper {
      flex-wrap: wrap;
   }
   .doubleSizeDiv, .singleSizeDiv {
      width: 100%;
   }
   .imgManGradient {
      bottom: 15px;
   }
   .section2Img {
      width: 100%;
      padding: 60px 10px 20px 10px;
   }
   .gray-text {
      width: 100%;

   }
   .design-section {
      text-align: center;
      margin-bottom: 30px;
   }
   .faqDetail {
      padding: 15px;
      text-align: center;
   }
   .fourcards, .threecards {
      width: calc(50% - 12.5px);
      margin-right: 0;
   }

   .gray-text {
      text-align: left;
   }

   .fc-searctText {
      width: 100%;
      margin-bottom: 15px;
   }

   .fc-wrapper .searchtxt {
      width: auto;
      padding-left: 0;
      padding-right: 15px;
   }
   .fc-wrapper .playerSearch {
      width: 100%;
   }
   .fc-wrapper .community {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
   }

   .matches-radio-wrapper {
      padding-right: 15px;
   }
   .navbar-toggler {
      position: absolute;
      bottom: unset;
      top: 0;
      left: 0;
      z-index: 200;
   }
   .navbar-toggler:focus {
      outline: none;
   }
   .footerWrapper {
      justify-content: center;
   }
   .menuFooter {
      display: none;
   }
   .navbarShowWrapper .menuFooter {
      display: flex;
   }
   .footerWrapper {
      justify-content: center;
   }
   .navbar-toggler {
      position: absolute;
      bottom: unset;
      top: 0;
      left: 0;
      z-index: 200;
   }
   .navbarShowWrapper {
      height: 100vh;
      position: fixed;
      z-index: 200;
      left: 0;
      width: 100vw;
      display: block;
      background-color: #010E14;
      margin-right: 0 !important;
      overflow-x: auto;
   }
   .navbarShowWrapper .navbarBrand {
      text-align: center;
   }
   .navbarShowWrapper .navbarNav {
      width: 100vw;
      height: 100vh;
      overflow: auto;
   }
   .navCollapse {
      display: none;
   }
   .navbarShowWrapper .navCollapse {
      display: inline-block !important;
      position: relative;
      z-index: 19;

      ul {
         background-color: #010E14;

         li {
            > div {
               margin: 0 auto;
               background-color: #0a1724;
               border: solid 1px #1F2A36;
               border-radius: 6px;

               > ul {
                  background-color: transparent;
                  border: none;
                  margin-top: 0;
               }
            }

            > .SprtsBtnWrapper {
               all: unset !important;
            }
         }

      }

   }

   .menuFooter {
      display: block;
   }
   .navbarShowWrapper .menuFooter {
      display: inline-block;
      width: 100%;
      bottom: 0;
      margin-bottom: 0;
   }
   .navbar-toggler {
      position: absolute;
      bottom: unset;
      top: 0;
      left: 0;
      z-index: 200;
   }
   .selectWrapperRes {
      padding-right: 0px;
   }
   .OmniCreditsNavBarLi {
      order: 10;
   }
}

@media only screen and (max-width: 768px) {
   .next-tournament-col {
      position: fixed;
      bottom: 0;
      width: 100%;
      left: 0;
      z-index: 99;

      .next-tournament-wrapper {
         zoom: 1;
         width: 100%;
         height: 150px;
         display: grid;
         grid-template-columns: 35% 1fr;
         right: 0;
         bottom: 0;
         border-left: 0;
         border-right: 0;
         border-bottom: 0;

         h2 {
            text-align: left;
            margin-left: 20px;
            margin-top: 38px;
            font-size: 24px;
         }

         .countdown {
            margin-top: 20px;

            > div {
               .cercal {
                  > span {
                     font-size: $countdown-count-mobile;
                  }
               }
            }
         }
      }

      .timer-wrapper {
         margin-top: 11px;

         div {
            > svg {
               max-width: 80px;
               max-height: 84px;
            }
         }
      }
   }
   .menuFooter {
      padding-top: 0 !important;
      padding-bottom: 35px !important;
   }
   .metalogoWrapper {
      height: 50px !important;
   }
   .socialLinksWrapper {
      > div {
         margin-top: -40px !important;
      }
   }

}

@media only screen and (max-width: 768px) and (min-width: 552px) {
   .container {
      max-width: 100%;
      padding: 0 20px;
   }

   /*Home*/
   .mainHeadingWrapper {
      position: relative;
      left: unset;
      width: auto;
   }
   .mainHeaderH1 {
      font-size: 42px;
      line-height: 50px;
      text-align: left;
      margin-top: 10px;
   }

   .subHeadingWrap {
      width: auto;
      float: left;
      position: relative;
      padding: 0 20px !important;
      text-align: left;
   }
   .mainImageWrapper {
      margin-top: 10px;
      position: relative;
      right: unset;
      width: auto;
   }
   .p18 {
      font-size: 16px;
   }
   .gradientSphear {
      display: none;
   }
   .transCard {
      text-align: left;
      padding: 20px;
   }
   .transCard img {
      padding-bottom: 10px;
      width: 75px;
   }
   .neonRight {
      height: 400px;
      width: 185px;
      right: 0;
      bottom: 170px;
   }
   .neonLeft {

      height: 430px;
      width: 190px;
      left: 0;
      top: -170px;
      opacity: 1;
   }
   .sectionWrapper {
      width: 100%;
      display: inline-flex;
      flex-wrap: wrap;
   }
   .subMainHeaderH1Wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      text-align: left;
   }
   .mt129, .mt99 {
      margin-top: 20px !important;
   }
   .mt-50 {
      margin-top: 0;
   }
   .mt149 {
      margin-top: 45px !important;
   }
   .advertWrapper {
      flex-wrap: wrap;
   }
   .doubleSizeDiv, .singleSizeDiv {
      width: 100%;
   }
   .imgManGradient {
      bottom: 15px;
   }
   .section2Img {
      width: 100%;
      padding: 60px 10px 20px 10px;
   }
   .gray-text {
      width: 100%;
      text-align: left;
      font-size: 14px;
      line-height: 18px;
   }
   .joinText {
      text-align: center;
   }
   .joinText .gray-text {
      text-align: center;
   }
   .design-section {
      text-align: center;
      margin-bottom: 30px;
   }
   .faqDetail {
      padding: 0px;
      text-align: center;
   }

   .fourcards, .threecards {
      width: 100%;
      margin-right: 0;
   }

   .mainHeaderH1 span .img-span {
      top: -52px;
      left: -118px;
   }

   .fc-wrapper {
      padding-right: 0px;
      padding-left: 0px;
      margin-bottom: 15px;
   }

   .matches-radio-wrapper {
      padding-right: 15px;
      padding-left: 5px;
      margin-bottom: 0;
   }

   .fc-wrapper .searchtxt {
      width: 100%;
      margin-left: 0;
      margin-bottom: 0;
      width: 100%;
   }

   .justify-content-left {
      display: flex;
      justify-content: center;
   }
   .logo-section {
      text-align: center;
   }
   .social-section {
      justify-content: center;
      display: flex;
      margin-top: 120px;
   }
   .social {
      /*margin: 0 10px;*/
      justify-content: center;
      display: inline-flex;
   }
   .bottom-footer .row .gray-text {
      text-align: center;
   }
   .terms-section {
      text-align: center;
      position: absolute;
      top: -185px;
   }
   .privacy-section {
      text-align: center;
      position: absolute;
      top: -145px;
   }
   .bottomGradient {
      background: radial-gradient(44.65% 42.65% at 50% 97%, #04F0FF 0%, rgba(20, 208, 230, 0) 100%);
      width: 100%;
      height: 345px;
   }
   .metalogoWrapper {
      text-align: center;
   }
   .metalogoWrapper a {
      // margin-bottom: 65px;
      display: inline-block;
   }
   .socialWrapper {
      justify-content: center;
      display: flex;
   }
   .socialWrapperRow {
      .socialWrapper {
         justify-content: unset;
      }
   }
   .termsWrapper {
      position: absolute;
      top: 56px;
      left: 0;
      text-align: center;
   }
   .privacyWrapper {
      position: absolute;
      top: 92px;
      left: 0;
      text-align: center;
   }
   .playerImg {
      width: 100%;
   }
   .idCardWrapper {
      padding: 5px 0;
   }
   .idCardInnerWrapper {
      padding: 10px;
      height: 364.45px;
   }

   .mobile-card-drag-down {
      position: relative;
      bottom: -35px;
   }

   .gray-text {
      text-align: left;
   }

   .commThirdSection {
      margin-top: 185px !important;
      margin-bottom: 50px;
   }

   .fc-searctText {
      width: 100%;
   }

   .matches-radio-wrapper {
      justify-content: space-around;
   }

   .matches-radio-wrapper .radioButtonlbl {
      margin-bottom: 15px;
      WIDTH: auto;
   }

   .fc-searctText {
      width: 100%;
      margin-bottom: 15px;
   }

   .matches-radio-wrapper .searchtxt {
      margin-bottom: 15px;
      margin-right: 0;
      padding-left: 5px;
      padding-right: 5px;
   }
   .teamDetail {
      position: relative;
      display: inline-block;
      width: 100%;
   }
   .matchCardInnerWrapper .teamDetail {
      display: flex;
   }
   .team1scoreSection, .team2scoreSection {
      height: 83.5px;
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      float: right;
   }
   .team1Logo, .team2Logo {
      width: 75px;
      float: left;
   }
   .team1scoreSection a, .liveScore .team2scoreSection a {
      position: absolute;
      left: 110px;
      display: flex;
      width: 153px;
      min-height: 83.5px;
      align-items: center;
   }
   .liveScore .team1scoreSection a, .liveScore .team2scoreSection a {
      left: 80px;
      width: 120px;
   }
   .team1scoreSection a .team1Name, .team2scoreSection a .team2Name {
      padding: 10px;
      width: 120px;
      margin-top: 0 !important;
   }
   .team1Score, .team2Score {
      font-size: 24px;
      line-height: 30px;
   }
   .team1Name, .team2Name {
      padding: 10px;
   }
   .footerWrapper {
      justify-content: center;
   }
   .navbar .menuFooter {
      display: flex;
   }
   .footerWrapper {
      justify-content: center;
   }
   .navbar-toggler {
      position: absolute;
      bottom: unset;
      top: 0;
      left: 0;
      z-index: 200;
   }
   .navbarShowWrapper {
      height: 100vh;
      position: fixed;
      z-index: 200;
      left: 0;
      width: 100vw;
      display: block;
      background-color: #010E14;
      margin-right: 0 !important;
      overflow-x: auto;
   }
   .navbarShowWrapper .navbarBrand {
      text-align: center;
   }
   .navbarShowWrapper .navbarNav {
      width: 100vw;
      height: 100vh;
      overflow: auto;
   }
   .navCollapse {
      display: none;
   }
   .navbarShowWrapper .navCollapse {
      display: inline-block !important;
      position: relative;
      z-index: 19;
   }
   .menuFooter {
      display: block;
   }
   .navbarShowWrapper .menuFooter {
      display: flex;
   }
   .navbar-toggler {
      position: absolute;
      bottom: unset;
      top: 0;
      left: 0;
      z-index: 200;
   }
   .selectWrapperRes {
      padding-right: 10px;
   }
   .modal-content {
      width: 60vw;
   }
   .PowerBoosterPurchaseModal {
      .modal-content {
         width: 85% !important;
      }
   }
   .modal-dialog {
      justify-content: center;
   }
   .LoginButton {
      font-size: 14px;
      line-height: 16px;
   }
   .BlackSkBtn text {
      font-size: 14px;
      line-height: 16x;
   }
}


@media only screen and (max-width: 576px) {

   .container {
      max-width: 100%;
      padding: 0 10px;
   }

   /*Home*/
   .mainHeadingWrapper {
      position: relative;
      left: unset;
      width: auto;
   }
   .mainHeaderH1 {
      font-size: 26px;
      line-height: 30px;
      text-align: left;
      margin-top: 10px;
   }

   .subHeadingWrap {
      width: auto;
      float: left;
      position: relative;
      padding: 0 20px !important;
      text-align: left;
   }
   .mainImageWrapper {
      margin-top: 10px;
      position: relative;
      right: unset;
      width: auto;
   }
   .p18 {
      font-size: 16px;
   }
   .gradientSphear {
      display: block;
   }
   .transCard {
      text-align: left;
      padding: 20px;
   }
   .transCard img {
      padding-bottom: 10px;
      width: 75px;
   }
   .neonRight {
      height: 400px;
      width: 185px;
      right: 0;
      bottom: 170px;
      opacity: 0.2;
   }
   .neonLeft {

      height: 430px;
      width: 190px;
      left: 0;
      top: -170px;
      opacity: 0.2;
   }
   .sectionWrapper {
      width: 100%;
      display: inline-flex;
      flex-wrap: wrap;
   }
   .footerWrapper {
      justify-content: center;
   }
   .subMainHeaderH1Wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      text-align: left;
   }
   .mt129, .mt99 {
      margin-top: 0px !important;
   }
   .mt-50 {
      margin-top: 0;
   }
   .mt149 {
      margin-top: 45px !important;
   }
   .advertWrapper {
      flex-wrap: wrap;
   }
   .doubleSizeDiv, .singleSizeDiv {
      width: 100%;
   }
   .imgManGradient {
      bottom: 15px;
   }
   .section2Img {
      width: 100%;
      padding: 60px 10px 20px 10px;
   }
   .gray-text {
      width: 100%;
      text-align: left;
      font-size: 14px;
      line-height: 18px;
   }
   .footerTxt, .footerTxt a {
      font-size: 10px;
      line-height: 13px;
   }
   .joinText {
      text-align: center;
   }
   .joinText .gray-text {
      text-align: center;
   }
   .design-section {
      text-align: center;
      margin-bottom: 30px;
   }
   .faqDetail {
      padding: 0px;
      text-align: left;
   }
   .fourcards, .threecards {
      width: 100%;
      margin-right: 0;
   }

   .mainHeaderH1 span .img-span {
      top: -52px;
      left: -118px;
   }

   .fc-wrapper {
      padding-right: 0px;
      padding-left: 0px;
      margin-bottom: 0;
      width: 100%;
   }

   .matches-radio-wrapper {
      padding-right: 0;
      padding-left: 0px;
      margin-bottom: 0;
   }

   .fc-wrapper .searchtxt {
      width: 100%;
      margin-left: 0;
      margin-bottom: 15px;
   }

   .matches-radio-wrapper .searchtxt {
      margin-bottom: 10px;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
   }

   .matches-radio-wrapper .fc-wrapper .searchtxt {
      width: 100%;
      margin-left: 0;
      margin-bottom: 0;
   }

   .justify-content-left {
      display: flex;
      justify-content: center;
   }

   .logo-section {
      text-align: center;
   }

   .social-section {
      justify-content: center;
      display: flex;
      margin-top: 120px;
   }
   .social {
      margin: 0 7px;
      justify-content: center;
      display: inline-flex;
      width: 44px;
      height: 44px;
   }
   .social img {
      width: 24px;
      height: 30px;
      padding-top: 14px
   }
   .bottom-footer .row .gray-text {
      text-align: center;
   }
   .terms-section {
      text-align: center;
      position: absolute;
      top: -185px;
   }
   .privacy-section {
      text-align: center;
      position: absolute;
      top: -145px;
   }
   .bottomGradient {
      background: radial-gradient(44.65% 42.65% at 50% 97%, #04F0FF 0%, rgba(20, 208, 230, 0) 100%);
      width: 100%;
      height: 345px;
   }
   .metalogoWrapper {
      text-align: center;
   }

   .metalogoWrapper a {
      margin-bottom: 65px;
      display: inline-block;
   }
   .socialWrapper {
      justify-content: center;
      display: flex;
   }
   .termsWrapper {
      position: absolute;
      top: 75px;
      left: 0;
      text-align: center;
   }
   .privacyWrapper {
      position: absolute;
      top: 107px;
      left: 0;
      text-align: center;
   }
   .playerImg {
      width: 55%;
      padding-left: 0px;
      position: absolute;
      float: 20;
      top: 20px;
      right: -70px;
   }
   .idCardWrapper {
      padding: 5px 0;
   }
   .idCardInnerWrapper {
      padding: 10px;
      height: 364.45px;
   }
   .mobile-card-drag-down {
      position: relative;
      bottom: -35px;
   }
   .gray-text {
      text-align: left;
   }
   .commThirdSection {
      margin-top: 185px !important;
      margin-bottom: 50px;
   }

   .fc-searctText {
      width: 100%;
   }

   .radioButtonlbl {
      /* margin-bottom: 15px;
       WIDTH: 47%;*/
   }

   .matches-radio-wrapper {
      justify-content: start;
   }

   .matches-radio-wrapper .radioButtonlbl {
      /* margin-bottom: 10px;
       width: calc(50% - 8px);*/
   }

   .fc-searctText {
      width: 100%;
      margin-bottom: 15px;
   }
   .teamDetail {
      position: relative;
      display: inline-block;
      width: 100%;
   }

   .matchCardInnerWrapper .teamDetail {
      display: flex;
      width: 100%;
   }
   .matchCardInnerWrapper .teamDetail table {
      width: 100%;
   }
   .team1scoreSection, .team2scoreSection {
      /*height: 83.5px;*/
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      float: right;
   }
   .team1Logo, .team2Logo {
      width: 75px;
      float: left;
   }
   .liveScore .team1Logo {
      top: 2px;
      position: relative;
   }
   .liveScore .team1Score, .liveScore .team2Score, .liveScore .team1Overs, .liveScore .team2Overs {
      font-size: 10px;
      line-height: 14px;
   }
   .team1scoreSection a, .team2scoreSection a {
      position: absolute;
      left: 65px;
      display: flex;
      /*width: 126px;*/
      min-height: 83.5px;
      align-items: center;
   }
   .liveScore .team1scoreSection, .liveScore .team2scoreSection {
      height: auto;
   }
   .liveScore .team1scoreSection a, .liveScore .team2scoreSection a {
      left: 40px;
      width: 70px;
      padding-top: 5px;
   }
   .team1scoreSection a .team1Name, .team2scoreSection a .team2Name {
      padding: 0 10px;
   }
   .liveScore .team1scoreSection a .team1Name, .liveScore .team2scoreSection a .team2Name {
      padding: 10px;
      margin-top: 0 !important;
      left: 0px;
      position: relative;
   }
   .liveScore .team1Name, .liveScore .team2Name {
      font-size: 14px;
      line-height: 18px;
   }

   .midDiv {
      margin: 0px 7.5px;
      padding: 2px 2px 0px 2px;
      font-size: 10px;
   }
   .statLbl {
      font-size: 10px;
      line-height: 14px;
   }
   .twodots {
      display: none;
   }
   .team1Score, .team2Score {
      font-size: 15px;
      line-height: 20px;
      max-width: 150px;
      text-align: right;
   }

   .team1Name, .team2Name {
      padding: 10px;
   }
   .liveScore .team1Score, .liveScore .team2Score, .liveScore .team1Overs, .liveScore .team2Overs {
      padding: 0;
   }
   .liveScore .team1Overs, .liveScore .team2Overs {
      top: 3px;
      position: relative;
      text-align: right;
   }
   .liveScore .team1Score, .liveScore .team2Score {
      top: 1px;
      position: relative;
      text-transform: uppercase;
      background: linear-gradient(89.7deg, #17EA85 2.27%, #14D0E6 23.6%, #17EA85 44.41%, #14D0E6 64.17%, #17EA85 82.99%, #14D0E6 99.74%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      text-shadow: 0px 0px 8px rgba(20, 208, 229, 0.56);
   }
   .LiveMatchScore {
      .team1Score, .team2Score {
         background: unset;
         -webkit-background-clip: unset;
         -webkit-text-fill-color: unset;
         background-clip: unset;
         text-fill-color: unset;
         text-shadow: unset;
      }
   }
   .liveScore .midSect {
      width: 27px;
      display: inline-block;
      text-align: center;
   }
   .liveScore .teamDetail {
      // width:50%;
      margin-left: -3px;
   }
   .liveScore .sched {
      width: 36%;
   }
   .liveScore .schedMid {
      width: 19%;
   }
   .timerDesc {
      display: none;
   }
   .timer-val {
      font-size: 16px;
      margin-bottom: 0;
   }
   .vsLbl {
      background: linear-gradient(89.7deg, #17EA85 2.27%, #14D0E6 23.6%, #17EA85 44.41%, #14D0E6 64.17%, #17EA85 82.99%, #14D0E6 99.74%);
      text-transform: uppercase;
      background: linear-gradient(89.7deg, #17EA85 2.27%, #14D0E6 23.6%, #17EA85 44.41%, #14D0E6 64.17%, #17EA85 82.99%, #14D0E6 99.74%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      text-shadow: 0px 0px 8px rgba(20, 208, 229, 0.56);
      font-weight: 700;
      font-size: 14px;
      font-family: 'Montserrat', sans-serif;
      display: inline-block;
   }
   .liveScore .team1Logo img, .liveScore .team2Logo img {
      width: 30px;
      height: 30px;
   }
   .navbarShowWrapper {
      height: 100vh;
      position: fixed;
      z-index: 200;
      left: 0;
      width: 100vw;
      display: block;
      background-color: #010E14;
      margin-right: 0 !important;
      overflow-x: auto;
   }
   .navbarShowWrapper .navbarBrand {
      text-align: center;
   }
   .navbarShowWrapper .navbarNav {
      width: 100vw;
      height: 100vh;
      overflow: auto;
   }
   .navCollapse {
      display: none;
   }
   .navbarShowWrapper .navCollapse {
      display: inline-block !important;
      position: relative;
      z-index: 19;
   }
   .navWrp .menuFooter {
      display: none;
   }
   .navbarShowWrapper .menuFooter {
      display: flex;
   }
   .navbar-toggler {
      position: absolute;
      bottom: unset;
      top: 0;
      left: 0;
      z-index: 200;
   }
   .selectWrapperRes {
      padding-right: 0px;
   }
   .statdiv {
      /* min-width: 474px;*/
   }
   .statwrap {
      flex-wrap: wrap;
      width: 100%;
   }
   .statwrap .stat-lbl {
      font-size: 12px;
      display: inline-block;
   }
   .stat-val, .stat-val .scoreLbl1, .table th, .stat-val .oversLbl, .battersWrp .table th, .table tbody tr td, span.tdmainTxt {
      font-size: 12px;
   }
   .liveScore {
      display: flex;
      padding: 6px;
      position: relative;
   }
   .backBtnScore {
      display: inline-block;
      /*position: absolute;*/
      left: 5px;
      top: 15px;
   }
   .backBtnScore svg {
      font-size: 20px;
      max-width: 16px;
   }
   .team1Name, .team2Name {
      font-size: 14px;
   }
   .liveScore .team1Name, .liveScore .team2Name {
      font-size: 10px;
      line-height: 14px;
   }
   .bowlindWrapper table {
      /* width: 500px;*/
      overflow: auto;
   }

   .bowlindWrapper {
      width: 100%;
      float: left;
      overflow: auto;
   }
   .modal-dialog {
      justify-content: center;
   }
   .modal-content {
      width: 100%;
   }
   .mt40 {
      margin-top: 0;
   }
   .LoginButton {
      font-size: 14px;
      line-height: 16px;
      height: 42px;
   }
   .BlackSkBtn text {
      font-size: 14px;
      line-height: 16x;
      height: 42px;
   }
   .simpleHeader {
      font-size: 20px;
      line-height: 26px;
   }
   .mobile-hide {
      display: none;
   }
   .mobile-show {
      display: block;
   }
   .modal-dialog {
      max-width: 90%;
      margin: 0 auto;
      margin-top: 30px;
      margin-bottom: 30px;
   }
   .next-tournament-col {
      .next-tournament-wrapper {
         zoom: .8;
      }
   }
}

.metalogoWrapper img {
   width: 198px;
}

@media only screen and (min-width: 768px) {
   .relative-lg {
      position: relative;
   }
   .relative-lg .socilaWrapper {
      position: absolute;
      bottom: 0px;
   }
}

@media (max-width: 768px) {
   .metalogoWrapper img {
      width: 103px;
   }
   .mainHeaderH1 span .img-span {
      top: 16px;
      left: -28px;
      background-size: 25%;
      background-repeat: no-repeat;
   }
   .relative-sm {
      position: relative;
   }
   .relative-sm .socilaWrapper {
      left: 15px;
      position: absolute;
      bottom: 10px;
   }
   .relative-sm .blackBtn {
      width: 100%;
   }
   .sub-section-match {
      min-height: auto;
   }
   .scoresText .winsLabel {
      font-size: 14px;
      line-height: 18px;
   }
}


.footer-links a {
   line-height: normal;
}

.copy-right-msg a {
   padding-left: 5px;
   margin-right: 25px;
}

.site-footer {
   font-size: 16px;
   font-weight: normal;
   display: grid;
   grid-template-columns: 320px 1fr;
   margin-bottom: 20px;
}

.footer-links a {
   width: fit-content;
   display: block;
   float: left;
   margin-right: 30px;
   line-height: 20px;
}

@media screen and (max-width: 1200px) {
   .site-footer * {
      font-size: 12px;
   }
   .site-footer {
      display: grid;
      grid-template-columns: 285px 1fr;
   }
   .footer-links {
      display: block;
   }
   .footer-links a {
      display: block;
      float: left;
      margin-right: 20px;
      line-height: normal;
   }
}

@media screen and (min-width: 768px) {
   .MobileMenuFooter {
      display: none;
   }
}

@media only screen and (max-width: 992px) {
   body {
      overflow-x: hidden;
   }

   .MobileMenuFooter {
      display: block;
   }
   .MetaLogoCol {
      width: unset;
      width: 100%;
   }
   .navbar-brand {
      a {
         img {
            max-width: 120px;
         }
      }
   }
   .navbarShowWrapper {
      height: 100vh;
      overflow: hidden;

      .navbar-expand-lg {
         ul.navbar-nav {
            height: calc(100vh - 400px);
            overflow-y: auto;
         }
      }
   }
   .menuFooter {
      position: relative;
      min-height: 185px;
      padding-top: 50px !important;
      padding-bottom: 20px;
      // border: solid 1px red;
      .container {
         height: 160px;
      }

      .site-footer {
         display: unset;
         all: unset;
         position: absolute;
         bottom: 0;
         height: 100px;
         max-width: 320px;
         margin: 0 auto;
         left: 0;
         right: 0;
         margin-left: auto;
         margin-right: auto;

         .copy-right-msg {
            text-align: center;
            position: absolute;
            bottom: 12px;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            padding-left: 16px;
         }

         .footer-links {
            display: block;
            position: relative;
            bottom: 13px;

            a {
               clear: both;
               float: none;
               display: block;
               width: 100%;
               text-align: center;
               margin-bottom: 8px;
            }
         }
      }

   }
   .metalogoWrapper {
      position: absolute;
      bottom: 0;
      width: 120px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      // position: fixed;
      // bottom: 135px;
      a {
         img {
            max-width: 120px;
         }
      }
   }

   .Footer_socialUi__qQMou {

      position: absolute;
      bottom: 15px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      margin-right: auto;
   }

   .Footer_social__HlbhW {
      width: 30px;
      height: 30px;

      img {
         width: 16px;
         padding-top: 8px;
      }
   }

}

@media screen and (max-width: 768px) {
   .team1Score, .team2Score {
      font-size: 20px;
   }
   .team1Logo, .team2Logo {
      width: 65px;
      margin-top: 8px;
   }
   .team1scoreSection a .team1Name, .team2scoreSection a .team2Name {
      padding: 10px;
      padding-left: 14px;
   }
   div.team1scoreSection > a > div {
      /* text-indent: 17px; */
   }
   div.team1scoreSection > a > div > span {
      position: absolute !important;
      left: -8px;
      top: 25px;
      display: none;
   }
   div.team2scoreSection > a > div > span {
      position: absolute !important;
      left: -8px;
      top: 25px;
      display: none;
   }
   .midSect .midDiv {
      display: none;
   }
   .metalogoWrapper {
      height: 20px;

      a img {
         max-width: 120px;
         margin-top: -60px;
      }
   }
   .mobile-footer-links {
      margin-bottom: 50px;

      a {
         font-size: 12px;
         height: 20px;
      }
   }


}

@media only screen and (max-width: 576px) {
   .liveScore .team1scoreSection a, .liveScore .team2scoreSection a {
      width: 100%;
   }
   .liveScore .team1Logo {
      top: 0px;
      left: -7px;
   }
   div.team1scoreSection > a > div > span {
      left: -2px;
      top: 10px;
      display: none;
   }
   div.team2scoreSection > a > div > span {
      left: -2px;
      top: 10px;
      display: none;
   }
   .liveScore .team1scoreSection a .team1Name, .liveScore .team2scoreSection a .team2Name {
      padding-left: 0px;
   }
   .team1scoreSection a .team1Name, .team2scoreSection a .team2Name {
      padding-left: 0px;
   }
   .liveScore .team1scoreSection a .team1Name, .liveScore .team2scoreSection a .team2Name {
      margin-top: 0px !important;
      display: flex;
   }
   .MobileMenuFooter {
      > div {
         > div.container.mt-5.mt-sm-0 {
            all: unset;
         }
      }

      .uppeFooter .metalogoWrapper {
         bottom: 95px;
      }
   }
   .liveScore {
      .teamDetail {
         a {
            width: 44%;
         }

         .team1Logo, .team2Logo {
            width: 65px;
            margin-top: 0;
         }
      }
   }
   .next-tournament-col {
      .next-tournament-wrapper {
         grid-template-columns: 30% 1fr;

         h2 {
            font-size: 3.30vw !important;
         }
      }
   }

   div.statdiv.font-italic > span > span.stat-lbl {
      display: block !important;
      width: 100%;
      //background-color: rgb(53 53 53 / 50%);
   }

}

@mixin ipadNav {
   .navbarNav {
      > ul {
         li.active {
            a {
               color: #17EA85 !important;
            }
         }
      }
   }
}

@media only screen and (min-width: 1024px) {
   @include ipadNav;
   .navbarNav {
      a.active {
         color: #17EA85 !important;
      }
   }
}

//iPad Pro 12 (2021)
@media only screen and (min-width: 1024px) and (orientation: landscape) {
   @include ipadNav;
   .navbarNav {
      a.active {
         color: #17EA85 !important;
      }
   }
}

@media only screen and (min-height: 1366px) and (orientation: portrait) {
   @include ipadNav;
   .navbarNav {
      a.active {
         color: #17EA85 !important;
      }
   }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (-min--moz-device-pixel-ratio: 2),
only screen and (-o-min-device-pixel-ratio: 2/1),
only screen and (min-device-pixel-ratio: 2) {

   @include ipadNav;
   .navbarNav {
      a.active {
         color: #17EA85 !important;
      }
   }
}

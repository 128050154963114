body {
   background-color: #010E14;
}

.loginLogo {
   width: 197px;
   height: 54px;
}

.LoginFont {
   font-family: 'Rubik-medium', Helvetica, Arial, sans-serif;
   font-size: 30px;
   color: #FFFFFF;
}

.midContent {
   width: 100%;
   max-width: 1440px;
   margin-left: auto;
   margin-right: 0px;
   margin-bottom: 0px;
}

.midContentTitle {
   font-family: 'Rubik-medium', Helvetica, Arial, sans-serif;
   font-size: 48px;
   text-decoration: none;
   text-align: center;
   margin-left: auto;
   font-weight: bold;
   color: hsla(0, 0%, 100%, 1.00);
}

.midContent2 {
   width: 100%;
   max-width: 1440px;
   padding-top: 10px;
   padding-right: 20px;
   padding-left: 20px;
   padding-bottom: 10px;
   margin-right: auto;
   margin-left: auto;
}

.midContent2Title {
   font-family: 'Rubik-medium', Helvetica, Arial, sans-serif;
   font-size: 20px;
   text-align: left;
   font-weight: 600;
   padding-top: 0px;
   width: 0%;
}

.bold {
   font-weight: 600;
   color: #FFFFFF;
}

.smallBtn2 {
   background-color: #000000;
   padding-top: 10px;
   padding-right: 20px;
   padding-bottom: 10px;
   padding-left: 20px;
   color: #fff;
   font-size: 15px;
   border-radius: 20px;
   border: 4px solid hsla(0, 0%, 100%, 1.00);
   line-height: 14px;
}

.left-menu li .active {
   /*background-color: #AB47BC!important;*/
   color: #fff !important;
}

.customBox {
   margin-top: 1%;
}

.font30Bold {
   font-size: 30px;
   line-height: 21px;
   font-family: 'Rubik-Medium', Helvetica, Arial, sans-serif;
}

.textf {
   border: 1px solid #0f2e46;
   background-color: #0b1e2c !important;
   height: 40px;
   padding: 10px;
   font-size: 15px;
   color: #fff;
   width: 100%;
}

.bigBtn {
   cursor: pointer;
   background-color: #000000;
   padding: 10px 20px;
   color: #fff;
   font-size: 15px;
   border-radius: 20px;
   border: 2px solid hsla(0, 0%, 100%, 1.00);
   line-height: 10px;
   width: 175px;
   height: 42px;
   font-family: 'Rubik-Medium', Helvetica, Arial, sans-serif;
}

.bigBtn:disabled {
   pointer-events: none !important;
}

.bigBtn:hover {
   background-color: #14d0e6;
   color: #000000;
}

.linka {
   color: #14d0e6;
   text-decoration: none !important;
   background-color: transparent;
   border: transparent;
}

.linka:hover {
   color: #19EA17FF;
}

/*sign up password strength styles*/
.passwordStrength {
   width: 100%;
   height: 10px;
   background-color: #ddd;
   position: relative;
   margin-bottom: 10px;
}

.strengthBar {
   position: absolute;
   top: 0;
   left: 0;
   height: 100%;
   background-color: #f00;
   width: 0%;
   transition: width 0.3s;
}

.strengthLabel {
   position: relative;
   top: 20px;
   right: 0;
   font-size: 14px;
}

.uiText {
   padding-left: 15px;

   li {
      font-size: 13px;
      color: #939a9f;
   }
}

.Radial1 {
   width: 900px;
   height: 600px;
   left: -515px;
   top: -350px;
   background: radial-gradient(32.65% 32.65% at 50% 50%, #04F0FF 0%, rgba(20, 208, 230, 0) 100%);
   mix-blend-mode: normal;
   position: absolute;
   opacity: 0.14;
   z-index: -1;
}

.Radial2 {
   width: 800px;
   height: 70vh;
   right: 0px;
   top: 150px;
   background: radial-gradient(32.65% 32.65% at 110% 50%, #E61479 0%, rgba(230, 20, 20, 0) 100%);
   mix-blend-mode: normal;
   position: absolute;
   opacity: 0.44;
   z-index: -1;
}

/*new design fonts march-2023*/

.LogoTitle2 {
   font-size: 50px;
   font-family: 'ObjSans-HeavySlanted', Helvetica, Arial, "sans-serif" !important;
   color: #E61479;
}

.TopBotGaps {
   // margin-top: 10vh;

}

.group {
   position: relative;
   margin-bottom: 10px;
}

input[type=password] {
   display: block;
   border: none;
   background-color: #04151D;
   background-image: -webkit-linear-gradient(0deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   background-image: -moz-linear-gradient(0deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   background-image: -o-linear-gradient(0deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   background-image: linear-gradient(90deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   max-width: 495px;
   color: #ffffff;
   padding-left: 20px;
   padding-right: 50px;
   font-size: 16px;
   width: 100%;
   box-sizing: border-box;
   border: solid 1px #3E5168;
   height: 67px;
   border-radius: 6px;
   background-color: #04151D;
   padding-bottom: 22px;
   padding-top: 35px;
   line-height: 10px;
   /*background-image: url("https://storage.googleapis.com/meta11/serviceplatform/pw-eye.png");*/
   background-repeat: no-repeat;
   background-position: 96% center;
}

input[type=password]:focus {

   border-style: solid;
   border-width: 1px;
   /*border-image: linear-gradient(45deg, #17EA85, #14D0E6, #17EA85, #14D0E6, #17EA85, #14D0E6) 1 !important;*/
   border-radius: 6px;
   outline: none
}


/* LABEL */
.group label {
   color: #95A6B7;
   font-size: 16px;
   font-weight: normal;
   position: absolute;
   pointer-events: none;
   left: 16px;
   top: 25px;
   transition: 0.2s ease all;
   -moz-transition: 0.2s ease all;
   -webkit-transition: 0.2s ease all;
}

/* active state */
input[type=password]:focus ~ label, input[type=password]:valid ~ label {
   top: 10px;
   font-size: 12px;
   color: #95A6B7;
   padding-left: 6px;
}

/*Email*/
input[type=text] {
   display: block;
   border: none;
   background-color: #04151D;
   background-image: -webkit-linear-gradient(0deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   background-image: -moz-linear-gradient(0deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   background-image: -o-linear-gradient(0deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   background-image: linear-gradient(90deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   max-width: 495px;
   color: #ffffff;
   padding-left: 20px;
   padding-right: 50px;
   font-size: 16px;
   /*font-family: 'SolinaLight';*/
   width: 100%;
   box-sizing: border-box;
   border: solid 1px #3E5168;
   height: 67px;
   border-radius: 6px;
   background-color: #04151D !important;
   padding-bottom: 22px;
   padding-top: 35px;
   line-height: 10px;
   /*background-image: url("https://storage.googleapis.com/meta11/serviceplatform/pw-eye.png");*/
   background-repeat: no-repeat;
   background-position: 96% center;
}

input[type=text]:focus {
   border-style: solid;
   border-width: 1px;
   /*border-image: linear-gradient(45deg, #17EA85, #14D0E6, #17EA85, #14D0E6, #17EA85, #14D0E6) 1 !important;*/
   border-radius: 2px;
   outline: none;
   color: #FFFFFF;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
   transition: background-color 600000s 0s, color 600000s 0s;
}

input[data-autocompleted] {
   background-color: transparent !important;
}

input[type=text]:-webkit-autofill {
   background-color: #04151D !important; /* Change background color to black */
}

input[type=text]:-webkit-autofill:focus {
   background-color: #04151D !important;
   color: #FFFFFF !important;
}

input[type=email] {
   display: block;
   background-image: -webkit-linear-gradient(0deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   background-image: -moz-linear-gradient(0deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   background-image: -o-linear-gradient(0deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   background-image: linear-gradient(90deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   max-width: 495px;
   color: #ffffff;
   padding-left: 20px;
   padding-right: 50px;
   font-size: 16px;
   /*font-family: 'SolinaLight';*/
   width: 100%;
   box-sizing: border-box;
   border: solid 1px #3E5168;
   height: 67px;
   border-radius: 6px;
   background-color: #04151D;
   padding-bottom: 22px;
   padding-top: 35px;
   line-height: 10px;
   /*background-image: url("https://storage.googleapis.com/meta11/serviceplatform/pw-eye.png");*/
   background-repeat: no-repeat;
   background-position: 96% center;
}

input[type=email]:focus {
   border-style: solid;
   border-width: 1px;
   /*border-image: linear-gradient(45deg, #17EA85, #14D0E6, #17EA85, #14D0E6, #17EA85, #14D0E6) 1 !important;*/
   border-radius: 2px;
   outline: none
}

/* active state */
input[type=text]:focus ~ label, input[type=text]:valid ~ label {
   top: 10px;
   font-size: 12px;
   color: #95A6B7;
   padding-left: 6px;
}

.NumIn {
   width: 68px !important;
   box-sizing: border-box;
   border: solid 1px #3E5168;
   height: 67px;
   border-radius: 6px;
   background-color: #04151D;
   background-image: -webkit-linear-gradient(0deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   background-image: -moz-linear-gradient(0deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   background-image: -o-linear-gradient(0deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   background-image: linear-gradient(90deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   text-align: center !important;
   color: #ffffff;
   padding-left: 20px;
   padding-right: 20px;
   font-size: 32px !important;
   font-family: 'SolinaLight';
   margin-left: 4px;
   margin-right: 3px;
}


::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
   color: #95A6B7;
   opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: #95A6B7;
}

::-ms-input-placeholder { /* Microsoft Edge */
   color: #95A6B7;
}

.FormBox {
   position: relative;
   margin-top: 5px;
   float: left;
   width: 100%;

   .group1 {
      margin-top: 45px;
   }

   .group {
      label {
         top: 18px;
      }
   }

   input[type=text], input[type=password] {
      height: 56px !important;
   }
}

.LoginButton {
   background: linear-gradient(95.34deg, #FAFF00 6.28%, #00FFC2 111.13%);
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
   border-radius: 4px;
   transform: skew(-10deg, 0deg);
   border: 1px solid #2FFF9E;
   text-align: center;
   letter-spacing: -0.01em;
   padding: 14px 27px;
   width: 49%;
   height: 54px;
   font-family: "ObjectSans-Heavy", Helvetica, Arial, sans-serif;
   font-size: 15px;
   line-height: 18px;
   text-transform: uppercase;
}

.LoginButton:hover {
   background: linear-gradient(95.34deg, #00FFC2 6.28%, #FAFF00 111.13%);
}

.LoginButton:visited {
   background: linear-gradient(95.34deg, #B0FFCA 6.28%, #F2FF7E 111.13%);
}

.buttonColor {
   color: #000000;
}


.BlackSkBtn {
   background-color: #0E2029;
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
   border-radius: 4px;
   transform: skew(-10deg, 0deg);
   border: 1px solid rgba(0, 255, 194, 0.09);
   font-family: "ObjectSans-Heavy";
   font-size: 16px;
   line-height: 18px;
   /* identical to box height, or 129% */
   width: 48%;
   text-align: center;
   letter-spacing: -0.01em;
   text-transform: uppercase;
   padding: 14px 27px;
   font-weight: 700;
   height: 54px;


}

.BlackSkBtn text {

   background-image: linear-gradient(0deg, #FAFF01, #39FF96);
   background-clip: text;
   color: transparent;
}

.OrSpace {
   text-align: center;
   margin-bottom: 30px;

   text {
      position: absolute;
      color: #95A6B7 !important;
      background-color: #010E14;
      display: block;
      width: 110px;
      height: 36px;
      left: 50%;
      transform: translate(-50%, 0);
   }
}

.OrSpace:before {
   content: '';
   position: absolute;
   left: 0;
   border: 1px solid #182328;
   width: 100%;
   z-index: 0;
   max-width: 496px;
   top: 13px;
   left: 0px;
}

.Font16 {
   font-family: 'ObjectSans-Regular';
   font-size: 16px;
   color: #95A6B7;
   line-height: 20px;
   float: left;

   a {
      background-image: linear-gradient(0deg, #17EA85, #14D0E6);
      background-clip: text;
      color: transparent !important;
   }
}

/*modal classes*/
.ModalCont {
   /*width: 616px;*/
   width: 100%;
   margin-left: auto;
   margin-right: auto;
   background-color: #03161F;
}

.ModalTitle {
   font-family: 'SolinaLight' !important;
   font-size: 48px;
   text-transform: uppercase;

}

.ModalSmallText {
   font-size: 16px;
   color: #95A6B7;
   font-family: 'ObjectSans-Regular';
   line-height: 20px;
}

.ModalSmallText2 {
   font-size: 16px;
   color: #ffffff;
   font-family: 'ObjSans-HeavySlanted';
   line-height: 20px;
   text-transform: uppercase;
}

.ModalSmallText2 font {
   text-transform: uppercase;
   background: linear-gradient(to right, #17EA85, #14D0E6, #17EA85, #14D0E6);
   -webkit-text-fill-color: transparent;
   -webkit-background-clip: text;
   cursor: pointer;
}

.modalBlur {
   background: rgb(0 0 0 / 76%);
   z-index: 9999;
}

.ModalContent {
   width: 100% !important;
   background-color: #03161F;
}

.ModalHeader {
   border-bottom: none;
}

.modal-dialog {
   background-color: #14D0E6;
}

.CloseIcon {
   font-size: 35px;
   color: #ffffff;
   border: 0;
   background-color: #03161F;
   cursor: pointer !important;
}

.CloseIcon:hover {
   color: #FFFFFF;
}

.Error {
   border: 1px solid #FF002E !important;
}

.TextError {
   color: #FF002E;
   line-height: 50px;
   /*height: 20px;*/
   display: block;
}

.FormCheckInput {
   height: 18px !important;
   width: 18px !important;
   border: solid 1px #3E5168 !important;
   background-color: #04151D !important;
   margin-top: -3px;
}

.ModalDialog {
   position: relative;
   /*width: auto;*/
   margin-left: auto;
   margin-right: auto;
   width: 100%;
   max-width: 616px;
   /*pointer-events: none;*/
}

.asteriskInput {
   font-family: 'asterix', 'fontello' !important;
   font-style: normal;
   font-weight: normal;
   speak: never;

   display: inline-block;
   text-decoration: inherit;
   width: 1em;
   margin-right: .2em;
   font-variant: normal;
   text-transform: none;
   line-height: 1em;
   font-size: 80% !important;

   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

.eye {
   background-image: url("https://storage.googleapis.com/meta11/serviceplatform/pw-eye.png");
   background-repeat: no-repeat;
   width: 20px;
   height: 20px;
   display: block;
   position: absolute;
   top: 50%;
   transform: translateY(-50%);
   right: 3%;
   cursor: pointer;
}

.normalEye:before {
   position: absolute;
   font-family: 'FontAwesome';
   display: block;
   color: #15D9C5;
   top: 50%;
   width: 20px;
   height: 20px;
   transform: translateY(-50%);
   right: 3%;
   font-size: 17px;
   cursor: pointer;
   content: "\f06e";
}

.slashEye:before {
   position: absolute;
   font-family: 'FontAwesome';
   display: block;
   color: #15D9C5;
   top: 50%;
   width: 20px;
   height: 20px;
   transform: translateY(-50%);
   right: 3%;
   font-size: 17px;
   cursor: pointer;
   content: "\f070";
}

.FormWrapper {
   max-width: 496px;
}

.SocialButtonWrapper {
   margin-top: 38px;

   button {
      width: 100%;
      height: 54px;
      margin-bottom: 16px;
      padding: unset;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 16px;

      img {
         transform: skew(10deg, 0deg);
      }
   }
}


@media only screen and (min-width: 1440px) {
   .midContent {
      width: 100%;
      margin-top: 0px;
      margin-left: auto;
      margin-right: auto;
      padding-right: 0px;
      padding-left: 0px;
   }
   .imgSize {
      width: 70%;
   }
}

@media only screen and (max-width: 1440px) {
   .imgSize {
      width: 70%;
   }
}

@media only screen and (max-width: 1366px) {
   .imgSize {
      width: 75%;
   }

}

@media only screen and (max-width: 1200px) {
   .imgSize {
      width: 90%;
   }
   .loginLogo {
      width: 150px;
   }

   .LoginFont {
      font-family: 'Rubik-medium', Helvetica, Arial, sans-serif;
      font-size: 25px;
      color: #FFFFFF;
   }
}

@media only screen and (max-width: 992px) {
   .LogoTitle1 {

      margin-top: 50px;
   }
   .LogoTitle1 .text {
      font-size: 26px;
   }
   .marginTopMd {
      margin-top: 50px;
   }
   .FormBox {
      margin-top: 40px;
   }
   .outterWrap {
      height: 100vh;
      overflow-y: auto;
   }
   .LoginButton {
      margin-top: 20px;
   }
   .FormContainer {
      // display: flex;
      // justify-content: center;
      background-color: #ccc;
   }
   .FormWrapper {
      margin: 0 auto;
   }
}

@media (max-width: 768px) {
   .loginLogo {
      width: 120px;
   }

   .LoginFont {
      font-family: 'Rubik-medium', Helvetica, Arial, sans-serif;
      font-size: 20px;
      color: #FFFFFF;
   }
}

@media only screen and (max-width: 552px) {
   .midContent {
      position: relative;
   }
   .Radial2 {
      display: none;
   }
   .Radial1 {
      left: 0;
      top: 0;
      background: radial-gradient(32.65% 32.65% at 0% 0%, #04F0FF 0%, rgba(20, 208, 230, 0) 100%);
      height: 100vh;
      z-index: -1;
   }
   .LoginButton {
      font-size: 12px;
      padding: 10px 27px;
      height: 46px;
   }
   .Font16, .Font16 a {
      font-size: 14px;
   }
   .outterWrap {
      height: 100vh;
   }
}
